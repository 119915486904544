
.l-seller-rating,
.l-seller-rating__stars-cont {
    display: inline-block;
    white-space: nowrap;
    box-sizing: border-box;
    vertical-align: top;
    position: relative;
    cursor: pointer;
}
.l-seller-rating {
    position: relative;
    margin-bottom: 5px;
}
.l-seller-rating i {
    letter-spacing: 0;
    margin: 0;
}
.l-seller-rating__stars-cont_active {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
}