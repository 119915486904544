.l-payment-page {
    background: #f0f0f0;
}

    .l-payment-page__header,
    .l-payment-page__wrap {
        padding: 0 12px;
    }

    .l-payment-page__header {
        /* Prevent margin collapse on progress bar */
        overflow: hidden;
    }
