/*
 * Product back button
 * */
.l-order-progress {
    box-sizing: border-box;
    display: table;
    width: 100%;
    padding: 36px 35px 28px;
    text-align: center;
}

    .l-order-progress__line,
    .l-order-progress__icon {
        display: table-cell;
        color: #c4c4c4;
        vertical-align: middle;
    }

    .l-order-progress__line:before {
        content: '';
        display: block;
        border-bottom: 1px dashed;
    }

    .l-order-progress__icon {
        position: relative;
        width: 1%;
    }

    .l-order-progress__label {
        position: absolute;
        left: 50%;
        bottom: 100%;
        width: 70px;
        margin: 0 0 10px -35px;
        font-size: 10px;
    }

.l-order-progress_closed .l-order-progress__icon,
.l-order-progress_closed .l-order-progress__line,
.l-order-progress_delivered .l-order-progress__icon,
.l-order-progress_delivered .l-order-progress__line {
    color: #8aad4e;
}

.l-order-progress_shipped .l-order-progress__icon_2,
.l-order-progress_shipped .l-order-progress__line_1,
.l-order-progress_shipped .l-order-progress__icon_1,

.l-order-progress_processed .l-order-progress__icon_1 {
    color: #f37021;
}

.l-order-progress_closed .l-order-progress__line:before,
.l-order-progress_delivered .l-order-progress__line:before,
.l-order-progress_shipped .l-order-progress__line_1:before {
    border-bottom-style: solid;
}

.l-order-progress_declined .l-order-progress__icon {
    color: #f25c22;
    text-align: center;
}