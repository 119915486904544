.l-mobile-step_finish-success {
    padding: 18px 12px 0 12px;
    font-size: 13px;
}
.l-success-message-cont__icon {
    float: left;
}
.l-success-message-cont .l-success-message-cont__text-thanks {
    color: #3c6f05;
    margin: 0 0 0 20px;
    font-size: 14px;
    font-weight: normal;
    text-align: left;
}
p.l-success-message-cont__text-comment {
    padding: 0;
    margin: 15px 0 15px 0;
    font-size: 13px;
    line-height: 17px;
}
fieldset.l-finish__cont_success {
    background: #f8f8f8;
    padding: 20px 12px;
    margin: 0 -12px 20px -12px;
    border: 0 none;
}

.l-mobile-step_finish-success .l-finish__hr{
    border-color: #ddd;
}
.l-mobile-step_finish-success__create-account {
    border-top: 1px solid #ddd;
    margin: 0 -12px 44px;
    padding: 0 12px;
}
.l-mobile-step_finish-success__create-account > form {
    transition: max-height .2s,
                opacity .2s;
    max-height: 160px;
}
.l-mobile-step_finish-success__create-account h3 {
    font-weight: normal;
    font-size: 14px;
    color: #333;
    margin: 24px 0 12px;
}

.l-mobile-step_finish-success__create-account .orange-button {
    margin-top: 20px;
}

.l-mobile-step_finish-success__bcard {
    border-top: 1px solid #ddd;
    margin: 0 -12px;
    padding: 20px 12px;
}

.l-mobile-step_finish-success__bcard .icon-bcard {
    display: block;
    margin-bottom: 8px;
}

.l-mobile-step_finish-success__bcard .bcardtitle {
    margin-bottom: 10px;
}

.l-mobile-step_finish-success__bcard .ss-form-entry {
    margin-bottom: 15px;
}

.l-mobile-step_finish-success__bcard .ss-form-entry:after {
    content: '';
    display: block;
    clear: both;
}

.l-mobile-step_finish-success__bcard .bcard_input {
    float: left;
    width: 73%;
}

.l-mobile-step_finish-success__bcard .ss-q-submit {
    float: right;
    width: 24%;
    outline: 0 none;
    border: 0 none;
    font-size: 17px;
    line-height: 42px;
    text-transform: uppercase;
    padding: 0 4px;
    height: auto;
    background: #f37022;
    color: #fff;
    clear: right;
}
.l-mobile-step_finish-success__bcard .ss-form-error {
    float: left;
    clear: both;
}

.l-mobile-step_finish-success__bcard .ss-signup {
    clear: both;
    color: #838383;
}

.l-mobile-step_finish-success__bcard .ss-signup {
    color: #838383;
}

.l-mobile-step_finish-success__bcard .ss-signup strong {
    font-weight: normal;
    color: #000;
}
.l-mobile-step_finish-success .ss-form-sucess {
    margin-bottom: 5px;
}
.js-hide-form {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
}