/*
 * Product Receipt block
 * */
.l-order-receipt {
    color: #757575;
    border: 1px solid #ececec;
    border-top: 0 none;
}

.l-order-receipt__body {
    padding: 10px 8px;
}

    .l-order-receipt__line {
        display: table;
        width: 100%;
    }

        .l-order-receipt__col {
            padding: 4px 0; 
            display: table-cell;
        }

        .l-order-receipt__col_r {
            text-align: right;
        }

.l-order-receipt__foot {
    border-top: 1px solid #ececec;
    font-weight: bold;
    color: #000;
}
    .l-order-receipt__foot .l-order-receipt__col {
        padding: 8px;
    }
    .l-order-receipt__foot .l-order-receipt__col_r {
        color: #f36f21;
    }