.l-form-tick {
    display: block;
    clear: both;
    white-space: nowrap;
}

	.l-form-tick__input {
		display: inline-block;
		vertical-align: top;
	}

	.l-form-tick__label {
		display: inline-block;
		vertical-align: top;
		white-space: normal;
	}

.l-form-tick .l-form-input__error{
	white-space: normal;
}
