.l-address-list {
    /*margin: -7px 0 22px;*/
    margin: -7px 0 15px;
}

    .l-address-list__itm {
        border-bottom: 1px solid #d6d6d6;
        padding: 7px 0;
        white-space: nowrap;
    }

    .l-address-list__itm:last-child {
		border-bottom: 0 none;
    }

    .l-address-list__itm .l-address-list__itm-label {
        font-size: 12px;
        line-height: 15px;
        display: inline-block;
    }

        .l-address-list__itm-label-text {
            display: inline-block;
        }
           .l-address-list__itm .address {
                width: 90%;
            }

        .l-address-list__itm .l-address-list__itm-a {
            border-bottom: 1px dotted #1b73b3;
            font-size: 12px;
            line-height: 15px;
        }

        .l-address-list__itm-label .icon-svg-address-phone {
        	margin-top: 2px;
        }
