.l-form-input {
    display: block;
}

    .l-form-input__input-wrap {
        position: relative;
        display: block;
    }

    .l-form-input__input.l-form-input__input {
        display: block;
        box-sizing: border-box;
        width: 100%;
        margin: 0;
        padding: 3px 10px;
        border: 1px solid #aaa;
        background: #fff;
        -webkit-border-radius: 3px;
                border-radius: 3px;
        -moz-appearance: none;
        -webkit-appearance: none;
                appearance: none;
        color: #333;
        font: normal 14px/22px Roboto, Tahoma, sans-serif;
    }

    .l-form-input_is-select .l-form-input__input-wrap:after {
        position: absolute;
        top: 50%;
        right: 16px;
        content: '';
        border: solid transparent;
        border-width: 6px 4px 0;
        border-top-color: #646464;
        margin: -3px -4px 0;
        white-space: nowrap;
    }

    .l-form-input_is-select .l-form-input__input {
        white-space: initial;
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .l-form-input__input_uppercase {
        text-transform: uppercase;
    }

    .l-form-input_expiry .l-form-input__input_year.l-form-input__input_year,
    .l-form-input_expiry .l-form-input__input_month.l-form-input__input_month {
        display: inline-block;
        width: auto;
        border: none;
        outline: none;
    }

    .l-form-input_expiry .l-form-input__input.l-form-input__input:-moz-placeholder {
        padding: 0;
    }

    .l-form-input_expiry .l-form-input__input.l-form-input__input:-ms-input-placeholder {
        padding: 0;
    }

    .l-form-input_expiry .l-form-input__input.l-form-input__input::-webkit-input-placeholder {
        padding: 0;
    }

    .l-form-input_expiry .l-form-input__input.l-form-input__input {
        width: 2.7em;
    }

    .l-form-input_expiry .l-form-input__input_month.l-form-input__input_month {
        padding-right: 0;
    }

    .l-form-input_expiry .l-form-input__input_year.l-form-input__input_year {
        padding-left: .4ex;
    }

    .l-form-input_expiry .error {
        border: none !important;
    }

    .l-form-input_expiry .l-form-input__input-wrap {
        border: 1px solid #aaa;
        -webkit-border-radius: 3px;
                border-radius: 3px;
    }

    .l-form-input_expiry.l-form-input_has-error .l-form-input__input-wrap {
        border-color: #d2232a;
    }

    .l-form-input__label {
        position: absolute;
        left: 10px; top: 7px;

        color: #646464;
        font-size: 8px;
        line-height: 10px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
         -khtml-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }

    .l-form-input__label_uppercase {
        text-transform: uppercase;
    }

    .l-form-input__icon {
        position: absolute;
        left: 10px; bottom: 10px;
    }

    .l-form-input__error {
        color: #d2232a;
        font-size: 11px;
    }

    .l-form-input__error {
        display: none;
    }

    .l-form-input_has-error .l-form-input__error {
        display: block;
    }

    .l-form-input__note {
        font-size: 11px;
        display: none;
    }

    .l-form-input_has-error .l-form-input__input.l-form-input__input {
        color: #d2232a;
        border-color: #d2232a;
        outline-color: #d2232a;
    }

    .l-form-input__label ~ .l-form-input__input.l-form-input__input {
        padding-top: 18px;
    }

    .l-form-input__icon ~ .l-form-input__input.l-form-input__input {
        padding-left: 32px;
    }
