.newsletter-sticky-footer-form-popup {
    width: 425px;
    height: 240px;
    position: fixed;
    /*bottom: -200px;*/
    left: 50px;
    z-index: 1000;
    border: 1px solid #000;
    border-bottom: 0;
    background: #fdfdfd;
    cursor: default;
}

.newsletter-sticky-footer-form-popup .newsletter-sticky-footer-top {
    background: #004688;
    height: 15px;
}

.newsletter-sticky-footer-form-popup .newsletter-sticky-footer-top .newsletter-sticky-footer-top-close {
    float: right;
    height: 15px;
    width: 25px;
    margin: 0 5px 0 0;
    cursor: pointer;
}

.newsletter-sticky-footer-form-popup .newsletter-sticky-footer-top .newsletter-sticky-footer-top-close-i {
    width: 15px;
    height: 2px;
    border-top: 1px solid #FFF;
    display: block;
    margin: 6px 0 0 5px;
}

.newsletter-sticky-footer-form-popup .newsletter-sticky-footer-design2-top {
    background: #004688;
    height: 15px;
}

.newsletter-sticky-footer-form-popup .newsletter-sticky-footer-design2-top .newsletter-sticky-footer-design2-top-close {
    float: right;
    height: 15px;
    width: 25px;
    margin: 0 5px 0 0;
    cursor: pointer;
}

.newsletter-sticky-footer-form-popup .newsletter-sticky-footer-design2-top .newsletter-sticky-footer-design2-top-close-i {
    width: 15px;
    height: 2px;
    border-top: 1px solid #FFF;
    display: block;
    margin: 6px 0 0 5px;
}

.newsletter-sticky-footer-form-popup .newsletter-sticky-footer-design2-title {
    font-size: 18px;
    font-weight: 500;
    color: #000;
    text-align: center;
    padding: 6px 0 0 0;
    line-height: 30px;
}

.newsletter-sticky-footer-form-popup .newsletter-sticky-footer-design2-title font#newsletter-sticky-currency {
    font-size: 20px;
    font-weight: 800;
}

.newsletter-sticky-footer-form-popup .newsletter-sticky-footer-design2-title font#newsletter-sticky-voucher-value {
    font-size: 24px;
    color: #fdb000;
    font-weight: 800;
}

.newsletter-sticky-footer-form-popup .newsletter-sticky-footer-design2-title font {
    font-size: 20px;
    font-weight: 800;
}

.newsletter-sticky-footer-form-popup .newsletter-sticky-footer-design2-center {
    text-align: center;
    font-weight: 500;
}

.newsletter-sticky-footer-form-popup .newsletter-sticky-footer-design2-center-p {
    margin: 0 0 6px 0;
}

.newsletter-sticky-footer-form-popup .newsletter-sticky-footer-design2-center-p-error {
    display: none;
    margin: -10px 0 2px 0;
    color: red;
}

.newsletter-sticky-footer-form-popup .newsletter-sticky-footer-design2-center-button:first-child {
    margin-left: 0;
}

.newsletter-sticky-footer-form-popup .newsletter-sticky-footer-design2-center-input {
    width: 290px;
    height: 30px;
    margin: 12px 0 0;
    padding: 3px 8px;
    font-size: 14px;
    color: #807f7f;
    border: 1px solid #bdbbbb;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.newsletter-sticky-footer-form-popup button.ui-button.female {
    margin-right: 10px;
}

.newsletter-sticky-footer-form-popup div.box-ft-design2 {
    padding: 8px;
}

.newsletter-sticky-footer-form-popup .newsletter-sticky-footer-design2-privacy-terms-text {
    font-size: 9px;
    color: #909999;
    margin: 0;
    text-align: center;
}

.newsletter-sticky-footer-bar {
    width: 100%;
    height: 25px;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10000;
    background: #004688;
    font-size: 11px;
}

.newsletter-sticky-footer-bar .newsletter-sticky-footer-email-popup-link {
    width: 140px;
    line-height: 25px;
    margin: 0 0 0 50px;
    padding: 0 0 0 50px;
    position: relative;
    color: #FFF;
    font-weight: bold;
    float: left;
    cursor: pointer;
}

.newsletter-sticky-footer-bar .newsletter-sticky-footer-email-popup-link:hover {
    background: #001658;
}

.newsletter-sticky-footer-bar .newsletter-sticky-footer-email-popup-link-design3 {
    width: 140px;
    line-height: 25px;
    margin: 0 0 0 50px;
    padding: 0 0 0 50px;
    position: relative;
    color: #FFF;
    font-weight: bold;
    float: left;
    cursor: pointer;
}

.newsletter-sticky-footer-bar .newsletter-sticky-footer-email-popup-link-design3:hover {
    background: #001658;
}

.newsletter-sticky-footer-bar .newsletter-sticky-footer-email-icon {
    width: 37px;
    height: 37px;
    position: absolute;
    left: 5px;
    top: -8px;
    z-index: 100000;
    background: url("/images/spinbasket/sprites_images/general_sprite/icn_general_1.png") 0px -201px no-repeat;
}

.newsletter-sticky-footer-bar .newsletter-sticky-footer-text-link {
    position: absolute;
    top: 0;
    right: 5px;
    line-height: 25px;
}

.newsletter-sticky-footer-bar .newsletter-sticky-footer-text-link a {
    color: #FFF;
}

.newsletter-sticky-footer-bar .newsletter-sticky-footer-text-link a:hover {
    text-decoration: underline;
}

.newsletter-sticky-footer-bar .newsletter-sticky-footer-text-link a:hover {
    color: #ff9724;
}

/* Unsubcribe newsletter */

.unsubscribe {
    background: #E5E5E5;
    width: 100%;
    padding: 30px 0px;
    color: #1c3766;
}
.unsubscribe .content {
    margin: 10px auto 10px;
    font-family: 'Helvetica Neue', helvetica, arial, sans-serif;
    width: 660px;
}
.unsubscribe .header-img {
    margin: 0 auto 10px;
    width: 238px;
    height: 236px;
    background: url('/images/spinbasket/newsletter/unsign/header-img.png') 0 0 no-repeat;
}
.unsubscribe h1 {
    font-size: 2.5em;
}
.unsubscribe h1.border-top {
    border-top: 1px dashed #000000;
    padding-top: 10px;
}
.unsubscribe h3 {
    margin-left: 30px;
}
.unsubscribe h3, .unsubscribe h1 {
    text-transform: uppercase;
}
.unsubscribe p {
    width: 100%;
    margin: 10px 0 25px;
}
.unsubscribe .unsubcribe-form {
    margin-bottom: 30px;
}
.unsubscribe .unsubcribe-form-wrapper {
    margin: 0 0 13px;
}
.unsubscribe .unsubcribe-form-wrapper:after {
    clear: both;
    content: '';
    display: block;
}
.unsubscribe .unsubcribe-form-button {
    display: inline-block;
}
.unsubscribe button {
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    background: #FAB609;
    color: #000000;
    padding: 5px 10px;
    border: none;
    font-weight: bold;
    text-transform: uppercase;
    -webkit-box-shadow: 0px 1px 1px 1px #7B7B7B;
    box-shadow: 0px 1px 1px 1px #7B7B7B;
    min-width: 150px;
}
.unsubscribe button:hover {
    background: #FCC22E;
}
.unsubscribe .unsubcribe-form-text {
    display: inline-block;
    width: 480px;
    vertical-align: middle;
    margin-left: 25px;
}
.unsubscribe button.no_preference{
    color: #ffffff;
}
.unsubscribe .last_option{
    text-align: center;
}
.unsubscribe .content.unsubcribe-step2 {
    width: 100%;
}
.unsubscribe .unsubcribe-step2 h1,
.unsubscribe .unsubcribe-step3 h1 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 2.3em;
}
.unsubscribe .unsubcribe-step2 button {
    margin-top: 10px;
}
.unsubscribe .unsubcribe-step2 button span:after {
    display: inline-block;
    content: '';
    width: 0;
    height: 0;
    border-left: 8px solid #000;
    border-top: 5px solid rgba(0, 0, 0, 0);
    border-bottom: 5px solid rgba(0, 0, 0, 0);
    margin-left: 8px;
}
.unsubscribe .unsubcribe-step2 .header-img {
    background-position: -238px 0;
}
.unsubscribe .unsubcribe-step3 .header-img {
    background-position: -476px 0;
}
