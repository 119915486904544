/*
*
* mobile orders page
*
*/

.l-orders-page {
    margin: 0;
    font-size: 11px;
    line-height: 14px;
}

    .l-orders-page__header {
        border-top: 1px solid #d3d3d4;
    }

    .l-orders-page__wrap {
        padding: 0 8px;
    }

    /* overriding h2 styles, remove later, when old styles be gone */
    .content h2.l-orders-page__title,
    .l-orders-page__title {
        margin: 0 0 8px;
        padding: 6px 0 6px 15px;
        background: #f4f3f3;
        color: #4d4d4d;
        font-weight: normal;
    }

    .l-orders-page__title-link {
        color: #5cb6c2;
        position: relative;
        display: inline-block;
        margin-right: 22px;
        padding-right: 4px;
    }

    .l-orders-page__title-link:before,
    .l-orders-page__title-link:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 100%;
        margin-top: -13px;
        border: 13px solid transparent;
        border-right: 0;
    }

    .l-orders-page__title-link:after {
        margin-left: -1px;
        border-left-color: #f4f3f3;
    }

    .l-orders-page__title-link:before {
        border-left-color: #d3d3d4;
    }

    .l-orders-page__select {
        margin: 8px 0;
        padding: 0 8px;
        font-size: 14px;
    }

    .l-orders-page__app-cta {
        border: solid #ebebeb;
        border-width: 1px 0;
        margin-bottom: 28px;
    }

    .l-orders-page__no-orders-cta {
        margin: 32px 0;
    }

    .l-orders-page__no-orders-msg {
        margin: 38px 0;
        text-align: center;
    }

    .l-orders-page__throbber {
        text-align: center;
    }

    .l-orders-page__btn_back .icon-svg {
        vertical-align: middle;
        margin-top: -2px;
        margin-right: 12px;
    }