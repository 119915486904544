.l-payment-systems {
    position: relative;
    padding: 10px 0;
}
    .l-payment-systems__list {
        overflow: hidden;
    }

    .l-payment-systems__cell {
        display: inline-block;
        vertical-align: top;
        margin-bottom: 10px;
    }

    /* prefixes were added by autoprefixer */
    .l-payment-systems__tab {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -webkit-justify-content: center;
        -webkit-align-items: center;
        height: 90px;
        background: #fff;
        text-align: center;
        padding: 0 5px;
    }

    .l-payment-systems__title {
        font-size: 11px;
        word-break: break-word;
    }

    .l-payment-systems__label {
        font-size: 11px;
        color: #33a3b3;
    }

    .l-payment-systems__radio {
        display: none !important;
    }

    .l-payment-systems__frame {
        overflow: hidden;
        background: #fff;
    }

    .l-payment-systems__frame-wrap {
        padding: 16px;
    }

    .l-payment-systems__review {
        margin: 0;
        text-transform: uppercase;
    }


    .l-payment-systems__cell_disabled {
        opacity: .5;
    }

    .l-payment-systems__cell_collapsed .l-payment-systems__tab {
        padding-bottom: 0;
    }

    .l-payment-systems__cell_collapsed .l-payment-systems__frame {
        max-height: 0;
    }

    .l-payment-systems__cell_collapsed .l-payment-systems__frame-wrap {
        opacity: 0;
    }

    .l-payment-systems__cell_expanded {
        position: relative;
        z-index: 1;
    }

    .l-payment-systems__cell_expanded .l-payment-systems__tab {
        padding-bottom: 6px;
    }

    .l-payment-systems__cell_expanded .l-payment-systems__frame {
        max-height: 1700px;
    }

    .l-payment-systems__cell_expanded .l-payment-systems__frame-wrap {
        opacity: 1;
    }

.l-payment-systems_active {
}

    .l-payment-systems_active .l-payment-systems__overlay {
        position: absolute;
        left: 0; top: 0;
        width: 100%; height: 100%;
        opacity: .5;
    }

    .l-payment-systems_active .l-payment-systems__cell_collapsed {
        opacity: .5;
    }


.l-payment-systems_animated {
}

    .l-payment-systems_animated .l-payment-systems__tab {
        transition: padding-bottom 30ms;
    }

    .l-payment-systems_animated .l-payment-systems__frame {
        transition: max-height 300ms;
    }

    .l-payment-systems_animated .l-payment-systems__cell,
    .l-payment-systems_animated .l-payment-systems__frame-wrap {
        transition: opacity 300ms;
    }

    .l-payment-systems_animated .l-payment-systems__cell_collapsed .l-payment-systems__tab {
        transition-delay: 300ms;
    }

    .l-payment-systems_animated .l-payment-systems__cell_collapsed .l-payment-systems__frame {
        transition-delay: 0ms;
    }

    .l-payment-systems_animated .l-payment-systems__cell_expanded .l-payment-systems__frame {
        transition-delay: 30ms;
    }


.l-payment-systems_cols_3 {
}

    .l-payment-systems_cols_3 .l-payment-systems__cell {
        width: 33.33%;
    }

    .l-payment-systems_cols_3 .l-payment-systems__cell:nth-of-type(3n+1) .l-payment-systems__tab {
        margin-right: 6px;
    }

    .l-payment-systems_cols_3 .l-payment-systems__cell:nth-of-type(3n+2) .l-payment-systems__tab {
        margin: 0 3px;
    }

    .l-payment-systems_cols_3 .l-payment-systems__cell:nth-of-type(3n+3) .l-payment-systems__tab {
        margin-left: 6px;
    }

    .l-payment-systems_cols_3 .l-payment-systems__frame {
        width: 300%;
    }

    .l-payment-systems_cols_3 .l-payment-systems__cell:nth-of-type(3n+2) .l-payment-systems__frame {
        margin-left: -100%;
    }

    .l-payment-systems_cols_3 .l-payment-systems__cell:nth-of-type(3n+3) .l-payment-systems__frame {
        margin-left: -200%;
    }
