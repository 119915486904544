/*
 * Install App call to action block
 * */
.l-app-cta {
    display: table;
    width: 100%; height: 60px;
    font-size: 10px;
    color: #757575;
}
    .l-app-cta__text,
    .l-app-cta__icon {
        display: table-cell;
        padding: 0 3px;
        vertical-align: middle;
    }

    .l-app-cta__icon {
        width: 1%;
    }