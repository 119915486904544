/*All prefixed css-properties below are generated on http://simevidas.jsbin.com/gufoko/quiet*/
/*Options: ie >= 10, ff >= 28, Safari > 6.1, Chrome >= 29, iOS >= 7, Android >= 2.3*/

.page-layout__widgets-placeholder {

}

.page-layout__row {
    margin-bottom: 10px;
    /*UNPREFIXED SOURCE:*/
    /*display: flex;*/
    /*flex-flow: row nowrap;*/
    /*justify-content: space-between;*/

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.page-layout__column {
    position: relative;
    /*UNPREFIXED SOURCE:*/
    /*flex-basis: 0;*/
    /*align-items: stretch;*/
    /*align-content: stretch;*/
    /*display: flex;*/

    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
}

/*UNPREFIXED SOURCE:*/
/*flex-grow: @i;*/
.page-layout__column_width_1 {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}
.page-layout__column_width_2 {
    -webkit-box-flex: 2;
    -webkit-flex-grow: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
}
.page-layout__column_width_3 {
    -webkit-box-flex: 3;
    -webkit-flex-grow: 3;
    -ms-flex-positive: 3;
    flex-grow: 3;
}
.page-layout__column_width_4 {
    -webkit-box-flex: 4;
    -webkit-flex-grow: 4;
    -ms-flex-positive: 4;
    flex-grow: 4;
}
.page-layout__column_width_5 {
    -webkit-box-flex: 5;
    -webkit-flex-grow: 5;
    -ms-flex-positive: 5;
    flex-grow: 5;
}
.page-layout__column_width_6 {
    -webkit-box-flex: 6;
    -webkit-flex-grow: 6;
    -ms-flex-positive: 6;
    flex-grow: 6;
}
.page-layout__column_width_7 {
    -webkit-box-flex: 7;
    -webkit-flex-grow: 7;
    -ms-flex-positive: 7;
    flex-grow: 7;
}
.page-layout__column_width_8 {
    -webkit-box-flex: 8;
    -webkit-flex-grow: 8;
    -ms-flex-positive: 8;
    flex-grow: 8;
}


.page-layout__widget {
    width: 100%;
}
