.l-payment-agents {
}
    .l-payment-agents__cell {
        display: inline-block;
        vertical-align: top;
        margin-bottom: 10px;
    }

    .l-payment-agents__tab {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -webkit-justify-content: center;
        -webkit-align-items: center;
        height: 120px;
        padding: 5px;
        background: #fff;
        text-align: center;
        border: 1px solid #ccc;
    }

    .l-payment-agents__tab_active {
        border-color: #f36f21;
    }

    .l-payment-agents__title {
        font-size: 11px;
    }

    .l-payment-agents__radio {
        display: none !important;
    }


.l-payment-agents_cols_3 {
}

    .l-payment-agents_cols_3 .l-payment-agents__cell {
        width: 33.33%;
    }

    .l-payment-agents_cols_3 .l-payment-agents__cell:nth-of-type(3n+1) .l-payment-agents__tab {
        margin-right: 6px;
    }

    .l-payment-agents_cols_3 .l-payment-agents__cell:nth-of-type(3n+2) .l-payment-agents__tab {
        margin: 0 3px;
    }

    .l-payment-agents_cols_3 .l-payment-agents__cell:nth-of-type(3n+3) .l-payment-agents__tab {
        margin-left: 6px;
    }
