.l-delivery-options-cont {
    margin-bottom: 20px;
    /* WS-11911 hide delivery options there */
    display: none;
}
    .l-delivery-options {
        text-align: left;
        font-size: 0;
    }
    .l-delivery-options pre{
        font-size: 8px;
    }
    .l-delivery-options__option-group {
        margin-right: -1.3%;
        color: #333;
    }
        .l-delivery-options input {
            position: absolute;
            left: -9999px;
        }
        .l-delivery-options__option {
            font-size: 12px;
            text-align: left;
            line-height: 1.5;
            display: block;
        }

        .l-delivery-options__option_input {
            font-size: 11px;
            text-align: center;
            display: inline-block;
            vertical-align: top;
            width: 32%;
            min-height: 70px;
            margin-right: 1.3%;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            border: 1px solid #aaa;
            cursor: pointer;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
             -khtml-user-select: none;
               -moz-user-select: none;
                -ms-user-select: none;
                    user-select: none;

        }

        /* to cover up upper styles */
        input[type=radio] + label.l-delivery-options__option_input {
            padding: 8px 4px;
            font-size: 11px;
            display: inline-block; 
        }

        input[type=radio]:checked + label.l-delivery-options__option_input {
            background: #fffbed;
            border-color: #b6b1a0;
        }
     

            .l-delivery-options__date {
                display: block;
                font-weight: bold;
            }
            .l-delivery-options__time {
                display: block;
            }
            .l-delivery-options__price {
                display: block;
                text-transform: uppercase;
                margin-bottom: 5px;
            }
