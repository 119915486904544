/*
 * Shipping history block
 * */
.l-shipping-history {
    margin-top: -1px;
}
    .l-shipping-history__title {
        padding: 8px 13px;
        border: solid #ebebeb;
        border-width: 1px 0;
        background: #fbfbfb;
        font-size: 10px;
        color: #1e1e1e;
    }

    .l-shipping-history__item {
        margin: 0 8px;
    }

    .l-shipping-history__toggle {
    }

    .l-shipping-history__cut .l-shipping-history__item {
        border-top: 1px dashed #ececec;
    }

    .l-shipping-history__toggle,
    .l-shipping-history__more,
    .l-shipping-history__less {
        border: 0 solid #ebebeb;
    }

    .l-shipping-history__toggle {
        margin-top: 2px;
        height: 8px;
        border-bottom-width: 1px;
        text-align: center;
    }

    .l-shipping-history__less,
    .l-shipping-history__more {
        padding: 6px 7px 5px;
        border-width: 1px;
        background: #fbfbfb;
        color: #33a3b3;
        font: normal 13px/1.6 'Roboto Regular', sans-serif;
        cursor: pointer;
        position: relative;
        z-index: 1;
    }

    .l-shipping-history__cut {
        overflow: hidden;
    }

    .l-shipping-history_expanded .l-shipping-history__cut {
        height: auto;
    }

    .l-shipping-history_expanded .l-shipping-history__more {
        display: none;
    }

    .l-shipping-history_collapsed .l-shipping-history__cut {
        height: 0;
    }

    .l-shipping-history_collapsed .l-shipping-history__less {
        display: none;
    }