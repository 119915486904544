.l-checkout-progress {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 1em;
    text-align: justify;
    font-size: 14px;
    color: #000;
    position: relative;
}

    .l-checkout-progress__line {
        margin: 0 3px -1.1em;
        padding-top: 1.1em;
        border-bottom: 1px solid #c9cfd2;
    }

    .l-checkout-progress__cell {
        display: inline-block;
        width: 33.33%;
        vertical-align: top;
        position: relative;
        color: #9c999d;
    }

    .l-checkout-progress__cell-1 {
        text-align: left;
    }

    .l-checkout-progress__cell-2 {
        text-align: center;
    }

    .l-checkout-progress__cell-3 {
        text-align: right;
    }

    .l-checkout-progress__icon {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        -webkit-border-radius: 100%;
                border-radius: 100%;
        width:  2em;
        height: 2em;
        line-height: 2;
        background: #c4c4c4;
        border-color: #c4c4c4;
    }

    .l-checkout-progress__icon i {
        vertical-align: middle;
        margin-top: -1px;
    }

    .l-checkout-progress__digit {
        font-size: 18px;
        line-height: 28px;
        color: #fff;
    }

    .l-checkout-progress__label {
        margin-top: .3em;
        line-height: 1.4em;
        color: #9c999d;
    }

    .l-checkout-progress__cell_active .l-checkout-progress__label,
    .l-checkout-progress__cell_active .l-checkout-progress__digit {
        color: #000;
    }

    .l-checkout-progress__cell_active .l-checkout-progress__icon {
        color: #000;
        background: #fff;
        border: 1px solid #646464;
    }
