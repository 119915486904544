.l-voucher {
	margin: 0 -5px;
	padding: 9px 5px 3px;
	height: 43px;
	-webkit-transition: all .3s;
			transition: all .3s;
	overflow: hidden;
}

.l-voucher_hidden {
	height: 0;
	padding: 0;
}

.l-voucher_error {
	height: auto;
}

	.l-voucher__row {
	}

		.l-voucher__input-cont {
			width: 74%;
			float: left;
		}
			.l-voucher__input {
				-webkit-appearance: none;
						appearance: none;
			}

			.l-mobile-step input.l-voucher__input {
				font-size: 14px;
				margin-bottom: 0;
				height: 43px;
				-webkit-border-radius: 4px;
				        border-radius: 4px;
				border-color: #ccc;
			}
			
	.l-mobile-step .l-voucher_error input.l-voucher__input {
		border-color: #d2232a;
		color: #d2232a;
	}
	

		.l-voucher__btn-cont {
			width: 23%;
			float: right;
		}

			.l-voucher__btn {
				font-size: 14px;
				-webkit-appearance: none;
						appearance: none;
				width: 100%;

				-webkit-border-radius: 3px;
						border-radius: 3px;
				border: 0 none;
				outline: 0 none;

				background: #e5e5e5;
				height: 43px;
			}

	
		.l-voucher__error-label {
			color: #d2232a;
			font-size: 12px;
			padding-top: 6px;
			overflow: hidden;
			clear: both;
		}


