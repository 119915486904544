.l-seller-rating-top-icon {
    position: relative;
    cursor: pointer;
}
.l-seller-rating-top-icon__text {
    font-size: 11px;
    color: #588995;
    display: inline-block;
    position: absolute;
    top: 8px;
    left: 33px;
    width: 60px;
    white-space: normal;
}