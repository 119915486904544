.swiper-container,
.swiper-slide {
    height: auto;
}

.page-widget_type__banner {

}

.page-widget_type_sliding-banner {
    background: #fff;
}

/* sliding banner */
.page-widget_type_sliding-banner .swiper-container {
    width: 100%;
}

.page-widget_type_sliding-banner .swiper-pagination {
    display: none;
}
.page-widget_type_sliding-banner .page-layout__slider_bullets-navigation_enabled .swiper-pagination {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
}

.page-widget_type_sliding-banner .swiper-pagination-switch {
    background: #fff;
    border: 1px solid #666;
    border-radius: 50%;
    width: 8px;
    height: 8px;
}

.page-widget_type_sliding-banner .swiper-pagination-switch.swiper-active-switch {
    background: #ffb319;
}

.page-widget_type_sliding-banner .swiper-slide_type_page-widget {
    text-align: center;
    float: left;
}

.page-widget_type_sliding-banner .swiper-img {
    width: 100%;
}

.page-widget_type_logo-slider {
    text-align: center;
    background: #fff;
    width: 100%;
}

.page-widget_type_logo-slider .logo-slider__list {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    white-space: nowrap;
    padding: 20px 10px;
}

.page-widget_type_logo-slider .logo-slider__item {
    display: inline-block;
    vertical-align: middle;
}

.page-widget_type_logo-slider .logo-slider__item + .logo-slider__item {
    margin-left: 10px;
}

.page-widget_type_logo-slider {
}

.page-widget_type_sku-slider {
    background: #fff;
}

.page-widget_type_sku-slider .swiper-container {
    /*height: 210px;*/
    padding: 10px;
}

.page-widget_type_sku-slider .productImage {
    height: auto;
}

.page-widget_type_video {
    width: 100%;
}

.page-widget_type_video__content {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    width: 100%;
}
.page-widget_type_video iframe,
.page-widget_type_video object,
.page-widget_type_video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.page-widget_type__custom-content {

}
/*not implemented yet*/
.page-widget_type__recommendation {
    display: none;
}
.page-widget__image {
    visibility: hidden;
}
.page-widget__image_state_loaded {
    visibility: visible;
}
.page-widget__image_type_banner {
    width: 100%;
}
