/*
 * Product gallery block
 * */
.l-products {
}

.l-products_full {
    overflow: hidden;
}
    .l-products_full .l-products__item {
        margin-bottom: 4px;
        border-bottom: 1px solid #ececec;
    }

    .l-products_full .l-products__item:last-child {
        margin-bottom: 0;
        border-bottom: none;
    }

.l-products_short {
    margin: 0 12px 12px 0;
}
   .l-products_short .l-products__item {
        vertical-align: top;
        display: inline-block;
        width: 33.33%
    }

.l-products_checkout {
    overflow: hidden;
}
    .l-products_checkout .l-products__item {
        margin-bottom: 20px;
    }

    .l-products_checkout .l-products__item:last-child {
        margin-bottom: 10px;
    }