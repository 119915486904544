.l-popup {
}
    .l-popup__overlay {
        opacity: 1;
    }

    .l-popup:after,
    .l-popup__wrap {
        display: inline-block;
        vertical-align: middle;
    }

    .l-popup:after {
        content: '';
        height: 100%;
    }

    .l-popup__content {
        width: 260px;
        margin: 210px auto 0;
        padding: 24px 24px 12px;
        background: #fff;
    }

    .l-popup__controls {
        text-align: center;
        margin-top: 30px;
    }

    .l-popup__close {
        display: inline-block;
        width: 100px;
        height: 32px;
        margin: 0;
        line-height: 32px;
    }
