.l-select {
    display: block;
    position: relative;
    border: 1px solid #e2e2e2;
    white-space: nowrap;
}

.l-select select {
    display: block;
    width: 100%;
    font-size: 13px;
    border: none;
    background: transparent;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    position: relative;
    z-index: 1;
}

.l-select:after {
    position: absolute;
    top: 50%;
    content: '';
    border: solid transparent;
    border-width: 6px 4px 0;
    border-top-color: #646464;
    margin: -3px -4px 0;
}

.l-select.error {
    border-color: #d2232a;
    color: #d2232a;
}

/* Assuming that `l` is default size */
.l-select select,
.l-select_size_l select {
    padding: 12px 25px 13px 10px;
    outline: 0 none;
}

.l-select select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

.l-select:after,
.l-select_size_l:after {
    right: 10px;
}

.l-select_size_m select {
    padding: 3px 25px 3px 10px;
}

.l-select_size_m:after {
    right: 10px;
}

.l-select_rounded {
    -webkit-border-radius: 3px;
            border-radius: 3px;
        border-color: #aaa;
}

/* Redefine selectize styles */
.l-select-custom .selectize-input {
    border: none !important;
    box-shadow: none !important;
}

.l-select-custom .selectize-dropdown [data-selectable] {
    padding: 12px !important;
}

.l-select-custom input {
    height: auto !important;
}

.l-select-custom .select {
    padding: 0 !important;
    border: none !important;   
}


.l-select-custom .select:before {
    content: none !important;
}