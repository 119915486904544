/*
--- LAZADA MOBILE CORE CSS ---
@author: LazadaID
First Create: Thursday, 21 of MArch 2013
Place: OFFICE
 */
html { height: 100%; }
body.mobile{
    font-family: Roboto, Tahoma, sans-serif;
    font-weight: 400;
    overflow-x:hidden;
    font-size: 15px;
}
body.main {
    position: relative;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    min-height: 100%;
}
body.non-scrollable {
    height: 100%;
    overflow: hidden;
}
body > iframe {
    position: absolute;
}

h1,h2,h3,h4,h5,h6,p,ol,ul{
    margin: 0;
    padding: 0;
}

ol,ul{
    list-style: none;
}

a{
    text-decoration: none;
    color: #1b73b3;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

select {
    outline: none;
}

.cf:before,
.cf:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */
}
.cf:after {
    clear: both;
}

header.simple-header {
    border-bottom: 1px solid #b7b7b7;
}

header #open-search,
.shopping-cart-info .trolley,
.faq-icon a:before,
.contact-us-icon a:before,
.shipping-return-icon a:before,
.tos-icon a:before,
.sellersList .sellerItem a.icon
{
    background-image: url(/images/local_mobile_api/sprites2014.png);
    background-size: 280px 27px;
}

.orange-button, .gray-button, .blue-button{
    height: 41px;
    line-height: 41px;
}

.orange-button, .gray-button, .product-description .product-name, .product-description .product-delivery-info{
    color: #333333;
}

.orange-button{
    background: #f37022; /* Old browsers */
    /*background: -moz-linear-gradient(top,  #ffb202 0%, #faa519 100%); *//* FF3.6+ *//*
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffb202), color-stop(100%,#faa519)); *//* Chrome,Safari4+ *//*
    background: -webkit-linear-gradient(top,  #ffb202 0%,#faa519 100%); *//* Chrome10+,Safari5.1+ *//*
    background: -o-linear-gradient(top,  #ffb202 0%,#faa519 100%); *//* Opera 11.10+ *//*
    background: -ms-linear-gradient(top,  #ffb202 0%,#faa519 100%); *//* IE10+ *//*
    background: linear-gradient(to bottom,  #ffb202 0%,#faa519 100%); *//* W3C *//*
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffb202', endColorstr='#faa519',GradientType=0 ); *//* IE6-9 *//*
   */
    color: #fff;
    width: 100%;
    display: block;
    font-size: 15px;
    font-weight:400;
    /*text-shadow: 0px 2px 0px rgba(255,255,255,0.15);*/
    clear: both;
    text-align: center;
    margin: 15px 0px;
    color: #ffffff;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
}
.orange-button_large {
    height: 46px;
    font-size: 18px;
    line-height: 46px;
}

.gray-button, .blue-button{
    color: #fff;
    background: #acacac; /* Old browsers */
    /*background: -moz-linear-gradient(top,  #e5e5e5 0%, #cdcdcd 100%); *//* FF3.6+ *//*
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#e5e5e5), color-stop(100%,#cdcdcd)); *//* Chrome,Safari4+ *//*
    background: -webkit-linear-gradient(top,  #e5e5e5 0%,#cdcdcd 100%); *//* Chrome10+,Safari5.1+ *//*
    background: -o-linear-gradient(top,  #e5e5e5 0%,#cdcdcd 100%); *//* Opera 11.10+ *//*
    background: -ms-linear-gradient(top,  #e5e5e5 0%,#cdcdcd 100%); *//* IE10+ *//*
    background: linear-gradient(to bottom,  #e5e5e5 0%,#cdcdcd 100%); *//* W3C *//*
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5e5e5', endColorstr='#cdcdcd',GradientType=0 ); *//* IE6-9 *//*
    */
    width: 100%;
    display: block;
    font-size: 15px;
    font-weight:400;
    /*text-shadow: 0px 2px 0px rgba(255,255,255,0.15);
    text-shadow: 0px 2px 0px rgba(255,255,255,0.15);*/
    clear: both;
    text-align: center;
    margin: 15px 0px;
    color: #ffffff;
}
.blue-button {
    color: #fff;
    background-color: #009caf;
    width: 100%;
    display: block;
    font-weight:400;
    clear: both;
    text-align: center;
    margin: 15px 0px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
}
.gray-button .cart_submit_text {
    width: 350px;
    margin-top: 10px;
}

::-webkit-input-placeholder { /* WebKit browsers */
    color: #9c999d;
    /*font-size: 0.875em;*/
    vertical-align: middle;
    padding-top: 3px;*/
}

:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #9c999d;
    /*font-size: 0.875em;*/
    vertical-align: middle;
    padding-top: 3px;*/
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #9c999d;
    font-weight: inherit;
    /*font-size: 0.875em;
    vertical-align: middle;
    padding-top: 3px;*/
}

:-ms-input-placeholder { /* Internet Explorer 10+ */
    color: #9c999d;
    font-size: 0.875em;
    /*vertical-align: middle;
    padding-top: 3px;*/
}

.placeholdersjs {
    color: #999999 !important;
    font-size: 0.875em;
    vertical-align: middle;
    padding-top: 3px !important;
    padding-left: 10px !important;
}

.progress-bar{
    width: 100%;
    position: relative;
}

.mobilestep .progress-bar {
    padding-top: 15px;
}

.progress-bar ul{
    text-align: center;
}

.progress-bar li{
    border: 1px solid #004689;
    border-radius: 50%;
    display: inline-block;
    color: #004689;
    width: 20px;
    height: 17px;
    text-align: center;
    padding-bottom: 2px;
    margin: 3px 15px 3px 15px;
    position: relative;
}

.progress-bar li:before {
    content: " ";
    display: block;
    width: 35px;
    height: 1px;
    background-color: #004689;
    position: absolute;
    top: 10px;
    left: 20px;
}

.progress-bar li.last-child:before {
    content: none;
}

.progress-bar .current{
    color: #ffffff;
    background-color: #004689;
}

.content h2, .content div[align="justify"] h1, .content div[align="justify"] h3, .content_container h1{
    color: #2d57ad;
    font-weight: normal;
    font-size: 1.125em;
    /*border-bottom: 1px solid #f0f0f0;*/
    clear: both;
    margin-top: 10px;
    padding-bottom: 10px;
}

.content h2.bg-title {
    margin-top: 0;
    color: #4f749e;
    font-size: 16px;
}

.content h2 span{
    color: #757575;
    font-size: 0.875em;
    float: right;
    margin-top: 2px;
    margin-left: 15px;
}

.content h2:after{
    content: " ";
    display: block;
    clear: right;
}

.product-list{
    background: #ffffff;
    padding: 0 10px;
    /*margin-top: 10px;*/
    border-top: 0;
}

.product-list li {
    position: relative;
    border-bottom: 1px solid #e8e8e8;
    padding: 15px 10px;
}

.mobilestep .product-list li {
    padding: 0;
}

.product-list img.product-image,
.product-list-catalog .product-image-url img {
    position: relative;
    display: inline-block;
    float: left;
    width: 85px;
    margin-right: 10px;
}

.product-list-catalog.gridView .product-image-url img {
    max-width: 90px;
    max-height: 90px;
    width: auto;
}

.product-list-catalog.gridView.product-list-fashion .product-image-url img {
    max-width: 130px;
    max-height: none;
    width: auto;
    margin: 0;
}

.mobile-step .product-list .product-description {
    margin-top: 2px;
}
.mobile-step .product-list .product-price {
    margin-top: 3px;
    padding-top: 0;
}

@media (max-width: 400px ) {
    .mobile-step .product-list .product-description {
        width: 40%;
    }
}

@media (max-width: 350px ) {
    .mobile-step .product-list .product-description {
        width: 35%;
    }
}

.product-description{
    display: inline-block;
    float: left;
    width: 50%;
}

.product-description--cart-list {
    display: block;
    width: auto;
    float: none;
    margin-left: 95px;
}
.product-description--cart-list .product-wrapper {
    margin-right: 50px;
}

ul.cartForm .product-description {
    padding-left: 5px;
}

#form-finish-page .product-description {
    padding-left: 5px;
}

#form-finish-page .product-list > li {
    padding-top: 7px;
}

.product-description .product-name{
    display: block;
    font-size: 1em;
    margin-bottom: 10px;
    font-weight: 500;
}
.product-description .product-name a {
    color: #000;
}
.product-description .product-sticker {
    margin-top: 10px;
}

.product-restricted-text {
    position: absolute;
    left: 0px;
    z-index: 10;
    top: 77px;
    width: 100px;
    text-align: center;
    text-transform: uppercase;
    color: #c9c9c9;
    font-weight: bold;
    font-size: 0.85em;
}
.product-detail-link .product-restricted-text {
    font-size: 2em;
    top: 225px;
    width: 100%;
}
.product-list .product-price{
    color: #ff3d00;
    padding-top: 5px;
    /*font-size: 0.925em;*/
    text-align: right;
    white-space: normal;
    padding-right: 10px;
}

#form-finish-page .product-list .product-price {
    padding-right: 0;
    right: 0;
    top: 7px;
    position: absolute;
}


#form-finish-page .product-list .product-price {
    font-size: 13px;
    max-width: 80px;
    white-space: normal;
}

#form-finish-page dl.all-price{
    margin: 0;
    padding: 10px;
}
#form-finish-page dl.total-price{
    padding: 10px 10px 0;
}
.product-list .product-price_have-badge{
    padding-right: 70px;
}
.product-description .product-price-normal{
    color: #C0C0C0;
    text-decoration: line-through;
    /*padding-bottom: 5px;*/
    display: block;
    white-space: nowrap;
}
.product-description .product-price-discount{
    display: block;
    white-space: nowrap;
}

.product-description .product-stock{
    color: #65a010;
    display: block;
    font-weight: 500;
}

.product-action-trigger{
    display: inline-block;
    /*float: right;
    margin-top: 45px;*/
    position: absolute;
    right: 13px;
    bottom: 51px;
}

.product-trigger-delete{
    display: inline-block;
    margin-top: 20px;
    text-align: right;
    font-size: 0.925em;
    color: #4ab3c4;
}


/* */

.select {
    background: #fff;
    border: 1px solid #acacac;
    position: relative;
    display: block;
}
.select:before{
    position: absolute;
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #aaaaaa;
    top: 50%;
    right: 7px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
}
/* Fix conflict css .select:before*/
#credit-card-payment ul.select:before{
    content: initial;
}

.select__control {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    background-color: transparent;
    padding: 7px 10px;
    border: none;
    font-size: 0.8em;
    color: #b7b7b7;
}
/* */


.content dl{
    float: left;
    width: 100%;
    padding: 0 10px 0 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.content dt{
    color: #757575;
    float: left;
    clear: both;
    margin-bottom: 10px;
}

.content dd{
    float: right;
    color: #757575;
}

.green{
    color: #37a933
}

.content dd .product-price-current {
    color: #999;
}

.content dd .product-price{
    color: #ff3d00;
    display: inline-block;
    width: 100%;
    text-align: right;
    font-weight: 500;
}

.content dd .product-price-normal{
    color: #999999;
    text-decoration: line-through;
}

.content dd small{
    color: #757575;
    font-size: 0.925em;
    display: block;
    text-align: right;
    margin-top: 5px;
}
.content dd small.cart-tax, .content dt.cart-items-count {
    font-size: 0.925em;
}

.form-mobile.form-inside {
    padding: 10px 15px;
}

#ProductRatingFormOptions .prd-ratingOption {
    position: relative;
}

#ProductRatingFormOptions .retingStarDesc {
    display: none;
}

#ProductRatingFormOptions .prd-ratingOptionRadio:checked ~ .retingStarDesc {
    display: inline-block;
    position: absolute;
    left: 152px;
    background: none;
    font-size: 13px;
    color: #757575;
    top: 4px;
}

#ProductRatingFormOptions .prd-ratingOptionLabel {
    cursor: pointer;
}

#ProductRatingFormOptions .prd-ratingOptionLabel .icon-starts-grey {
    width: 13px;
}

#ProductRatingFormOptions .prd-ratingOptionLabel .icon-starts-gold {
    width: 13px;
}

.os_windows #RatingForm_title {
    line-height: 14px;
}

#RatingForm_title {
    line-height: 15px;
}

.form-mobile input[type="number"],
input[type="text"],
.form-mobile input[type="text"],
.form-mobile input[type="tel"],
.form-mobile input[type="email"],
.form-mobile input[type="password"],
.form-mobile input[type="search"],
input[type="email"],
.form-mobile textarea {
    /*border-radius: 5px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);*/
    border: 1px solid #acacac;
    background-color: #fcfcfc;
    color: #333;
    padding: 7px 10px;
    width: 100%;
    margin-top: 5px;
    font-size: 0.9em;
    box-sizing: border-box;
}
#form-customer-create input {
    margin-top: 0px;
}
#form-account-login input[type="email"],
#form-account-login input[type="password"],
#form-account-login input[type="text"],
#forgot-password-container input[type="email"],
#forgot-password-container input[type="text"],
#forgot-password-container input[type="password"],
.customer-create-row input[type="email"],
.customer-create-row input[type="password"],
.customer-create-row input[type="text"] {
    margin: 0;
    font-size: 15px;
    line-height: 0.7;
    height: 43px;
    font-family: Roboto, Tahoma, sans-serif;
    border-color: #aaa;
    border-radius: 4px;
    background: transparent;
    box-shadow: none;
    -webkit-appearance: none;
    appearance: none;
}

#form-account-login .l-input-field,
#form-account-login-mobile .msgArea,
#form-account-login-mobile .emailInput,
#form-account-edit-password .l-input-field {
    margin-bottom: 20px;
}
.form-mobile textarea{
    /*width: 100%;
    box-shadow: none;
    border-radius: 0;
    border: 1px solid #cccccc;
    text-indent: 10px;
    margin-top: 5px;*/
    resize: none;
    min-height: 100px;
}
.product-quantity-wrapper select{
    padding-left: 12px;
}
.form-mobile select, .product-quantity-wrapper select {
    border: none;
    z-index: 2;
    background-color: transparent;
    /*font-size: 0.9em;*/
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    opacity: 1;
}

.form-mobile select::-ms-expand {
    visibility: hidden;
}

.form-mobile select, .billing.location{
    font-family: Roboto, Tahoma, sans-serif;
    font-weight: 400;
    overflow-x: hidden;

}

.form-mobile select, .billing.location option{
    color: #484848;
}

.form-mobile .sort-filter-option {
    float: left;
    margin-top: 0;
    width: 50%;
    text-align: center;
}

.form-mobile .sort-filter-option div {
    margin-top: 0;
}

#overlay {
    position: relative;
    left: 0;
    top: -25px;
    width: 100%;
    z-index: 50;
    background: #FFF;
}

.change-billing{
	font-size:0.925em;
	}
.delete-billing{
	font-size:0.925em;
	}

.form-mobile label {
    display: inline-block;
    /*width: auto;*/
}

.form-mobile label.block{
    width: auto;
}

.form-mobile .forgot-password{
    font-size: 14px;
    color: #009caf;
}

.form-mobile button, .orange-button, .gray-button{
    border: none;
    text-shadow: none;
}

#LoginForm_email {
    margin-top: 7px;
}
div.coloum {
    float: left;
    text-align: left;
}

div.coloum-center {
    width: 75%;
    text-align: left;
    margin-left: 10px;
    line-height: 22px;
    float: left;
}

div.coloum-payment{
    width: 75%;
    text-align: left;
    margin-left: 10px;
    line-height: 22px;
}

div.coloum-right {
    float: right;
    text-align: right;
    margin: 0px;
}

.content p {
    margin: 10px 0px 10px;
}

.content p.p-404 {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
    margin-bottom: 10px !important;
    //min-height: 200px;
}

.form-mobile div ul {
    text-align: left;
}

.form-mobile div ul li{
    margin-bottom: 10px;
}

.mobile-decision-overlay, .tell-us-overlay{
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 110000;
    display: block;
    opacity:0;
    background: rgba(0,0,0,.3);
}

.mobile-decision-wrapper, .tell-us-popup {
    width: 90%;
    background-color: #ffffff;
    padding: 0px;
    text-align: center;
    margin: 20% auto;
    border: 1px solid #0D4173;
    overflow: hidden;
}

.mobile-decision-wrapper > div.header{
    display: inline-block;
    font-weight:700;
    height: 33px;
    background-color: #0D4173;
    width: 100%;
    color: white;
    padding-top: 10px;
}

.mobile-decision-wrapper > div.msg, .tell-us-popup > .msg{
    display: block;
    min-height: 33px;
    color: #666666;
    padding: 20px 20px 7px 20px;
    border-bottom: 1px solid #e5e5e5;
}

.mobile-decision-wrapper div.decision-btn{
    display: block;
    overflow: hidden;
    width: 50%;
    float: left;
}

.mobile-decision-wrapper div.decision-btn a, .tell-us-popup .decision-btn {
    display: block;
    padding: 10px 0;
    color: #666666;
    text-align: center;
}

.tell-us-popup .decision-btn span {
    display: inline-block;
    cursor: pointer;
    background: none repeat scroll 0 0 #f37022;
    border: medium none;
    color: #fff0f2;
    font-size: 13px;
    font-weight: 500;
    height: 30px;
    line-height: 30px;
    padding: 0 25px;
}

.mobile-decision-wrapper div.decision-btn > .left-border {
    border-left: 1px solid #e5e5e5;
}

.mobile-popup-overlay{
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(255,255,255,1);
    z-index: 110;
    display: block;
    opacity:0;
}

.mobile-popup-overlay-black{
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 100;
    display: block;
    opacity:0;
}

.mobile-popup-wrapper{
    width: 50%;
    background-color: #ffffff;
    padding: 10px;
    text-align: center;
    margin: 50px auto;
    border-radius: 10px;
}

.mobile-popup-wrapper span{
    display: inline-block;
    font-weight:700;
}

.mobile-popup-wrapper div{
}

input.error, select.error, textarea.error {
    border: 1px solid #d2232a !important;
}

#numberError, .errorMessage {
    margin: 0px !important;
    text-align: left !important;
}

#numberError, .errorMessage,
span.error, div.error.msg {
    padding: 3px 0px 0px 0;
    font-size: 11px;
    color: #bd1947;
    clear: left;
}
#form-customer-create #RegistrationForm_is_newsletter_subscribed {
    margin-bottom: 0;
    float: left;
    margin-top: 2px;
    margin-right: 5px;
}

.shipping_input_field + .errorMessage,
.shipping_t_input span.error {
    padding-top: 10px;
}

div.clear {
    clear: both;
}

.form-mobile div ul li div.text {
    text-align: left;
    color: #666;
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #fcfcfc;
    line-height: 22px;
}

/* revert change for webcheckout - start */
.clear-width {
    width: auto !important;
}

#coupon-error {
    border: 1px solid #bd1947 !important;
    background-color: #fffafb !important;
    padding: 6px 6px;
    font-size: 0.775em;
    color: #bd1947;
    line-height: 22px;
}

.total-price dd,
.all-price dd{
    white-space: nowrap;
    font-size: 0.875em;
}
.total-price{
    margin: 0;
    padding: 10px 10px 0 10px;
}

.subtotal-price {
    color: #333 !important;
}

div.error{
    color: #bd1947;
}

/****/
.hidden{display:none;}
#jsonAppObject{display:none}
/***/

.cf label{
    width: auto;
}

#forgot-password-container {
    padding: 0 0 10px;
    margin-top: 10px;
}

.payment-methods {
    margin-bottom: 15px !important;
    padding: 5px;
    overflow: hidden;
    position:relative;
    min-height: 26px;
}
.payment_card_protection {
    display: none;
    border: none !important;
}
.payment-methods.active .payment_card_protection{
    display: block;
}
.payment_card_protection_title {
    font-size: 14px;
}

.creditcards div {
    text-align: left;
}

div.address {
    text-align: left;
    margin: 0px !important;
    width: 200px
}

.content h2 span a {
    /*font-size: 1.5em;*/
}

#shipping_address p , #billing_address p {
    margin: 0px 0px 25px !important;
}

#form-account-payment .active label {
    font-weight:700;
}

.rfloat {
    display: block;
    margin: 10px 0px;
}

.autocomplete {
    border: 1px solid #ccc !important;
    /*max-height: 100px !important;*/
}

.autocomplete div {
    padding: 5px;
}

.cf-wrapper {
    margin-bottom: 55px;
    /*color: #999999;*/
}

.cf-wrapper .address, .cf-wrapper .payment-mobileapi{
    margin-bottom: 55px;
    color: #999999;
}

/* revert change for webcheckout - end*/

/* 08-04-2013 */
.product-list-featured .product-description {
    width: 37%;
    margin-left: 0px;
}

.product-list-featured .product-price {
    display: block;
    margin-top: 20px;
}

.product-list-featured {
    height: 140px;
}

.product-list-featured li{
    position: absolute;
    border: none;
    display: none;
}

.product-list-featured li.visible{
    display: block;
}

.product-list-featured a{
    display: block;
    width: 320px;
    margin: 0px auto;
    position: relative;
}

.category-list .parent a {
    font-weight:700;
}

.category-list a,
.category-list .category-list__link,
.category-list .username {
    color: black;
    font-size: 1em;/*1.075em;*/
    display: block;
    padding: 11px 0px;
    border-bottom: 1px solid #e8e8e8;
    position: relative;
    user-select: none;
}

.category-list .username {
    color: #f5a623;
    cursor: default;
}

.category-list .active {
    color: #f5a623;
    cursor: default;
}

.category-list .parent ul a {
    font-weight: normal;
    padding-left: 20px;
}

.category-list__submenu {
    display: none;
}

.category-list__listItem.-open .category-list__submenu {
    display: block;
}

.category-list--cms-menu .category-list__listItem--cms-menu {
    position: relative;
    font-size: 14px;
    font-weight: 300;
}

.category-list--cms-menu .category-list__listItem--cms-menu > a,
.category-list--cms-menu .category-list__listItem--cms-menu > .category-list__listItem-link {
    padding: 10px;
    border-bottom: 1px solid #f4f4f4;
    display: block;
}

.category-list--cms-menu .category-list__listItem--has-submenu > a,
.category-list--cms-menu .category-list__listItem--has-submenu > .category-list__listItem-link {
    padding-right: 30px;
}

.category-list--cms-menu .category-list__listItem-heading > a,
.category-list--cms-menu .category-list__listItem-heading > .category-list__listItem-link {
    padding-top: 7px;
    padding-bottom: 7px;
}

.category-list__submenu .category-list__listItem > a,
.category-list__submenu .category-list__listItem > .category-list__listItem-link {
    padding-left: 20px;
}

.category-list--cms-menu .category-list__listItem-heading > a {
    color: #000;
}

.category-list--cms-menu .category-list__listItem--cms-menu a.active,
.category-list--cms-menu .category-list__listItem--cms-menu.-active > a {
    color: #f5a623;
}

.category-list--cms-menu .splitter {
    display: none;
}

.category-list--cms-menu .category-list__listItem-heading {
    color: #000;
    font-weight: 400;
    background: #f4f4f4;
}

.category-list__listItem--has-submenu > a:after,
.category-list__listItem--has-submenu > .category-list__listItem-link:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 35px;
    height: 40px;
}

.category-list__listItem--has-submenu > a:after,
.category-list__listItem--has-submenu > .category-list__listItem-link:after {
    background-position: 50% 50%;
    background-size: 10px auto;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiPgogIDxkZWZzPgogICAgPHN0eWxlPgoKICAgICAgLmNscy0yIHsKICAgICAgICBmaWxsOiAjOGE4YThhOwogICAgICB9CiAgICA8L3N0eWxlPgogIDwvZGVmcz4KICA8cGF0aCBkPSJNMjMuNTAwLDEzLjAwMCBDMjMuNTAwLDEzLjAwMCAxMy4wMDAsMTMuMDAwIDEzLjAwMCwxMy4wMDAgQzEzLjAwMCwxMy4wMDAgMTMuMDAwLDIzLjUwMCAxMy4wMDAsMjMuNTAwIEMxMy4wMDAsMjMuNzc2IDEyLjc3NiwyNC4wMDAgMTIuNTAwLDI0LjAwMCBDMTIuNTAwLDI0LjAwMCAxMS41MDAsMjQuMDAwIDExLjUwMCwyNC4wMDAgQzExLjIyNCwyNC4wMDAgMTEuMDAwLDIzLjc3NiAxMS4wMDAsMjMuNTAwIEMxMS4wMDAsMjMuNTAwIDExLjAwMCwxMy4wMDAgMTEuMDAwLDEzLjAwMCBDMTEuMDAwLDEzLjAwMCAwLjUwMCwxMy4wMDAgMC41MDAsMTMuMDAwIEMwLjIyNCwxMy4wMDAgLTAuMDAwLDEyLjc3NiAtMC4wMDAsMTIuNTAwIEMtMC4wMDAsMTIuNTAwIC0wLjAwMCwxMS41MDAgLTAuMDAwLDExLjUwMCBDLTAuMDAwLDExLjIyNCAwLjIyNCwxMS4wMDAgMC41MDAsMTEuMDAwIEMwLjUwMCwxMS4wMDAgMTEuMDAwLDExLjAwMCAxMS4wMDAsMTEuMDAwIEMxMS4wMDAsMTEuMDAwIDExLjAwMCwwLjUwMCAxMS4wMDAsMC41MDAgQzExLjAwMCwwLjIyNCAxMS4yMjQsMC4wMDAgMTEuNTAwLDAuMDAwIEMxMS41MDAsMC4wMDAgMTIuNTAwLDAuMDAwIDEyLjUwMCwwLjAwMCBDMTIuNzc2LDAuMDAwIDEzLjAwMCwwLjIyNCAxMy4wMDAsMC41MDAgQzEzLjAwMCwwLjUwMCAxMy4wMDAsMTEuMDAwIDEzLjAwMCwxMS4wMDAgQzEzLjAwMCwxMS4wMDAgMjMuNTAwLDExLjAwMCAyMy41MDAsMTEuMDAwIEMyMy43NzYsMTEuMDAwIDI0LjAwMCwxMS4yMjQgMjQuMDAwLDExLjUwMCBDMjQuMDAwLDExLjUwMCAyNC4wMDAsMTIuNTAwIDI0LjAwMCwxMi41MDAgQzI0LjAwMCwxMi43NzYgMjMuNzc2LDEzLjAwMCAyMy41MDAsMTMuMDAwIFoiIGlkPSJwYXRoLTEiIGNsYXNzPSJjbHMtMiIgZmlsbC1ydWxlPSJldmVub2RkIi8+Cjwvc3ZnPgo=");
}

.category-list__listItem--has-submenu.-open > a:after,
.category-list__listItem--has-submenu > .category-list__listItem-link:after {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjIiIHZpZXdCb3g9IjAgMCAyNCAyIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KCiAgICAgIC5jbHMtMiB7CiAgICAgICAgZmlsbDogIzhhOGE4YTsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggZD0iTTAuNTAwLDAuMDAwIEMwLjUwMCwwLjAwMCAyMy41MDAsMC4wMDAgMjMuNTAwLDAuMDAwIEMyMy43NzYsMC4wMDAgMjQuMDAwLDAuMjI0IDI0LjAwMCwwLjUwMCBDMjQuMDAwLDAuNTAwIDI0LjAwMCwxLjUwMCAyNC4wMDAsMS41MDAgQzI0LjAwMCwxLjc3NiAyMy43NzYsMi4wMDAgMjMuNTAwLDIuMDAwIEMyMy41MDAsMi4wMDAgMC41MDAsMi4wMDAgMC41MDAsMi4wMDAgQzAuMjI0LDIuMDAwIC0wLjAwMCwxLjc3NiAtMC4wMDAsMS41MDAgQy0wLjAwMCwxLjUwMCAtMC4wMDAsMC41MDAgLTAuMDAwLDAuNTAwIEMtMC4wMDAsMC4yMjQgMC4yMjQsMC4wMDAgMC41MDAsMC4wMDAgWiIgaWQ9InBhdGgtMSIgY2xhc3M9ImNscy0yIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+Cg==");
    background-size: auto 1px;
}

.category-list--cms-menu .see-more {
    color: #44b1c3;
    font-weight: 400;
    font-size: 13px;
}
.category-list__listItem--is-heading {
     background: #f4f4f4;
 }
.category-list__listItem--is-heading span {
    font-weight: bold;
}
.small-banner {
    text-align: center;
}

.right-border {
    border-right: 1px solid #f0f0f0;
}

.small-banner a {
    padding: 5px;
    display: inline-block;
}

.mobile-search {
    padding: 7px 10px 0.7em 10px;
    position: relative;
    background: #fff;
    margin: 0.1em 0 0 0;
}
.mobile-search.-with-suggestions {
    background: #fafaf7;
}

.form-mobile .search-icon-wrapper {
    position: absolute;
    top: 8px;
    right: 9px;
}
.search-icon-wrapper .search-clear-icon {
    display: none;
}

.form-mobile .search-icon-wrapper button {
    padding: 0;
    width: 30px;
    height: 28px;
    background: transparent;
}

.form-mobile .search-icon-wrapper button span {
    margin-top: 1px;
}

.slideshow-position-bar, .pagination{
    text-align: center;
    padding-bottom: 15px;
}

.content-bg .galery-pagination {
    padding-bottom: 45px;
}

.slideshow-position-bar li{
    display: inline-block;
}

.slideshow-position-bar a{
    width: 16px;
    height: 6px;
    display: inline-block;
    background-color: #dbdada;
    margin: 0px 3px;
}

.slideshow-position-bar a.current{
    background-color: #114a8a;
}

.product-list.product-list-catalog {
     padding: 0;
     background: none;
     box-shadow: none;
 }
#product-more-wrapper, #product-more-wrapper .content-wrapper + .content-wrapper {
    margin-top: -1px;
    padding: 0;
    background: none;
    box-shadow: none;
}

#product-more-wrapper .product-list.product-list-catalog {
    border-top: none;
}

.product-list-catalog li {
    width: 100%;
    margin-top: 10px;
    background: #FFF;
    position: relative;
    z-index: 10;
    padding: 10px 0;
    border-bottom: 1px solid #e8e8e8;
}
.product-list-catalog.gridView:after {
    content: "";
    display: table;
    clear: both;
}
.product-list-catalog.gridView li {
    width: 50%;
    height: 230px;
    float: left;
    border-right: 1px solid #e8e8e8;
    text-align: center;
    padding-top: 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: 0;
}
.product-list-fashion.gridView li {
    height: 340px;
    padding-top: 10px;
}
.product-list-catalog .product-sticker {
    display: block;
    max-width: 60px;
}
.product-list-catalog.gridView .product-sticker {
    position: absolute;
    top: -5px;
    left: 10px;
    max-width: 50px;
}
.product-list-catalog.listView .product-sticker {
    margin-left: 112px;
}
.product-list-catalog .product-sticker img {
    width: 100%;
}
.product-list-catalog.gridView img.product-image {
    margin-bottom: 5px;
    float: none;
}
.product-list-catalog.gridView .product-price {
    margin-left: 0;
    margin-top: 10px;
    text-align: center;
}
.product-list-catalog.gridView .product-price_have-badge {
    padding-right: 0;
}
.product-list-catalog.gridView .product-name {
    padding-left: 10px;
}
.product-list-catalog.gridView .product-discount {
    bottom: auto;
    top: 15px;
    right: -1px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.product-list-catalog.gridView .product-price_have-badge .product-price-normal {
    display: inline-block;
    margin-bottom: 1px;
}

.product-list-catalog a{
    display: block;
}

.product-list-catalog .product-description {
    width: 100%;
}

.product-list-catalog .product-image,
.product-list-catalog .product-image-url img {
    width: 90px;
    height: auto;
    margin: 0 12px 0 10px;
}

.product-list-catalog .product-name{
    margin-bottom: 5px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    min-height: 26px;
    overflow: hidden;
    display: block;
    padding-right: 10px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.product-list-catalog .product-price {
    display: block;
    padding-top: 0;
    margin-left: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-list-catalog .product-price-normal {
    display: inline;
    font-weight: normal;
}

.page-title{
    color: #4f749e;
    font-size: 1.2em;
    overflow: hidden;
    padding: 10px 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.progress-bar.page-title {
    background: #dce2e9;
    color: #4f749e;
    font-weight: 500;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 16px;
    line-height: 1.2;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.page-title span {
    display: block;
    /*padding: 10px;*/
    text-transform: capitalize;
}

.page-title  a{
    color: inherit;
}
.progress-bar.page-title{
    border-bottom: none;
}

.filter-by-wrapper {
    min-height: 36px;
}
.filter-by {
    background: #fff;
    width: 100%;
    min-height: 36px;
    font-size: 0;
    white-space: nowrap;
    z-index: 1000;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-top: 1px solid #e8e8e8;
    color: #274a60;
    -webkit-backface-visibility: hidden;
    border-bottom: 1px solid #e8e8e8;
}

.filter-by.-fixed {
    position: fixed;
    top: 0;
}


.description{
    display: table;
    border-bottom: 1px solid #e3e3e3;
    border-top: 1px solid #e3e3e3;
    height: 40px;
}
.description .product-prices,
.description .product-availability{
    display: table-cell;
    vertical-align: middle;
    text-align: center;

}

.description.product-multisource {
    border-bottom: none;
}

.description .product-prices{
    border-right: 1px solid #e3e3e3;
    padding: 0 7px 5px;
}
.description .product-prices span {
    display: inline-block;
    padding: 3px 0 0;
    white-space: nowrap;
}
.description .product-availability{
    width: 40%;
}
.description .product-price{
    color: #ff3d00;
    font-size: 19px;
}
.description .product-price-past{
    color: #757575;
    font-size: 12px;
    text-decoration: line-through;
    margin-left: 8px;
}
.description .product-stock.in{
    display: block;
    color: #65a010;
    font-size: 15px;
}
.description .product-stock.out{
    display: block;
    color: #c11600;
    font-size: 15px;
}

.description.description--other-sellers {
    border-bottom: none;
    margin-bottom: -10px;
    border-top: none;
    margin-top: 0;
}

.description-detail {
    padding: 10px 15px 13px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-size: .9em;
    line-height: 1.5em;
    color: #757575;
}

.description-detail__other-sellers-info {
    padding: 3px 0;
}
.description-detail__other-sellers-info em {
    color: #4ab3c4;
    font-weight: 500;
    font-style: normal;
}
.description-detail__other-sellers-info em.price {
    color: #ff3d00;
}

.description-detail .ui-listBulleted {
    list-style: disc;
    margin-left: 10px;
}

.description-detail .ui-listBulleted li {
    margin-left: 10px;
}

.description-detail li {
    margin-bottom: 10px;
}

.description-detail span {
    display: block;
    margin-top: 5px;
}
.description-detail span.attribute {
    display: inline;
}

.description-detail .product-name {
    margin-top: 0;
    font-size: 1.1em;
}

.description-detail iframe {
    width: 100%;
}

.seller-guarantee-detail {
    text-align: center;
}

.seller-guarantee-detail li {
    display: inline-block;
    width: 35%;
    min-width: 120px;
    text-align: center;
}

.seller-guarantee-detail li img {
    height: 120px;
}

.description-detail-more-link {
    border: solid #d3d3d3;
    border-width: 1px 0;
    padding: 10px;
    text-align: center;
    font-size: 1.1em;
    margin-bottom: 15px;
    color: #4ab3c4;
}

.description-detail-more-link a, .description-detail-more-link span {
    color: #4ab3c4;
    display: block;
    cursor: pointer;
}

.seller__promotion__detail {
    margin: 15px 0 0 0;
}
.seller__promotion__detail .seller__promotion__detail__inner {
    padding: 10px;
    border-top: 1px solid #5cb6c2;
    border-bottom: 1px solid #5cb6c2;
    margin: 0 5px;
}
.seller__promotion__detail .seller__promotion__detail__heading {
    color: #5cb6c2;
    text-transform: uppercase;
    font-size: 0.867em;
    margin: 0 0 3px 0;
}
.seller__promotion__detail .text {
    color: #6a6a6a;
    font-size: 0.867em;
}
.seller__promotion__detail .description-detail-more-link {
    padding: 0;
    border-width: 0;
    border: 0;
    font-weight: 300;
    font-size: 0.867em;
}
.seller__promotion__detail .seller__gift {
    margin-top: -1px;
}
.seller__promotion__detail .description-detail-more-link:hover {
    cursor: pointer;
}

.seller__promotion__detail.product-multisource__source__seller__promotion {
    border: 1px solid #5cb6c2;
    padding: 10px;
    margin: 0;
}
.seller__promotion__detail.product-multisource__source__seller__promotion .seller__promotion__detail__inner {
    border: 0;
    padding: 0 15px;
    margin: 0;
}
.seller__promotion__detail.product-multisource__source__seller__promotion .text {
    font-size: 1em;
}

.description-details-full-text {
    display: none;
}

.description-details-full-text img {
    height: auto !important;
}

.product-seller span {
    display: inline;
}
.product-seller {
    position: relative;
    padding: 15px 0 15px 0;
    overflow: hidden;
}
.product-seller__text {
    display: inline-block;
}
.product-seller__rating {
    display: inline-block;
    position: absolute;
    right: 15px;
}

.description .product-price-normal {
    color: #999999;
}

.description .product-price-normal span {
    display: inline;
}

.product-actual-price {
    text-decoration: line-through;
}
.product-discount-percentage {
    font-weight: bold;
}

.description .product-stock{
    font-weight:500;
    display: block;
}
.description .product-stock.in {
    color: #6ABF67;
}
.description .product-stock.out {
    color: #c11600;
}
.description .product-delivery-info{
    display: block;
}

.description .product-seller {
    display: block;
}

.description .product-seller .seller-name {
    font-weight: bold;
}

.seller-list {
    font-size: .9em;
    color: #757575;
}
.seller-list .list-title {
    height: 45px;
    background: #f0f0f0;
}
.seller-list .list-title span {
    color: #333333;
    text-transform: uppercase;
    display: inline-block;
    margin: 20px 10px 0;
}
.seller-list .seller-price {
    float: right;
    white-space: nowrap;
}
.seller-list .seller-price span {
    display: block;
    max-width: 75%;
    line-height: 1.25em;
}
.seller-list .final-price {
    color: #ff3d00;
    font-weight: bold;
}
.seller-list .original-price {
    text-decoration: line-through;
    color: #999999;
}
.seller-list .mini-btn {
    /*width: 95px;*/
    padding: 0 20px;
    margin: -3px 0 0;
    font-weight: 500;
}
.seller-list .seller-option > div {
    margin: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.seller-list .stock-status {
    font-weight: bold;
    color: #37a932;
    margin-right: 5px;
}
.seller-list .seller-option {
    padding: 10px 15px 15px;
    border-bottom: 1px solid #e3e3e3;
}
.seller-list .seller-option:first-child {
    padding-top: 0;
}
.seller-list .seller-option:after {
    content: "";
    display: table;
    clear: both;
}
.seller-list .seller-logo img {
    width: 100%;
}
.seller-list .seller-logo {
    width: 60px;
    float:left;
    margin-right: 10px;
}
.seller-list .seller-info {
    /*float: left;
    max-width: 200px;*/
    color: #000;
    font-size: 15px;
}
.seller-list .seller-info span {
    display: inline-block;
    line-height: 1.25em;
    margin-top: 5px;
}
.seller-list .seller-info a {
    color: #4ab3c4;
    font-weight: 500;
}
.seller-list .seller-name {
    font-weight: 500;
}
.seller-list .stock-msg {
    max-width: 200px;
}
.seller-list .stock > span {
    display: inline-block;
    vertical-align: top;
}

.seller-list .warrantyWrapper {
    margin-top: 5px;
    font-style: italic;
    line-height: 1.5;
}

.selection-title {
    display: block;
    margin-bottom: 8px;
}

.variation-link .variation-item {
    display: inline-block;
    border: 1px solid #ffffff;
    margin: 5px 10px 0 0;
}
.variation-link .variation-item.current {
    border: 1px solid #f4772e;
}

.thumb-large {
    padding-top: 15px;
    text-align: center;
    background: #ffffff;
    position: relative;
    /*overflow: hidden;*/
    margin-top: 10px;
}

.specifications li, .whatisinbox li {
    font-size: 1em;
    margin-bottom: 10px;
}

.specifications a {
    color: inherit;
}

.specifications .description-detail img {
    width:100%;
}

.summary-total {
    border-top: 1px solid #f0f0f0;
    padding-top: 10px;
}

.summary-total .quantity {
    float: left;
}

.summary-total .total {
    float: right;
    text-align: right;
}

.summary-total .total span {
    font-size: 0.675em;
    color: #999999;
}

.summary-total .total span.price {
    color: #c11600;
    font-weight:700;
    font-size: 0.975em;
    display: block;
}

.specifications p {
    line-height: 24px;
    margin-top: 15px !important;
    padding: 0px 5px;
}

.specifications {
    border-top: none;
}

.form-mobile .cta-btn, .process-checkout-cta {
    position: fixed;
    bottom: 0;
    z-index: 1000;
    width: 100%;
    background: #fff;
    box-shadow: 0 0 10px #D0D0D0;
    border-top: 1px solid #b7b7b7;
    margin: 0;
    margin-left: 0;
    box-sizing: border-box;
    -webkit-backface-visibility: hidden;
}

.form-mobile .cta-btn .wrapper-content {
    padding: 10px;
    margin: 0;
    display: table;
    width: 100%;
    box-sizing: border-box;
}

.process-checkout-cta .wrapper-content {
    padding: 10px;
    margin: 0;
}

.process-checkout-cta .orange-button {
    margin: 0;
}
.form-mobile .cta-btn-row {
    display: table-cell;
    vertical-align: top;
}

.form-mobile .cta-btn-row-cart {
    width: 55px;
}

.form-mobile .cta-btn .cart-link {
    margin-right: 0;
    margin-top: 6px;
}
.form-mobile .cta-btn.hide {
    display: none;
}

/*.main-container.open div.cta-btn {
    left: 240px;
}

.main-container.open .process-checkout-button {
    left: 240px;
}*/

.cta-btn button {
    margin: 0;
    display: inline-block;
    line-height: 1em;
}

.cta-btn .wishlist-btn, .cta-btn .share-btn {
    display: table-cell;
    width: 42px;
    height: 41px;
    border-left: 1px solid #B7B7B7;
}
.cta-btn .wishlist-btn {
    background: url("/images/local_mobile_api/sprites_03.png") -393px 0 no-repeat;
    display: none;
}
.cta-btn .share-btn {
    background: url("/images/local_mobile_api/sprites_03.png") -434px 0 no-repeat;
    display: none;
}
.container {
    /*overflow: hidden;*/
    width: 100%;
}

.main-container {
    width: 100%;
    background-color: white;
    min-height: 100%;
    -webkit-transition: -webkit-transform 150ms ease;
    -o-transition: transform 150ms ease;
    transition: transform 150ms ease;
}

.main-container.open {
    overflow-x:hidden;
    -webkit-transform: translateX(270px);
    -moz-transform: translateX(270px);
    -ms-transform: translateX(270px);
    transform: translateX(270px);
}
.main-container.open:after, .main-container.overlay:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000000;
    z-index: 100000;
    display: block;
    top: 0;
    left: 0;
    opacity: 0.5;
}

.main-container_gray {
    background: #f1f1f1;
}

.left-container {
    position: absolute;
    left: -270px;
    width: 270px;
    background-color: white;
    display:none;
}

.left-container.open {
    display: block;
    -webkit-transform: translateX(270px);
    -moz-transform: translateX(270px);
    -ms-transform: translateX(270px);
    transform: translateX(270px);
}

.bodyfix .main-container.open, .bodyfix .left-container.open{position:fixed !important;}

.menu-list a,
.menu-list .category-list__link,
.menu-list .username {
    padding-left: 40px;
    background-position: 10px center;
    background-repeat: no-repeat;
}

#content .menu-list a {
    padding-left: 45px;
}
#content .menu-list a > .icon {
    left: 15px;
}
#content .menu-list a .icon.icon-arrow-right-side {
    left: auto;
    right: 15px;
}
.menu-list a .icon,
.menu-list .category-list__link .icon {
    position: absolute;
    left: 10px;
}
.menu-list a .icon-list {
    top: 15px;
}
.menu-list a .icon-logout {
    top: 12px;
}
.menu-list a .icon-faq,
.menu-list a .icon-contact {
    top: 11px;
}

.menu-list .homepage-icon a{
    background-image: none;
}

.menu-list .category-icon a{
    background-image: none;
}

.menu-list .brand-icon a{
    background-image: none;
}

.menu-list .account-icon a{
    background-image: none;
}

.menu-list .sign-in-icon a{
    background-image: none;
}

.menu-list .help-icon a{
    background-image: none;
}

.menu-list .icon-svg {
    position: absolute;
    left: 13px;
    top: 12px
}

.shopping-cart-info {
    background-color: #dce2e9;
    padding: 10px;
}

.shopping-cart-info .trolley {
/*    float: left;
    width: 41px;
    height: 27px;
    margin-bottom: 45px;
    background-position: 0 0 ;
    text-align: center;
    font-weight:700;
    padding-left: 3px;
    margin-right: 10px;
    position: relative;
    font-family: "Roboto-Bold", arial, sans-serif;*/
    font-size: 0.8em;
    background: none;
    vertical-align: top;
    display: inline-block;
    margin-top: 2px;
    line-height: 1.2em;
    margin-left: 3px;
}

.shopping-cart-info .title {
    font-weight: 500;
    font-size: 18px;
    color: #4f749e;
    text-transform: capitalize;
}

.shopping-cart-info .price {
    font-weight: 500;
    display: block;
    font-size: 16px;
    color: #ff3d00;
    margin-top: 5px;
    margin-bottom: 5px;
}

.shopping-cart-info .tax {
    color: #757575;
    font-size: 0.775em;
}

.language-switcher {
    line-height: 19px;
}

.language-switcher .title {
    padding: 10px 0px 10px 40px;
}

.language-switcher .title,
.language-switcher .language-item {
    color: black;
    font-size: 15px;
    display: block;
    position: relative;
    user-select: none;
    cursor: pointer;
    color: #757575;
}

.language-switcher .icon {
    position: absolute;
    left: 12px;
    top: 12px;
}
.menu-list a .icon.icon-arrow-right-side {
    left: inherit;
    right: 11px;
    top: 14px;
}

.language-switcher .icon.icon-expand-icon,
.language-switcher .icon.icon-collapse-icon,
.language-switcher .icon.icon-check-icon,
.language-switcher .icon.icon-arrow-active,
.language-switcher .icon.icon-check {
    left: auto;
    right: 11px;
    top: 14px;
}

.language-switcher .icon.icon-collapse-icon {
    right: 8px;
}

.language-switcher .title span,
.language-switcher .icon {
    display: inline-block;
    vertical-align: middle;
}

.language-switcher .language-list {
    overflow: hidden;
    max-height: 151px;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.language-switcher.collapse .language-list {
    max-height: 0;
}

.language-switcher .language-item a {
    padding: 11px 0px 10px 57px;
    display: block;
}

.language-switcher .language-item .icon-svg {
    left: 33px;
}

.language-switcher .language-item .icon.icon-current-language {
    top: 16px;
    left: 15px;
}

.language-switcher .language-list__title {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.language-switcher.open .language-list__title:after {
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCIgd2lkdGg9IjI4IiBoZWlnaHQ9IjE0IiB2aWV3Qm94PSIwIDAgMjggMTQiPgogIDxkZWZzPgogICAgPHN0eWxlPgoKICAgICAgLmNscy0yIHsKICAgICAgICBmaWxsOiAjODk4OTg5OwogICAgICB9CiAgICA8L3N0eWxlPgogIDwvZGVmcz4KICA8cGF0aCBkPSJNMC4yNDMsMC4xNzcgQy0wLjMzMSwwLjY3OSAwLjIzNCwxLjEwNyAwLjk3MSwxLjg3MiBDMC45NzEsMS44NzIgMTIuMTI0LDEzLjM5NSAxMi4xMjQsMTMuMzk1IEMxMi44NjEsMTQuMTYwIDE0LjA5OSwxNC4yMDYgMTQuODkwLDEzLjQ5OSBDMTQuODkwLDEzLjQ5OSAyNy4wNDcsMi42MzQgMjcuMDQ3LDIuNjM0IEMyNy40NDIsMi4yODAgMjguMzA1LDEuNjcxIDI3Ljg3MSwxLjIxMiBDMjcuMzg1LDAuNzAwIDI2Ljk1MywxLjA4OSAyNS44NjMsMi4wNjMgQzI1Ljg2MywyLjA2MyAxMy42MDUsMTIuOTA3IDEzLjYwNSwxMi45MDcgQzEzLjYwNSwxMi45MDcgMS45ODUsMS4xNjkgMS45ODUsMS4xNjkgQzEuMjQ4LDAuNDA0IDAuODI1LC0wLjMzMiAwLjI0MywwLjE3NyBaIiBpZD0icGF0aC0xIiBjbGFzcz0iY2xzLTIiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPgo8L3N2Zz4K');
    background-size: 100%;
    position: absolute;
    top: 17px;
    right: 10px;
    width: 14px;
    height: 7px;
}

.language-list__title:after {
    content: "";
    display: block;
    width: 7px;
    height: 14px;
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCIgd2lkdGg9IjE0IiBoZWlnaHQ9IjI4IiB2aWV3Qm94PSIwIDAgMTQgMjgiPgogIDxkZWZzPgogICAgPHN0eWxlPgoKICAgICAgLmNscy0yIHsKICAgICAgICBmaWxsOiAjODk4OTg5OwogICAgICB9CiAgICA8L3N0eWxlPgogIDwvZGVmcz4KICA8cGF0aCBkPSJNMC4xNzcsMjcuNzU3IEMwLjY3OSwyOC4zMzEgMS4xMDcsMjcuNzY2IDEuODcyLDI3LjAyOSBDMS44NzIsMjcuMDI5IDEzLjM5NSwxNS44NzYgMTMuMzk1LDE1Ljg3NiBDMTQuMTYwLDE1LjEzOSAxNC4yMDYsMTMuOTAxIDEzLjQ5OSwxMy4xMTAgQzEzLjQ5OSwxMy4xMTAgMi42MzQsMC45NTMgMi42MzQsMC45NTMgQzIuMjgwLDAuNTU4IDEuNjcxLC0wLjMwNSAxLjIxMiwwLjEyOSBDMC43MDAsMC42MTUgMS4wODksMS4wNDcgMi4wNjMsMi4xMzcgQzIuMDYzLDIuMTM3IDEyLjkwNywxNC4zOTUgMTIuOTA3LDE0LjM5NSBDMTIuOTA3LDE0LjM5NSAxLjE2OSwyNi4wMTUgMS4xNjksMjYuMDE1IEMwLjQwNCwyNi43NTIgLTAuMzMyLDI3LjE3NSAwLjE3NywyNy43NTcgWiIgaWQ9InBhdGgtMSIgY2xhc3M9ImNscy0yIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+Cg==');
    background-size: 100%;
    position: absolute;
    top: 14px;
    right: 10px;
}

/* Style change start */
.main-container.open #lazada-logo{display:none}

.pagination1{text-align:center;}
.swiper-pagination-switch {display: inline-block; width: 16px; height: 6px; background: #dbdada; margin: 0 3px; cursor: pointer;}
.swiper-active-switch.swiper-activeslide-switch,
.swiper-active-switch { background-color: #114a8a;}

/* swiper banner */
.swiper-item-banner {
    /*width: 100%;
    overflow: hidden;*/
}
.swiper-item-banner .swiper-wrapper {
    /*height: 120px;
    box-shadow: 0px 10px 20px #dddddd;
    -moz-box-shadow: 0px 10px 20px #dddddd;
    -webkit-box-shadow: 0px 10px 20px #dddddd;*/
}
.swiper-container, .swiper-slide {
    height: 120px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.swiper-item-banner .swiper-slide img {width:100%;}
.swiper-item-banner .pagination1{
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    position: relative;
    z-index: 100;
    padding: 3px 3px 1px;
    height: 14px;
    background: #ffffff;
}
.swiper-item-banner .swiper-pagination-switch, .swiper-product-gallery .swiper-pagination-switch {
    margin: 0 2px;
    width: 7px;
    height: 7px;
    border: 0;
    background: #d5d5d5;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    vertical-align: top;
}
.swiper-item-banner .swiper-active-switch.swiper-activeslide-switch,
.swiper-item-banner .swiper-active-switch,
.swiper-product-gallery .swiper-active-switch {
    background: #2b4b62;
}
.swiper-product-gallery .pagination {
    padding-bottom: 0;
    margin-top: 10px;
}
.product-list-featured li{position: static; border: none; display: block; position:relative;}
.product-list-featured {overflow:hidden;}
.category-list-more{
    display: block;
    text-align: right;
    width: 10px;
    height: 17px;
    margin-left: 5px;
    vertical-align: middle;
    background: url("/images/local_mobile_api/sprites_02.png") -282px 0px no-repeat;
    position: absolute;
    right: 10px;
    top: 14px;
}
.category-list a{position:relative;}
.visible .category-list-child{display:block !important;}
.itm-rat{width: 85px; display: inline-block;vertical-align: middle;}
.itm-ratStars{background: url('/images/spinbasket/icons/stars.png') no-repeat; display: block; overflow-x:hidden;}
.itm-ratStars.itm-ratRating{width: 70%;height: 20px;display: block;background: url('/images/spinbasket/icons/stars.png'); background-position: 0 -20px;}
.hidden{display:none;}

.swiper-gallery li.swiper-slide,
.swiper-gallery{
    background: #fff;
    float: left;
    height: 300px !important;
    width: 100%;
}
.swiper-gallery{
    padding-bottom: 10px;
}
.swiper-gallery li.swiper-slide iframe {
    max-width: 100%;
    max-height: 100%;
}

.thumb-small img{height:auto !important; width:44px !important;}
.product-list-featured {width: 350px;margin: 0px auto;height: 160px !important;}
.product-list-featured img.product-image{height: 140px !important;width: 140px !important;}
.prd-ratingOptionLabel {
    width: 20px !important;
    float:left;
}

.prd-ratingOptionLabel > span {
    background: url("/images/spinbasket/icons/stars.png") repeat-x scroll 0 -2px transparent;
    padding: 0 8px;
}

.prd-ratingOptionLabel > span.active,
.prd-ratingOptionLabel > span.fill {background-position: 0 -22px;}
/*.webcheckout.success * {display: none !important;}*/
.animated {
    top: 0px !important;
    right: 0px !important;
    height:0px !important;
    width:0px !important;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}
/*.ratingBox {background: url("/images/spinbasket/icons/stars.png") repeat-x scroll 0 0px transparent; height: auto; width: 85px;}*/
.ratingBox{line-height: 1;}
/*.rating {background: url("/images/spinbasket/icons/stars.png") repeat-x scroll 0 -20px transparent; height: 20px;}*/
/* Style change end*/

.menu-list a, .menu-list .category-list__link {
    position: relative;
    color: #555;
    cursor: pointer;
}

/*.menu-list .homepage-icon a:before {
    background-image: url(/images/local_mobile_api/sprites_02.png);
    content: " ";
    display: block;
    width: 18px;
    height: 17px;
    position: absolute;
    background-position: -214px 0px;
    left: 10px;
    background-color: transparent;
}

.menu-list .category-icon a:before {
    background-image: url(/images/local_mobile_api/sprites_02.png);
    content: " ";
    display: block;
    width: 22px;
    height: 19px;
    position: absolute;
    background-position: -192px 0px;
    left: 10px;
    background-color: transparent;
}*/

.menu-list .brand-icon a:before {
    background-image: url(/images/local_mobile_api/sprites_02.png);
    content: " ";
    display: block;
    width: 17px;
    height: 19px;
    position: absolute;
    background-position: -232px 0px;
    left: 10px;
    background-color: transparent;
}

/*.menu-list .account-icon a:before {
    background-image: url(/images/local_mobile_api/sprites_02.png);
    content: " ";
    display: block;
    width: 19px;
    height: 20px;
    position: absolute;
    background-position: -249px 0px;
    left: 10px;
    background-color: transparent;
}*/

/*.menu-list .sign-in-icon a:before {
    background-image: url(/images/local_mobile_api/sprites_02.png);
    content: " ";
    display: block;
    width: 13px;
    height: 17px;
    position: absolute;
    background-position: -268px 0px;
    left: 12px;
    background-color: transparent;
}*/

/*.menu-list .help-icon a:before {
    background-image: url(/images/local_mobile_api/sprites_02.png);
    content: " ";
    display: block;
    width: 18px;
    height: 18px;
    position: absolute;
    background-position: -188px 18px;
    left: 10px;
    background-color: transparent;
}*/

/*  Additional 12-04-2013 */
.thumb-small{
    text-align: center;
    margin-top: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid #f0f0f0;
}

.thumb-small li{
    display: inline-block;
    margin: 0px 10px;
}

.thumb-small a{
    width: 45px;
    height: 56px;
    display: inline-block;
}

.thumb-small a.current{
    border-bottom: 3px solid #114a8a;
}

.thumb-small img{
    width: 45px;
    height: 56px;
}

.input-bar{
    /*box-shadow: inset 0px 2px 3px rgba(0,0,0,0.15);*/
    border: 1px solid #acacac;
    background-color: #FFF;
    width: 100%;
    height: 29px;
    text-align: left !important;
    border-radius: 5px;
    padding-right: 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    /*display: table-cell;*/
}

.input-bar input[type="text"], .input-bar input[type="search"] {
    border: none !important;
    background-color: rgba(0, 0, 0, 0) !important;
    box-shadow: none !important;
    display: block;
    box-sizing: border-box !important;
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    font-size: 13px;
    height: 30px;
    padding: 0 10px;
    font-family: Roboto, Tahoma, sans-serif;
    vertical-align: top;
}

.input-bar input[type="search"]::-webkit-input-placeholder { font-style: italic; padding: 0; vertical-align: top; }
.input-bar input[type="search"]::-moz-placeholder { font-style: italic; padding: 0; vertical-align: top; }
.input-bar input[type="search"]:-ms-input-placeholder { font-style: italic; padding: 0; vertical-align: top; }
.input-bar input[type="search"]:-moz-placeholder { font-style: italic; padding: 0; vertical-align: top; }

.input-bar input[type="text"]:focus, .input-bar input[type="search"]:focus {
    outline: none;
}

.input-bar .input-bar-container {
    margin: 0;
}

.review-rating {
    margin-top: 10px;
}

.review-rating span {
    color: #999;
    vertical-align: middle;
}

.thumb-large img {
    max-width: 280px;
}

.msgBox {
    background-color: #fffcb4;
    font-size: 0.875em;
    line-height: 1.3;
    font-weight:500;
    padding: 5px 12px;
    border-bottom: 1px solid #f8f373;
    color: #8c8714;
}

.content div[align="justify"]{
    text-align: left !important;
}

.content div[align="justify"] p, .content_container p{
    line-height: 22px;
    font-size: 0.875em !important;
}

.l-pageWrapper.static-content {
    background: #FFF;
    padding: 0 10px;
}

.static-content iframe{
    max-width: 100% !important;
}

.static-content .left_col {
    display: none;
}

.static-content {
    padding: 15px !important;
}

.static-content .content_container {
    padding: 0;
    line-height: 22px;
    font-size: 16px;
}

.static-content .content_container h2 {
    margin-top: 20px;
}

.static-content .content_container .right_col {
    float: none;
    width: auto;
    padding: 0px;
    border-left: none;
}

.return-wrapper .content_container .right_col {
    width: auto;
}

.static-content ul {
    margin-left: 20px;
    list-style-type: disc;
    font-size: 0.875em;
}

.static-content ul li {
    margin-bottom: 10px;
}

span.required {
    display: none;
}

.review-stars a {
    display: block;
    padding: 0px 0px 20px 0px;
}

#devBar, .slideDevBar {
    display: none;
}

.review-comment {
    padding: 10px 15px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.review-comment h3 {
    font-size: 0.875em;
}

.review-content {
    color: #999999;
    font-size: 0.875em;
    margin-top: 5px;
}

.lhmxl.mls {
    font-size: 0.875em;
    margin-top: 5px;
    display: block;
}

.rating-stars {
    margin-top: 15px;
    font-size: 1.2em;
}

.variation.size {
    text-align: left;
    font-size: 0.875em;
    padding-left: 20px;
    padding-top: 10px;
}

.specifications h1 {
    font-size: 1.5em;
}

div.input {
    position: relative;
    text-align: left;
}

/*label[for="banktransfer"]{*/
    /*margin-left: 5px;*/
/*}*/

.prd-ratingOptionTitle {
    margin-right: 10px;
}

.input-bar{
    margin: 0 !important;
}

.product-quantity {
    display: block;
    margin: 10px 0px;
    color: #999;
}

.content h2 span{
    text-transform: capitalize;
}

.product-list-featured img.product-image{
    height: 160px !important;
    width: 160px !important;
}

.product-list-featured .product-description{
    margin-left: 5px;
    margin-top: 20px
}

.product-list-featured .product-name{
    font-size: 0.875em;
    max-height: 80px;
    position: relative;
}

.product-list-featured{
    height: 180px !important;
}

.search-suggestion, .search-history {
    position: absolute;
    top: 46px;
    left: 0px;
    background-color: #fafaf7;
    z-index: 150;
    width: 100%;
    font-size: 0.8em;
    display: none;
    /*-webkit-box-shadow: 0px 3px 3px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 3px 3px rgba(0,0,0,0.1);
    -ms-box-shadow: 0px 3px 3px rgba(0,0,0,0.1);
    box-shadow: 0px 3px 3px rgba(0,0,0,0.1);*/
}

.search-history ul {
    border-bottom: 1px solid #c5c5c3;
}

.search-suggestion li, .search-history li {
    margin-bottom: 0px !important;
}

.search-suggestion a, .search-suggestion .no-result {
    color: #333333;
    display: block;
    padding: 15px;
    font-size: 1em;
}
.search-suggestion .ssg-item:first-child {
    /*border-bottom: 1px solid #c7c7c7;*/
}
.search-suggestion__hide {
    margin: 0 !important;
    padding: 8px;
    color: #4ab3c4;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    border-top: 1px solid #c5c5c3;
    border-bottom: 2px solid #f6b58c;
    cursor: pointer;
}
.search-suggestion__hide span:after {
    content: "";
    display: inline-block;
    border-bottom: 4px solid;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    margin-left: 4px;
    vertical-align: 2px;
}
.search-history .search-history__header {
    border-bottom: 2px solid #f6b58c;
    margin: 0;
    padding: 10px 15px 10px 20px;
    font-size: 14px;
    color: #151414;
    display: none;
    font-weight: 500;
}
.search-history__clear {
    float: right;
    color: #44b1c3;
    font-weight: normal;
}
.search-history .ssg-item, .search-suggestion .ssg-item {
    border-top: 1px solid #c5c5c3;
}
.search-history .ssg-item:first-child {
    border: none;
}
.search-history .ssg-item:hover {
    background: rgba(0, 0, 0, 0.1);
}
.search-history .ssg-item {
    padding: 0 10px;
}
.search-history .ssg-item a {
    padding: 11px 10px;
    color: #757575;
    font-size: 13px;
    display: block;
}
.cta-arrow {
    display: inline-block;
    background-image: url(/images/local_mobile_api/sprites_04.png);
    width: 9px;
    height: 25px;
    background-position: -5px -5px;
    position: absolute;
    right: 5px;
    top: 10px;
    z-index: 0;
}

.specifications-detail {
    position: relative;
}

.specifications-detail a{
    display: block;
    position: relative;
    z-index: 1;
}
.specifications-detail .catSubTitle:first-child {
    border-top: none;
}


.form-mobile div ul li div.text {
    font-size: 0.925em;
}
.form-mobile div ul.search-suggestion {
    box-shadow: 0 4px 8px -4px grey;
}
.msgBox{
    font-size: 0.925em !important;
    text-align: center;
}

.product-description .product-price, .product-description .product-price-normal{
   /* position: relative;*/
    z-index: 1;
    min-height: 20px;
    text-align: left;
    /*font-size: 0.925em;*/
}
.product-description .product-stock{
    margin-top: 5px;
    font-size: 0.925em;
}

.product-description .product-delivery-info {
    font-size: 0.925em;
    color: #757575;
}
.product-description .product-delivery-info .delivery-label,
.product-description .product-delivery-info .delivery-value {
    display: inline-block;
    width: 100%;
}

body.cart .product-image {
    margin-right: 5px;
}

.p-404{
    line-height: 24px;
}

.float-itm{position:absolute; width:280px; height:280px; z-index:10;}

/* checkout for apps */
.webcheckout .form-mobile div ul li div.text {
    background-color: white;
    border: none;
    margin: 0px;
    padding: 5px 25px;
}

.mobile .form-mobile div ul li div.text {
    background-color: white;
    border: none;
    margin: 0px;
    padding: 5px 25px;
}

.webcheckout input[type="radio"] {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 1px solid #05467e;
    -webkit-appearance: none;
    border-radius: 50%;
    box-shadow: inset 0px 0px 0px 2px #fff;
}

.webcheckout input[type="radio"]:checked {
    background-color: #f6a118;
}

.webcheckout #form-account-payment label {
    vertical-align: top;
}

.webcheckout #checkout-address #billing_address .g80.block:before {
    content: " ";
    display: block;
    height: 22px;
    left: 33px;
    position: absolute;
    width: 36px;
}

input[type=checkbox] {
height:15px;
width:15px;
}

.webcheckout .inactive input[type="radio"] {
    background-color: #f2f2f2;
    border: 1px solid #ccc;
}

.product-tab {
    border-bottom: 1px solid #e4e4e4;
    position: relative;
    margin-bottom: -2px;
}

.product-tab li {
    display: inline-block;
}

.product-tab .current {
    color: black;
    border-left: 1px solid #bfbfbf;
    border-right: 1px solid #bfbfbf;
    border-top: 1px solid #bfbfbf;
    position: relative;
    background-color: #fff;
    font-weight:700;
}

.product-tab a {
    display: block;
    padding: 10px;
    font-size: 0.9em;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid #fff;
}

.product-tab .current:after {
    display: block;
    content: " ";
    background-color: #ffffff;
    height: 2px;
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: -2px;
    z-index: 2;
}

.review-stars .cta-arrow {
    display: none;
}

.review-stars h2 {
    margin-bottom: 15px;
}

.product-discount {
    text-align: center;
    background-color: #f37020;
    color: white;
    position: absolute;
    left: 50%;
    width: auto;
    height: auto;
    font-size: 1.2em;
    line-height: normal;
    padding: 2px 13px;
    display: inline-block;
    border-radius: 0;
    z-index: 1;
}

.product-discount:after {
    content: " ";
    display: block;
    width: 0px;
    height: 0px;
    position: absolute;
    bottom: -10px;
    left: 0px;
    border-style: solid;
    border-width: 20px 20px 0 0;
    border-color: #c11600 transparent transparent transparent;
    z-index: -1;
}

.product-list-catalog .product-discount {
    left: auto;
    right: 0;
    bottom: 35px;
    font-size: 15px;
    line-height: 18px;
    padding: 2px 7px 2px 10px;
    border-radius: 3px 0 0 3px;
    position: absolute;
    height: 17px;
}

.product-list-catalog .product-discount--with-sticker {
    bottom: 96px;
}

.product-list-catalog .product-discount:after {
    display: none;
}

.swiper-item .product-discount {
    right: auto;
    padding: 2px 5px;
}

.form-select {
    display: block;
    width: 100%;
    overflow: hidden;
    background: transparent url('/images/local_mobile_api/dropdown_bg.png') right bottom no-repeat;
    padding: 0;
    margin: 10px 0 0 0 !important;
    border-bottom: 1px solid #cccccc;
    position: relative;
    z-index: 10;
}
.form-select > select {
    width: 150%;
    min-width: 35px;
    border: none;
    height: 33px;
    padding-top: 8px;
	margin-top:8px;
	color:#999999;
}
.form-select.error{
    border: 1px solid #bd1947 !important;
}
.required-select {
    display: block;
    color: gold;
    float: right;
    position: absolute;
    top: 21px;
    right: 15px;
    height: 10px;
    width: 10px;
    z-index: 3;
}

.form-select > .required-select {
    top: 12px !important;
}
.form-select-checkout {
    width: 45%;
    float: left;
    margin-top: 0px !important;
}

.line .form-select-checkout:first-child {
    margin-right: 10%;
}

#cc_valid_mobile li{
    padding-bottom: 0px;
}


@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .input-bar input[type="text"]{
        width: 100% !important;
        text-indent: 3px;
    }

    .progress-bar li{
        padding-bottom: 2px;
        padding-top: 2px;
    }

    .return-wrapper {
        height: 280px;
    }

    .return-wrapper .l-pageWrapper {
        height: 280px;
    }
}

@media only screen and (orientation : portrait) {
    .prdListWrapper {
        height: 214px !important;
    }

    .prdWrapper {
        width: 130px !important;
    }

    .prdInfo .prdTitle {
        height: 42px !important;
    }
    .swiper-slide .prdInfo .prdTitle {
        height: 48px !important;
        overflow: hidden;
    }
}

@media only screen and (min-device-width : 720px) and (max-device-width : 1280px) and (orientation : landscape) {
    .input-bar input[type="text"]{
        width: 100% !important;
    }

    .progress-bar li{
        padding-bottom: 2px;
        padding-top: 2px;
    }
}

a.no-parent {
    font-weight: normal !important;
}

.mobile-parent-popup {
    display: table;
    width: 100%;
    height: 100%;
}

.mobile-child-popup {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.buynow-cta {
    font-size: 0.975em;
    font-weight:700;
    margin-top: 10px;
}

.small-banner img {
    max-width: 280px;
}

.autocomplete {
    max-height: none !important;
}

.autocomplete div {
    background-color: #ffffff !important;
    border-bottom: 1px solid #ccc;
}

.specifications iframe {
    max-width: 280px;
}

.mobile-child-popup span{
    display: inline-block;
    color: #ccc;
    margin-left: 4px;
    font-size: 20px;
    line-height: 1;
    vertical-align: middle;
}

.mobile-popup-overlay img {
    display: inline-block;
    vertical-align: middle;
}

.webcheckout .mobile-popup-overlay{visibility:hidden}

.payment-type-label {
    background-image: url('/images/local_mobile_api/sprite_payments_default.png');
    display: inline-block;
    width: 36px;
    height: 26px;
    background-position: 0px 0px;
    margin-left: 5px;
    text-indent:-10000px;
    vertical-align: middle;
}

.payment-type-label_linepay {
    background-image: url('/images/local_mobile_api/payment_linepay-16.png');
    width: 53px;
    height: 16px;
}

.payment-type-label.id-payment{
    background-image: url('/images/ventures/id/local_mobile_api/sprite_payments_id.png') !important;
    width: 42px;
    height: 25px;
}

body.mobile #form-account-payment label{
    vertical-align: top;
}

body.mobile #form-account-payment .for-paypal label[for='paypal'] span:first-child {
    position: relative;
    top: -3px;
}

body.mobile #form-account-payment label[for=cashondelivery] span, body.mobile #form-account-payment label[for=paypal] span {
    position: relative;
    top: -3px;
}
.show-spinner {
    visibility: visible !important;
}

#form-account-payment .creditcards span {
    overflow: hidden;
    text-indent: -9999px;
}
#form-account-payment .creditcards span.payment-type-label {
    filter: none;
    -webkit-filter: grayscale(0);
}

#form-account-payment .creditcards span.payment-type-label.grey {
    xwidth: 44px;
    filter: url("data:image/svg+xml;utf8,<svg%20xmlns='http://www.w3.org/2000/svg'><filter%20id='grayscale'><feColorMatrix%20type='matrix'%20values='0.3333%200.3333%200.3333%200%200%200.3333%200.3333%200.3333%200%200%200.3333%200.3333%200.3333%200%200%200%200%200%201%200'/></filter></svg>#grayscale"); /* Firefox 3.5+ */
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(1);
}

#form-account-payment .i-hellopay-payment {
    background: url("/images/venture_id/icons/HelloPay.png") no-repeat scroll 0 0 transparent;
    vertical-align: bottom;
    display: block;
    float: left;
    height: 25px;
    width: 112px;
}

.minor-text{
    font-size: 12px;
}

.middle-text{
    font-size: 14px;
}

#searchInput {
    margin: 0;
    height: 28px;
    text-overflow: ellipsis;
}

#cc_security_code {
    clear: both;
}
.grandTotal {
	font-weight:bold;
	}
.form-mobile .address_container{
    overflow-x:hidden;
}

#checkout-success-content .ss-form-bcard-container{
    background-color: #FFF;
    border-top: 1px solid #CCC;
    padding:15px 20px;
    font-size: 0.9em;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
}
#checkout-success-content #bcard_logo {
    background: url(/images/spinbasket/checkout/BCard_logo.jpg) no-repeat;
    background-size: 80px 37px;
    display: table-cell;
    width: 80px;
    height: 37px;
    vertical-align: top;
    padding: 0;
    margin-right: 10px;
}

#checkout-success-content .bcart-text{
    display: table-cell;
    vertical-align: middle;
    padding-left: 10px;
}
.font-normal {
    font-weight: normal;
    font-size: 13.5px;
}

#checkout-success-content .bcard_input {
    width: 75%;
    display: inline-block;
    vertical-align: middle;
    margin-left: 7px;
    height: 32px;
    line-height: 32px;
    margin: 0 0 15px 0;
    box-sizing: border-box;
}
#checkout-success-content .ss-q-submit {
    display: inline-block;
    width: 22%;
    cursor: pointer;
    padding: 6px;
    float: right;
    border: none;
    background: #ffb202;
}
#checkout-success-content .ss-q-submit:hover {
    cursor: pointer;
}
#checkout-success-content .addpoint_icon {
    background-image: url("/images/spinbasket/checkout/add_address_icon.png");
    display: inline-block;
    width: 6px;
    height: 10px;
    vertical-align: middle;
}
#checkout-success-content .addpoint_text {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 20px;
}
#checkout-success-content .bcardtitle {
    margin-bottom: 15px;
}
#checkout-success-content .ss-signup {
    margin-top: 5px;
    color: #767676;
}
#checkout-success-content .ss-signup strong {
    color: #000;
    font-weight: normal;
    padding-right: 5px;
}

.ss-error {
    color: #c00;
    font-weight: bold;
    margin: 8px auto;
}

.ss-sucess {
    font-weight: bold;
    margin: 8px auto;
    font-size: 12px;
}

.product-slide-wrapper{
    display: block;
    overflow: hidden;
    width: 160px;
    position: relative;
    float: left;
}

#credit-card-payment-recurring {
    display: block;
    overflow: hidden;
}

#credit-card-payment-recurring .form-select-checkout {
    width: 100%;
}

#smartbanner {
    display: none;
}

#smartbanner .block {
    display: inline-block;
}

#smartbanner {
    width:100%;
    font-family: Roboto, Tahoma, sans-serif;
    background: #3c3c3c;
    z-index:9998;
    -webkit-font-smoothing:antialiased;
    overflow:hidden;
    -webkit-text-size-adjust:none;
    padding-bottom: 3px;
    position: relative;
    color: #d6d6d6;
    border: 0;
}

#smartbanner .sb-container {
    margin: 0 auto;
}
.ios .sb-close {
    color: #999;
}
.ios #download_link .sb-info {
    color: #555;
    padding:8px 0 0 20px;
    width: auto;
}
#smartbanner .sb-info .title {
    font-size: 17px;
}
#smartbanner .sb-info .sub-title, #smartbanner .sb-info .app-info  {
    font-size: 12px;
}
#smartbanner .sb-close:active {
    font-size:13px;
    color:#aaa;
}
.mobile-search ::-webkit-input-placeholder { font-style: italic; }
.mobile-search ::-moz-placeholder { font-style: italic; }
.mobile-search :-ms-input-placeholder { font-style: italic; }
.mobile-search input:-moz-placeholder { font-style: italic; }
#smartbanner .sb-icon {
    width: 46px;
    height:46px;
}
/*.android .sb-icon {
    background-image: url('/images/local_mobile_api/android_app_icon_update.png');
}
.ios .sb-icon {
    background-image: url('/images/local_mobile_api/ios_app_icon_update.png');
}*/
.android .sb-icon, .ios .sb-icon {
    background-image: url('/images/local_mobile_api/app_icon_update.png');
    background-size: 100% auto;
}
#smartbanner.no-icon .sb-icon {
    display:none;
}
#smartbanner .sb-close {
    height: 100%;
    position: absolute;
    text-align: right;
    right: 0;
    background: none;
}
#smartbanner .sb-info {
    width:55%;
    margin-top: 6px;
    vertical-align: top;
    margin-left: 10px;
    margin-bottom: 5px;
}

@media (max-width: 360px) {
    #smartbanner .sb-info {
        width: 45%;
    }
}

#smartbanner .sb-info div {
    overflow: show;
}

/*#smartbanner.ios .sb-info {
    margin-top: 7px;
}*/
#smartbanner .app-icon {
    margin-left: 7px;
    margin-top: 7px;
}
/*#smartbanner.android {
    border-color:#212228;
    background: #3d3d3d url('/images/local_mobile_api/dark_background_stripes.gif');
    border-top: 5px solid #FAA41A;
    box-shadow: none;
}*/

/*#smartbanner.android .sb-close {
    color: #b1b1b3;
    background: #333;
    width: 48px;
}*/

/*#smartbanner.android .sb-close:active {
    color:#eee;
}*/

/*#smartbanner.android .sb-info {
    color:#fff;
    font-size:12px;
    line-height: 18px;
}*/

#smartbanner .sb-button {
    line-height: 1em;
    position: absolute;
    text-align: center;
    text-decoration: none;
    background: #f37022;
    border-radius: 3px;
    color: #ffffff;
    text-transform: capitalize;
    right: 45px;
    top: 14px;
    border: 0;
    line-height: 30px;
    font-size: 15px;
    padding: 0 11px;
}

#smartbanner .close-icon {
    width: 35px;
    line-height: 35px;
    text-align: center;
    vertical-align: middle;
    margin-top: 15px;
}

/*.android .sb-button {
    font-size: 0.75em;
    font-weight: bold;
    border: 1px solid #dddcdc;
    color: #FFF;
    min-width: 12%;
    background-color: #faa41a;
    text-transform: uppercase;
    width: 25%;
    top: 20px;
    padding: 6px 1px;
}

.ios .sb-button {
    top: 15px;
    font-size: 1em;
    padding: 5px 10px;
    border: 1px solid #DDD;
    border-radius: 5px;
    text-transform: uppercase;
}
.ios .sb-close {
    width: 35px;
}*/

label.error{
    color: #d2232a;
}

#content {
    background: #ffffff;
}
.content-bg {
    background: #ffffff;
    padding: 10px 10px 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

/* Homepage */
.content-wrapper {
    padding: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.richRelevance-placeholder {
    display: block;
}
.catWrapper, .richRelevance-placeholder {
    width: 100%;
    margin-bottom: 0;
    /*box-shadow: 0px 1px 2px 1px #B7B7B7;
    -moz-box-shadow: 0px 1px 2px 1px #B7B7B7;
    -webkit-box-shadow: 0px 1px 2px 1px #B7B7B7;*/
    background: #ffffff;
    position: relative;
    /*border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;*/
}

.catWrapper .ratingReviews, .catWrapper .ratingBox {
    display: inline-block;
    line-height: 20px;
    vertical-align: top;
    margin-bottom: 5px;
}

.catTitle, .richRelevance-placeholder-title {
    line-height: 16px;
    font-size: 16px;
    font-weight: 500;
    color: #4f749e;
    padding: 9px 10px 9px 15px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    display: block;
    background: #dce2e9;
    user-select: none;
}
.catSubTitle {
    border: solid #e3e3e3;
    border-width: 1px 0;
    padding: 10px 10px 10px 15px;
    color: #000;
    font-size: 1em;
}
.catTitle--opened, .catTitle--closed {
    margin-top: 10px;
    user-select: none;
}
.catSubTitle--opened, .catSubTitle--closed, .catTitle--opened, .catTitle--closed {
    cursor: pointer;
    position: relative;
}
.catTitle--opened .icon, .catTitle--closed .icon {
    position: absolute;
    top: 9px;
    right: 11px;
}
.catTitle--closed + .description-detail {
    display: none;
}

.description--other-sellers .catTitle--closed + .description-detail {
    display: block;
}

.description--other-sellers .catWrapper--collapse {
    border-top: 1px solid #e3e3e3;
}

.catSubTitle--closed + .description-detail + .description-detail-more-link {
    font-size: 0;
    padding: 0;
    border-top: #fff;
}
.catSubTitle--closed {
    border-bottom-color: #fff;
}
.catSubTitle--opened:after, .catSubTitle--closed:after {
    position: absolute;
    top: 9px;
    right: 15px;
    color: #686868;
    font-size: 1.2em;
}
.catSubTitle--opened:after {
    content: "\2013";
    right: 14px;
}
.catSubTitle--closed:after {
    content: "+";
}

.catList-link {
    color: #757575;
    display: block;
    padding: 10px 20px 10px 15px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background: #ffffff;
    border-bottom: 1px solid #e8e8e8;
    position: relative;
    font-style: 14px;
}

.catList-link strong{
    font-weight: normal;
}
.content-bg .cart{
    margin-top: 10px;
}
.catList.catWrapper li:last-child .catList-link,
.catList.catWrapper .catList-link {
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    font-size: 15px;
}
.catList.catWrapper .catList:last-child li:last-child .catList-link,
.catList.catWrapper .catList:last-child li:last-child .catList-link {
    border-radius: 0px 0px 3px 3px;
    -moz-border-radius: 0px 0px 3px 3px;
    -webkit-border-radius: 0px 0px 3px 3px;
}
/*.catList-link:before {
    content:'';
    display: block;
    width: 22px;
    height: 20px;
    margin-right: 5px;
    vertical-align: middle;
    background: url("/images/local_mobile_api/sprites_02.png") no-repeat;
    background-position: -107px -29px;
    position: absolute;
    top: 12px;
    left: 9px;
}*/

.product-multisource-title {
    font-size: 16px;
    line-height: 16px;
    padding: 10px 10px 10px 15px;
    color: #757575;
}
.product-multisource__desc {
    background: #f8f8f8;
    color: #757575;
    padding: 5px 20px 6px;
    font-size: 0.867em;
    line-height: 1em;
}
.product-multisource__desc__icon {
    vertical-align: middle;
    margin: 0 3px;
}
.product-multisource-title .number {
    color: #4ab3c4;
    font-weight: 500;
}
.product-multisource-title .price {
    color: #ff3d00;
    font-weight: 500;
}
.warranty-text label .price {
    color: #ff3d00;
    font-weight: 500;
}
.multisource-description-detail {
    padding: 10px 17px;
    box-sizing: border-box;
    font-size: 13.5px;
    border-top: 1px solid #e3e3e3;
}
.multisource-description-detail.first {
    border-top: none;
}
.multisource-description-detail.show {
    display: block;
}
.multisource-description-detail.hide {
    display: none;
}
.multisource-description-detail.last {
    border-bottom: none;
}
.multisource-seller {
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
        border-bottom: 1px solid #f6f6f6;
        padding-bottom: 10px;
}
.no-rating .seller-text{
    width: 70%;
    display: inline-block;
}
.rating .seller-text {
    width: 50%;
    display: inline-block;
}
.rating .seller-rating {
    vertical-align: top;
    margin-top: 2px;
    text-align: left;
    float: right;
    white-space: nowrap;
}
.seller-text {
    color: #000;
}
.seller-text a {
    color: #000;
    font-weight: bold;
}
.seller-text .seller {
    margin: 7px 0;
    font-weight: bold;
}
.seller-text .seller-name {
    font-weight: bold;
    text-transform: uppercase;
}
.multisource-delivery-info {
    color: #757575;
    border-bottom: 1px solid #f6f6f6;
    padding: 10px 0px;
}
p.multisource-delivery-info__text {
    margin: 0px 0px 5px 0px;
}
.multisource-delivery-info__text__title {
    color: #333333;
    display: block;
}
.multisource-delivery-info__text__time {
    color: #757575;
    display: block;
}
.multisource-delivery-info__text__icon {
    vertical-align: middle;
}
.multisource-warranty {
    color: #757575;
    border-bottom: 1px solid #f6f6f6;
    padding: 10px 0px;
}
p.multisource-warranty__text,
.multisource-warranty__text{
    margin: 0px 0px 5px 0px;
}
p.multisource-warranty__text_type_satisfaction {
    color: #ca9c2e;
}
.multisource-warranty__text__icon {
    vertical-align: middle;
    margin-right: 4px;
}
.multisource-warranty__text__icon_type_satisfation {
    background: url(/images/local_mobile_api/logo-satisfaction-guaranteed-small.svg) top left no-repeat;
    width: 12px;
    height: 13px;
}
.warranty-option {
    margin-bottom: 10px;
}
.warranty-option .warranty-text {
    display: inline-block;
    width: 80%;
    margin: 0 5px;
    vertical-align: top;
}
.warranty-text p {
    margin: 1px 0 0 6px;
}
.warranty-text p .price {
    color: #ff3d00;
}
.multisource-buyit {
    padding: 10px 0 0;
}
.multisource-buyit:after {
        content: '';
        display: block;
        clear: both;
}
.multisource-cart-form {
        display: inline-block;
        float: right;
}
.multisource-buyit .buynow {
    width: 130px;
    text-align: left;
    display: inline-block;
    cursor: pointer;
}
.multisource-buyit .buynow button {
    position: static;
    margin: 0;
}
.multisource-buyit .price {
    color: #ff3d00;
    font-size: 16px;
    display: inline-block;
    text-align: right;
    box-sizing: border-box;
    padding-right: 20px;
    float: left;
}
.multisource-view-all {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    border-top: solid #ebebeb 1px;
    border-bottom: solid #ebebeb 1px;;
}
.multisource-view-all.show {
    display: block;
}
.multisource-view-all.hide{
    display: none;
}
.multisource-view-all span {
    color: #4ab3c4;
    cursor: pointer;
    display: inline;
}
.warranty-option input[type=radio ]:not(old) {
    width: 1em;
    margin: 0;
    padding: 0;
    font-size: 1em;
    opacity: 0;
}
.warranty-option input[type=radio ]:not(old) + .warranty-text > label {
    display: inline-block;
    margin-left: -2em;
    line-height: 1.5em;
}
.warranty-option input[type=radio]:not(old) + .warranty-text > label > span {
    display          : inline-block;
    width            : 16px;
    height           : 16px;
    margin           : 2px 10px 3px 5px;
    border           : 1px solid rgb(181, 181, 181);
    border-radius    : 50%;
    background       : rgba(222, 218, 224, 0.34);
    background-image :    -moz-radial-gradient(rgb(255, 255, 255), rgb(255, 255, 255));
    background-image :     -ms-radial-gradient(rgb(255, 255, 255), rgb(255, 255, 255));
    background-image :      -o-radial-gradient(rgb(255, 255, 255), rgb(255, 255, 255));
    background-image : -webkit-radial-gradient(rgb(255, 255, 255), rgb(255, 255, 255));
    background-image: radial-gradient(16px at 60px 50% , #ffffff 0%, #ffffff 14px, rgba(0, 0, 0, 0.3) 18px, rgba(0, 0, 0, 0) 19px);
    vertical-align   : bottom;
}
.warranty-option input[type=radio]:not(old):checked + .warranty-text > label > span {
    background-image :    -moz-radial-gradient(rgb(255, 255, 255), rgb(255, 255, 255));
    background-image :     -ms-radial-gradient(rgb(255, 255, 255), rgb(255, 255, 255));
    background-image :      -o-radial-gradient(rgb(255, 255, 255), rgb(255, 255, 255));
    background-image : -webkit-radial-gradient(rgb(255, 255, 255), rgb(255, 255, 255));
    background-image: radial-gradient(16px at 60px 50% , #ffffff 0%, #ffffff 14px, rgba(0, 0, 0, 0.3) 18px, rgba(0, 0, 0, 0) 19px);
}
.warranty-option input[type=radio]:not(old):checked + .warranty-text > label > span > span {
    display: block;
    width: 10px;
    height: 10px;
    margin: 3px;
    border: 0.0625em solid rgb(243, 111, 33);
    border-radius: 50%;
    background: rgb(243, 111, 33);
}
.multisource-buyit .buynow button.disabled {
    background: #acacac;
}

.link-mobiles-tablets:before {
    background-position: -107px -29px;
    width: 13px;
    height: 21px;
    left: 14px;
}
.link-computers-laptops:before {
    background-position: -120px -29px;
}
.link-consumer-electronics:before {
    background-position: -143px -29px;
}
.link-home-appliances:before {
    background-position: -189px -29px;
}
.link-cameras:before {
    background-position: -212px -28px;
}
.link-fashion:before {
    background-position: -166px -29px;
}
.link-health-beauty:before {
    background-position: -279px -29px;
    width: 20px;
}
.link-toys-babies:before {
    background-position: -234px -30px;
    width: 20px;
}
.link-books-games-musics:before {
    background-position: -257px -29px;
}

/* Footer */
footer {
    color: #333333;
    text-align: center;
    background: #fbfbfb;
    /*box-shadow: 0px -2px 20px #B7B7B7;
    -moz-box-shadow: 0px -2px 20px #B7B7B7;
    -webkit-box-shadow: 0px -2px 20px #B7B7B7;*/
    /*border-top: 1px solid #e8e8e8;*/
    margin-top: -1px;
    width: 100%;
    position: relative;
    z-index: 100;
    clear: both;
}

/*body.main footer {
    position: absolute;
    bottom: 0;
}*/

footer.simple-footer {
    padding: 10px 0;
}

footer a {
    color: #44b1c3;
    /*border-bottom: 1px solid #333333;*/
    text-decoration: none;
}
footer .term,
footer .separator {
    color: #44b1c3;
}

footer a:hover,
footer a:active{
    /*color: #999999;
    border-bottom: 1px solid #999999;*/
    text-decoration: underline;
}

footer .tt-desc a{
    color: #1b73b3;
    border-bottom: 0;
    text-decoration: underline;
}

footer .footer-text {
    font-size: .9em;
    padding-bottom: 5px;
}

footer address {
    font-style: normal;
    margin: 5px;
    color: #707070;
    display: block;
    text-align: center;
}
footer.simple-footer address{
    padding: 0 20px;
}

footer .change-device{
    font-weight: 500;
    margin: 5px auto 0;
    padding-bottom: 10px;
    color: #999999;
    display: block;
    box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    text-decoration: underline;
    border-bottom: 0;
}
footer .change-device:hover,
footer .change-device:active{
    color: #333333;
}
.download-app {
    color: #004689;
    font-weight: bold;
    border-bottom: 0;
}
.download-app:hover,
.download-app:active {
    color: #004689;
}
.footer-usp {
    border-bottom: 1px solid #e8e8e8;
    min-height: 50px;
    width: 100%;
    padding: 0;
    display: table;
    margin-bottom: 4px;
    background: #fcfbf1;
}
.l-detailscreen .footer-usp, .mobile-catalog-products .footer-usp {
    margin-bottom: 10px;
}
.l-detailscreen footer, body.cart footer /*, .catalog .main-container footer */{
    padding-bottom: 61px;
}
.mobile-catalog-products footer,
footer.filter-footer {
    padding-bottom: 50px;
}
.mobile-catalog-products.no-products footer {
    padding-bottom: 0;
}
.mobile-catalog-products.no-products .footer-usp {
    margin-bottom: 0;
}
.mobile-catalog-products.no-products footer {
    padding-bottom: 0;
}
.mobile-catalog-products .p-404 {
    padding: 5px 5px 0 10px;
}
footer .footer-usp a {
    width: 33%;
    border-right: 1px solid #e8e8e8;
    display: table-cell;
    min-height: 40px;
    text-align: center;
    padding: 6px 4px;
    vertical-align: top;
    position: relative;
    /*top: -1px;*/
    border-top: 1px solid #e8e8e8;
}
footer .footer-usp a.current {
    z-index: 101;
    background: #f2f2f0;
    border-top-color: #f2f2f0;
}
footer .footer-usp a:last-child {
    border-right: none;
}
footer .footer-usp a:hover,
footer .footer-usp a:active {
    text-decoration: none;
}
footer .footer-usp a:focus {
    outline: none;
}
footer .tip {
    /*position: absolute;*/
    /*z-index: 10;*/
    text-align: left;
    width: 100%;
    background: #f2f2f0;
    border-top: 1px solid #e8e8e8;
    padding: 10px;
    /*border-bottom: 1px solid #CCC;*/
    /*box-shadow: 0px -2px 20px #B7B7B7;*/
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
footer .tip .tt-title {
    color: #0b0b0b;
    margin-bottom: 7px;
    font-weight: 500;
}
footer .tip .tt-title b { font-weight: 500 }
footer .tip .tt-desc {
    color: #757575;
    font-size: .9em;
    line-height: 1.3;
    /*margin-bottom: 10px;*/
}
.usp-icon {
    display: block;
    margin: 3px auto 0;
    width: 25px;
    height: 25px;
    z-index: 99;
    position: relative;
    margin-bottom: 5px;
}
/*.usp-cash .usp-icon {
    background-position: -70px 0;
}
.usp-delivery .usp-icon {
    background-position: -95px 0;
}
.usp-return .usp-icon {
    background-position: -120px 0;
    width: 24px;
}*/

.usp-text {
    color: #757575;
    line-height: 1em;
    margin: 2px auto 5px;
    display: block;
    z-index: 99;
    position: relative;
    font-weight: 700;
    font-size: .85em;
    font-weight: normal;
}

/* Product list */
.catProduct-list, .pl {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    overflow: hidden;
}
.prdListWrapper, .richRelevance-placeholder-content {
    width: 100%;
    height: 200px;
    overflow: hidden;
}
.pl .richRelevance-placeholder-content {
    height: 230px;
    position: relative;
}
.pl .richRelevance-placeholder-inner {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.prdWrapper {
    width: 200px;
    min-height: 144px;
    padding: 8px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    display: inline-block;
}
.prdWrapper:last-child {
    border-right: 0;
}
.itm {

}
.itm-link {
    display: block;
}
.prdImg {
    width: 100%;
    height: 100px;
    text-align: center;
}
.productImage {
    display: block;
    height: 120px;
    text-align: center;
}
.prdImg img, .productImage img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}
.prdInfo span {
    line-height: 1.1em;
    text-align: center;
    display: block;
    margin: auto;
}
.itm-productInfo {
    display: block;
    margin: auto;
    text-align: left;
    line-height: 1.3em;
    padding: 5px 8px;
}
.prdInfo .prdTitle {
    display: block;
    color: #000;
    margin-bottom: 5px;
    max-height: 78px;
    font-style: normal;
    overflow: hidden;
    font-size: .9em;
}
.itm-productInfo .itm-title {
    color: #000000;
    text-align: left;
    display: inline-block;
    margin-bottom: 5px;
    max-width: 100%;
    max-height: 59px;
    overflow: hidden;
    font-style: normal;
}
.swiper-slide .prdInfo .prdTitle {
    height: 34px;
    overflow: hidden;
}

.prdInfo .prdPrice-old {
    color: #999999;
    text-decoration: line-through;
    height: 16px;
}
.itm-priceBox, .prdInfo .itm-priceBox {
    display: block;
    margin-top: 5px;
    text-align: left;
}
.prdInfo .itm-priceBox span {
    text-align: left;
}
.prdInfo .prdPrice-new,
.prdInfo .prdPrice, .itm-price {
    color: #ff3d00;
    font-weight: 400;
}
.prdInfo .prdDiscount {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    color: #ffffff;
    font-weight: 500;
}
.prdInfo .prdDiscount .value,
.prdInfo .prdDiscount .empty {
    padding: 5px;
    width: 46px;
    margin: 0 auto 10px;
}
.prdInfo .prdDiscount .value {
    background: #c11800;
    border-radius: 30px;
    -webkit-border-radius: 30px;
}
img.full-layout-banner {
    width: 100%;
    vertical-align: top;
}

.password-hint {
    color: #484848;
    display: block;
    font-size: 0.9em;
    font-style: italic;
    margin-top: 3px;
    padding-left: 10px;
}
.term-condition {
    display: block;
    font-size: 0.9em;
    font-style: italic;
    line-height: 1.5;
}
.term-condition a {
    font-weight: bold;
    cursor: pointer;
    color: #faa41a;
    font-style: normal;
}
.term-condition a:hover {
    text-decoration: none;
    color: #faa41a;
    font-style: normal;
}
.text-return {
    background-color: #fff;
    display: none;
    height: 320px;
    width: 320px;
    margin: auto;
    top: 50% !important;
    left: 50% !important;
    margin-top: -160px;
    margin-left: -160px;
    padding: 5px 0 10px;
    position: fixed;
    border: solid 1px #FFF;
    border-radius: 7px;
}
.text-return .return-title span{
    display: inline-block;
    margin-left: 20px;
}
.text-return .return-title {
    border-bottom: 1px solid #faa41a;
    display: block;
    font-size: 18px;
    margin: 0;
    padding: 0 0 5px;
    text-align: left;
}
.text-return .button-close {
    color: #000;
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    right: 10px;
    text-decoration: none;
    top: 2px;
    width: 20px;
}
.text-return .button-close img {
    width: 100%;
}
.return-wrapper {
    max-width: 100%;
    height: 280px;
    padding: 0 10px;
    overflow-y: auto;
    background-color: #fff;
}
.pp .return-wrapper {
    background-color: #fff;
    overflow-y: auto;
}
.return-wrapper .content_container h2:after {
    clear: right;
    content: " ";
    display: block;
}
.return-wrapper .content_container  h2, .return-wrapper .content_container  div[align="justify"] h3 {
    border-bottom: 1px solid #f0f0f0;
    clear: both;
    color: #faa41a;
    font-size: 1.125em;
    font-weight: normal;
    margin-top: 15px;
    padding-bottom: 10px;
}
.return-wrapper .content_container ul {
    font-size: 0.875em;
    list-style-type: disc;
    margin-left: 20px;
}
.return-wrapper .content_container .left_col {
    display: none;
}
.breadcrumbs {
    background: none repeat scroll 0 0 #f4f3f3;
    font-size: 15px;
    height: auto;
    padding-left: 15px;
    position: relative;
    overflow-x: auto;
}
.breadcrumbs.has-croll {
    overflow-x: auto;
    overflow-y: hidden;
}
.breadcrumbs ul {
    white-space: nowrap;
}
.breadcrumbs a {
    display: inline-block;
    height: 33px;
    position: relative;
    color: #54b7c7;
}
.breadcrumbs a:after {
    /*border: 1px solid #9e9e9e;
    border-width: 1px 1px 0 0;
    content: "";
    height: 37px;
    position: absolute;
    right: -20px;
    top: -1px;
    transform: scale(0.707) rotate(45deg);
    width: 37px;
    z-index: 1;*/
}
.breadcrumbs li.prs {
    display: inline-block;
    white-space: nowrap;
    vertical-align: top;
    margin-left: 7px;
    line-height: 34px;
    font-size: 14px;
}
.breadcrumbs li.prs:first-child {
    margin-left: 0;
}
.breadcrumbs li.prs:last-child {
    margin-right: 13px;
}
.breadcrumbs li.prs span + span {
    margin-left: 7px;
}
.breadcrumbs .icon {
    vertical-align: top;
}
.breadcrumbs .cta-arrow {
    position: static;
    right: 0;
    height : 16px;
    vertical-align: middle;
}

.review-wrapper {
    margin-bottom: 15px;
}

.l-catalogscreen .main-container {
    background: #fff;
}
.form-mobile .variation {
}
.pgn-spellchecker, .search-results-info {
    margin: 0;
    padding: 7px 15px;
    background: #f4f3f3;
    z-index: 10;
    color: #595959;
    font-size: 14px;
    line-height: 1.4;
}
.pgn-spellchecker span, .search-results-info span,
.pgn-spellchecker a, .search-results-info a {
    color: #54b7c7
}
.pgn-spellchecker em, .search-results-info em {
    color: #f37021;
    font-style: normal;
}
.pgn-spellchecker .search-phrases {
    margin-top: 7px;
}
/*.pgn-spellchecker div {*/
    /*margin: 5px 0;*/
/*}*/

/*@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 150dpi) {
    .android .sb-icon {
        background-image: url('/images/local_mobile_api/android_app_icon@2x_update.png');
        background-size: 50px 50px;
    }
    .ios .sb-icon {
        background-image: url('/images/local_mobile_api/ios_app_icon@2x_update.png');
        background-size: 50px 50px;
    }
}*/

body.cart footer, body.static-design footer {
    margin-top: 15px;
}
/*Supplier Box Modal*/
.supplier-modalbox {

}
.supplier-modalbox h2 {
    font-size: 1em;
    font-weight: normal;
    text-transform: capitalize;
    border-bottom: 1px solid #ffb319;
    padding-bottom: 2px;
    margin-bottom: 2px;
}
.supplier-modalbox h3 {
    font-size: 1em;
    text-transform: capitalize;
    color: #004689;
}

.supplier-modalbox .brt {
    margin-top: 5px;
}

.supplier-modalbox .brt h2 {
    padding-top: 5px;
}

/*Help page's menu*/
.menu-list .faq-icon a,
.menu-list .contactus-icon a,
.menu-list .shipping-return-icon a,
.menu-list .tos-icon a
{
    background-image: none;
}

/*.menu-list .faq-icon a:before {
    background-image: url(/images/local_mobile_api/sprites2014.png);
    content: " ";
    display: block;
    width: 18px;
    height: 17px;
    position: absolute;
    background-position: -189px 0px;
    left: 10px;
    background-color: transparent;
}

.menu-list .contact-us-icon a:before {
    background-image: url(/images/local_mobile_api/sprites2014.png);
    content: " ";
    display: block;
    width: 18px;
    height: 17px;
    position: absolute;
    background-position: -206px 3px;
    left: 10px;
    background-color: transparent;
}

.menu-list .shipping-return-icon a:before {
    background-image: url(/images/local_mobile_api/sprites2014.png);
    content: " ";
    display: block;
    width: 19px;
    height: 17px;
    position: absolute;
    background-position: -225px 2px;
    left: 11px;
    background-color: transparent;
}

.menu-list .tos-icon a:before {
    background-image: url(/images/local_mobile_api/sprites2014.png);
    content: " ";
    display: block;
    width: 14px;
    height: 17px;
    position: absolute;
    background-position: -244px 0px;
    left: 12px;
    background-color: transparent;
}*/


.social-login {
    margin: 30px 0 15px;
    text-align: center;
    overflow: hidden;
}

    .social-login__grid {
        display: block;
        margin-bottom: 16px;
    }

    @media (min-width: 460px) {

        .social-login__grid {
            width: 49%;
            float: left;
        }

        .social-login__grid + .social-login__grid {
            float: right;
        }

    }

        .social-login-button {
            cursor: pointer;
            display: inline-block;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;

            width: 100%;
            height: auto;
            padding: 0 6px 0 0;

            border-style: solid;
            border-width: 1px;

            font-size: 16px;
            line-height: 48px;
            font-weight: 500;
            text-align: center;

            white-space: nowrap;
        }

            .social-login-button__icon {
                float: left;
                padding: 14px 15px 14px 14px;
                line-height: 0;
            }

        .social-login-button_facebook {
            color: #3b5998;
            border-color: #3b5998;
        }

            .social-login-button__icon_facebook {
                background-color: #3b5998;
            }

        .social-login-button_google {
            color: #dd4b39;
            border-color: #dd4b39;
        }

            .social-login-button__icon_google {
                background-color: #dd4b39;
            }


.content-bg .or-divider {
    display: inline-block;
    text-align: center;
    width: 100%;
    margin: 15px 0 !important;
}
.content-bg_order-tracking h2 {
    color: #000;
    font-size: 13px;
    text-align: center;
}
.or-divider .inner {
    margin: 0;
    position: relative;
}
.or-divider .inner::before {
    border-bottom: 1px solid #e6e6e6;
    content: "";
    left: -10px;
    position: absolute;
    right: -10px;
    top: 50%;
}
.or-divider .loginReg__or {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #e6e6e6;
    border-radius: 100%;
    color: #ababab;
    display: inline-block;
    font-size: 9px;
    margin: 0;
    padding: 7px;
    position: relative;
    text-transform: uppercase;
}
.mobile-step .form-mobile .social-login div {
    margin-top: 0;
}
.mobile-step .form-mobile .or-divider div {
    margin-top: 0;
}
.forgot-password-wrap {
    text-align: right;
    padding-top: 10px;
    padding-bottom: 25px;
}
.ui-text-left {
    text-align: left;
}

.seller-list .seller-option > div.warranty-extension, .seller-list .seller-option > div.seller-info-wrapper{
    /*padding: 0 10px;*/
    border-bottom: none;
}
.seller-list .seller-option > div.seller-info-wrapper {
    padding-bottom: 15px;
}
.seller-list .catalog-form-cart {
    float: left;
}
.warranty-extension{
    margin-top: 5px;
}

.warranty-extension li{
    margin-bottom: 10px;
}

.warranty-extension label{
    display: inline-block;
    width: 100%;
}
.warranty-extension.my-account input[type=radio] + label:before{
    display: inline-block;
    float: left;
    margin-right: 5px;
}
.description-detail span.warranty-label, span.warranty-label{
    display: inline-block;
    width: 90%;
    margin-top: 0;
}
/*order tracking*/
.cms-staticpage {padding: 0;}
.container h1 { font-size: 24px; color: #DE7B2A; margin: 0 0 20px 0; }
.formcode2 .formtitle {background:#1575bf; color:#fff; font-size:16px; padding:15px 5px 0 15px; float:left; height:40px; display: block; width:155px;}
.formcode2 .forminput {padding:10px 0 0 20px; height: 90px;}
.formcode2 .input {border:#CED3DC 1px solid; width:250px; padding:3px 5px; height: 35px; margin: 1px 0px 0; float:left; outline: none; }
.formcode2 .verify-field { float: left; width: 390px; margin: 0 5px 0 0; }
.formcode2 .capchar { float: left; width: 125px; margin: 0 5px 0 0; }
.formcode2 img { float: left; margin-right:0; border: none; }
.formcode2 .button{ width: 130px; margin-top: -20px; height: 35px; padding: 0 0 2px 0; border: none;color:#fff; font-size:14px; font-weight: bold; background-color: #010101;
                    background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#44474c), to(#010101));
                    background: -webkit-linear-gradient(top, #44474c, #010101);
                    background: -moz-linear-gradient(top, #44474c, #010101);
                    background: -ms-linear-gradient(top, #44474c, #010101);
                    background: -o-linear-gradient(top, #44474c, #010101);
                    border-radius: 3px; -webkit-border-radius: 3px;
                    cursor:pointer; float: right; margin-right: 11px;
}

.formcode2 .button:hover { background-color: #010101;
                           background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#44474c), to(#010101));
                           background: -webkit-linear-gradient(top, #44474c, #010101);
                           background: -moz-linear-gradient(top, #44474c, #010101);
                           background: -ms-linear-gradient(top, #44474c, #010101);
                           background: -o-linear-gradient(top, #44474c, #010101);
}


.tb-status {background:#ced3dc; border-spacing:1px !important; border-collapse:inherit; font-size:14px; margin-top:10px}
.tb-status th {background:#1575bf; color:#fff; font-weight:bold; text-align: left;}
.tb-status td {background:#faf9f9; text-align: left; }
.tb-status tr:nth-child(odd) td {background: #eae9e9}
.tb-status td.checked img { float: right; margin: 0 10px 0 15px; }
.mgBox{ overflow:hidden; margin-top:0px; padding: 0 !important; }
.mgBox span{ color:#FF0000; font-style:italic; display:block; line-height: 1.2; }
.mgBox .first{ width:100%;}
.mgBox .hide{ display:none;}
.hide{ display:none !important;}
.mgBox strong{font-weight:normal;}
.formcode2 .formtitle a {  padding: 0 18px 0 0; font-size: 11px; color:#fff;
                           text-decoration:none;  }
.formcode2 .formtitle a:hover { text-decoration: underline; }
.statusorder {font-size:14px; font-weight:bold; margin-bottom:0px;}
.parap { font-family: Helvetica,Arial,sans-serif; color: #404040; text-align: justify; line-height: 18px; padding-right: 10px;}
.formcode2 .disabled { cursor:default; opacity:0.5;filter:alpha(opacity=50);}
.box-info {margin-top: 20px; }
.box-info p { padding: 0px 5px 0px 5px; margin: 0 0 10px; line-height: 18px; text-align: justify; }
.tb-status table { margin: 10px 0 0 0; font-size: 12px; }
.tb-status table th { background: #ccc; border-color: #000; color:#000; padding: 5px; }
.tb-status table td{ padding: 5px;}

/*Order tracking*/
#order-input {display: block !important; margin: 0 0 30px; }
#div_loading {
    background-color:#fff;
    opacity:0.9;
    position: relative;
    display:none;
    z-index:100;
}

.thumb-small-container.swiper-container {
    margin:0 auto;
    position:relative;
    overflow:hidden;
    -webkit-backface-visibility:hidden;
    -moz-backface-visibility:hidden;
    -ms-backface-visibility:hidden;
    -o-backface-visibility:hidden;
    backface-visibility:hidden;
    z-index:1;
}
.thumb-small-container .swiper-wrapper {
    position:relative;width:100%;
    -webkit-transition-property:-webkit-transform,left,top;
    -webkit-transition-duration:0s;
    -webkit-transform:translate3d(0px,0,0);
    -webkit-transition-timing-function:ease;
    -moz-transition-property:-moz-transform,left,top;
    -moz-transition-duration:0s;
    -moz-transform:translate3d(0px,0,0);
    -moz-transition-timing-function:ease;
    -o-transition-property:-o-transform,left,top;
    -o-transition-duration:0s;
    -o-transform:translate3d(0px,0,0);
    -o-transition-timing-function:ease;
    -o-transform:translate(0px,0px);
    -ms-transition-property:-ms-transform,left,top;
    -ms-transition-duration:0s;
    -ms-transform:translate3d(0px,0,0);
    -ms-transition-timing-function:ease;
    transition-property:transform,left,top;
    transition-duration:0s;
    transform:translate3d(0px,0,0);
    transition-timing-function:ease;
    margin: 0 auto;
}
.thumb-small-container .swiper-free-mode>.swiper-wrapper {
    -webkit-transition-timing-function:ease-out;
    -moz-transition-timing-function:ease-out;
    -ms-transition-timing-function:ease-out;
    -o-transition-timing-function:ease-out;
    transition-timing-function:ease-out;
    margin:0 auto;
}
.thumb-small-container .swiper-slide {
    /*float:left;
    width: auto;
    margin: 0 -1px;
    min-height: 40px;*/
}
.thumb-small-container .swiper-slide.current {
    /*border: 1px solid #ffb202;
    margin: 0;*/
}
.thumb-small-container .swiper-slide img {
    max-width: 50px;
    max-height: 50px;
    vertical-align: middle;
}
.thumb-small-container .swiper-wp8-horizontal {-ms-touch-action:pan-y;}
.thumb-small-container .swiper-wp8-vertical {-ms-touch-action:pan-x;}
.thumb-small-container.swiper-container {
    width: 98%;
    height: 55px;
    color: #fff;
    text-align: center;
    margin: 10px 1%;
}
.ajax-loader {
    position: absolute;
    left: 45%;
    top: 30%;
    margin-left: -32px; /* -1 * image width / 2 */
    margin-top: -32px;  /* -1 * image height / 2 */
    display: block;
}

table { border-spacing:0; }
.order_header{display:block; margin-bottom:15px;}
.order-tracking h2 { text-transform: uppercase; color: #004084;font-family: Arial;font-size: 32px;font-style: normal;font-weight: bold;text-decoration: none;}
.order_tracking table .block {
  display: table-row;
}
.order_header h3 {font-size:18px;font-weight:normal;font-style:normal;text-decoration:none;color:#000;}
.order_header label {font-weight: bold; display:inline; width:170px; float:left;font-size:13px; line-height:20px;}
.order_header span {display:block;font-size:13px;line-height:20px;}
.order_process {display:block;}

.order_content table { border :1px solid #c0c0c0; width:100%; margin:0; padding:0; font-size: 13px; border-collapse: collapse;border-spacing: 0;  }
.order_content .col-1 {width: 40%; }
.order_content .col-2 {width: 10%; }
.order_content .col-3 {width: 50%; }
.order_content thead td {background-color:#d9d9d9; padding: 10px; border: 1px solid #c0c0c0; font-weight: bold; }
.order_content td { padding: 7px 10px;  border: 1px solid #c0c0c0;}
.order_content td td { border: none; border-bottom: 1px dotted #c0c0c0; }
.cms-staticpage .order_content ul, .order_content ul {padding:0; margin: 0 !important; list-style:none;  }

table.table_amount  {border: 0px; }
table.table_amount tr {text-align:center;}
table.table_content  {border : 0px;}
table.table_content tbody tr { padding:10px; width:300px;}

.status_time {display:block; color:#000 !important; font-weight: bold; }
.status_text {display:block; }
#order-tracking-button button {float:right; margin:20px;}
.order_content ul li:last-child {list-style-type: none;}
.cms-staticpage .order_content ul li { margin: 0 -10px !important; padding: 10px !important; line-height: 1.4; }
.cms-staticpage .order_content ul li:nth-child(even) { background: #e9e9e9; }
.cms-staticpage .order_content ul li:last-child { background: none}

#order-tracking-button .button {width :  auto !important; padding : 11px !important; height: auto !important; float:right; margin:  0 0 0 10px;
                                color:#fff; font-size:14px; font-weight: bold; background-color: #F9821E;
                                background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#FCA03C), to(#F9821E));
                                background: -webkit-linear-gradient(top, #FCA03C, #F9821E);
                                background: -moz-linear-gradient(top, #FCA03C, #F9821E);
                                background: -ms-linear-gradient(top, #FCA03C, #F9821E);
                                background: -o-linear-gradient(top, #FCA03C, #F9821E);
                                border-radius: 3px; -webkit-border-radius: 3px;
                                cursor:pointer; float: right;

}
#order-tracking-button .button:hover {background-color: #010101;
                                      background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#44474c), to(#010101));
                                      background: -webkit-linear-gradient(top, #44474c, #010101);
                                      background: -moz-linear-gradient(top, #44474c, #010101);
                                      background: -ms-linear-gradient(top, #44474c, #010101);
                                      background: -o-linear-gradient(top, #44474c, #010101);}
.order_content_box { margin-bottom: 15px; }

.order_process {display:block; margin : 20px 0px;}
.order_process_text {margin-bottom: 10px;}
.order_process_text ul {}
.order_process_text ul li { float:left; list-style-type: none; display: inline;  text-align:left;}

.order_process_text ul li.width_25 {width:22%;}
.order_process_text ul li.width_20 {width:17%;}
.order_process_text ul li.width_50 {width:45%;}

.order_process_text ul li span {font-size:13px; }
td.amount {text-align : center;}

.order_process_bar { width:100%; background-color :#eee; border:0px solid #ccc; }
.order_process_bar .bar { background : url('http://opsfe.lazada.com/assets/loading_process_bar.png') repeat-x; height: 20px;}

.verify-code-label {padding:0 0 8px; font-weight:bold; font-size: 12px; display: block; }

.order-input { background : url(http://static.lazada.vn/cms/campaign/images/bg-1.jpg) 0 100% no-repeat; height: 364px; }
.order-input-inner {padding: 30px 30px 0 250px;}

#custom-landing-page .fashion-landing{
    margin: 0 auto;
}
.supplier-info.brt{
    display: none;
}
/*New Search Suggestion*/
.search-suggestion .margin-left {
    margin-left: 10px;
}
.search-suggestion .ssg-item a {
    padding: 10px;
}
.search-suggestion .ssg-item a span {
    text-align: right;
    float: right;
    color: #6e686e;
}
.search-suggestion .ssg-item strong {
    color: #f36f21;
    font-weight: normal;
}
.search-suggestion .ssg-item .margin-left {
    border-bottom: 0;
}
.mobile .form-mobile .search-suggestion .ssg-item div.text {
    padding: 0 0 0 10px;
    display: inline-block;
    color: #000;
    font-size: 14px;
    background: none;
}
.search-suggestion .ssg-item.header .icon-arrow-right-small, .search-suggestion .category-list-more.icon.icon-arrow-right-small {
    float: right;
    margin: 13px 11px 0 0;
    display: inline-block;
}
.search-suggestion #ac-0 .icon.icon-arrow-right-small {
    position: absolute;
    right: 11px;
    top: 13px;
}
#ac-0 { border-top: none }
#ac-0:hover { background: transparent; cursor: default }
#ac-0 a {
    font-size: 14px;
    font-weight: 500;
    cursor: default;
}
#ac-0 a span { display: none }
.search-suggestion .ssg-item.header div.text {
    margin: 0.5em 0 0 10px;
}
.search-suggestion .ssg-item .ssl {
    float: none;
    display: inline-block;
    width: 90%;
    color: #333333;
    text-align: left;
}
.search-suggestion .ssg-item.header {
    border-bottom: 2px solid #f6b58c;
    padding: 5px 0;
    font-weight: 500;
}
.search-suggestion .ssg-item.header + .ssg-item {
    border-top: none;
}
.search-suggestion .ssg-item:hover,
.search-suggestion .ssg-item:active {
    background: rgba(0,0,0,0.1);
}
.search-suggestion .ssg-item.header:hover,
.search-suggestion .ssg-item.header:active {
    background: none;
}
.search-suggestion .ssg-item:last-child a {
    border-bottom: 0;
    padding-bottom: 10px;
}
.position-fixed{
    position: fixed;
}
.form-mobile .delivery-notification-box {
    margin: -10px -10px 0 -10px;
    padding: 5px 10px;
}
.delivery-notification-box .list-item .item {
    margin-bottom: 0;
}
.delivery-notification-box .list-item .lbl-item {
    padding-right: 5px;
    display: inline-block;
    vertical-align: top;
}
.delivery-notification-box .list-item .name-item {
    color: #d2232a;
    display: inline-block;
    max-width: 240px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.box-color {
    background: #fcedc2;
}

.mobile_badge {
    color: #008000;
    font-weight: normal;
}

.rate_username {
    font-weight: bold;
}

.icon-cod {
    background-image: url(/images/local_mobile_api/icon-cod.png);
}
.icon-cod-tick {
    background-position: 0 0;
    width: 14px;
    height: 12px;
}
.icon-cod-info{
    background-position: 0 -15px ;
    width: 14px;
    height: 14px;
}
.invalid-cod{
    color: #999999;
    font-size: 0.9em;
    position: relative;
    top: -2px;
    line-height: 20px;
}
.description-detail-cod .description-detail span{
    display: inline-block;
    vertical-align: top;
}
.description-detail-cod .description-detail li {
     margin-bottom: 3px;
}
.description-detail-cod .description-detail span.promotion-text{
    width: 90%;
}

.description-detail-cod .description-detail .icon-check {
    margin-top: 8px;
    margin-right: 4px;
}

.description-detail-cod .shipment-type-info {
    padding: 0 10px 0 15px;
    font-size: 1em;
    color: #000;
    overflow: hidden;
    clear: both;
}
.description-detail-cod .shipment-type-info .strong {
    font-weight: 500;
    color: #000;
}

.product-info{
    display: table;
    width: 100%;
    margin-top: 15px;
}
.product-info .product-arrow-left,
.product-info .product-arrow-right,
.product-info .product-info-head{
    display: table-cell;
    vertical-align: middle;
}
/*.product-info .product-arrow-left .icon,
.product-info .product-arrow-right .icon{
    margin-top: 8px;
}*/
.product-info .product-info-head {
    padding-left: 2px;
}
.product-info .product-info-head .write-review-button {
    margin: 3px 0;
    font-size: .9em;
}
.product-info .product-arrow-left{
    width: 13px;
    /*padding: 0 15px;*/
}

.product-info .product-arrow-right{
    width: 13px;
    /*padding: 0 15px;*/
    text-align: right;
}
.product-info .product-info-head .product-info-name{
    color: #2a4766;
    font-size: 18px;
 }
.catWrapper.product-info .ratingReviews, .catWrapper.product-info .ratingBox{
     line-height: 1;
 }
.catWrapper.product-info .ratingWrapper{
     color: #009db4;
     font-size: 12px;
     margin:8px 0 0 0;
 }
.product-info .ratingReviews{
     color: #009db4;
     font-size: 13px;
     margin:0 0 0 5px;
 }
 .description {
    margin: 5px 0 0 0;
}
.thumb-small-container .swiper-slide-wrap{
     width: 52px;
     height: 52px;
     line-height: 50px;
     border: 1px solid #cacbca;
     margin: 0 5px;
     opacity: 0.6;
 }
.thumb-small-container .swiper-slide.current .swiper-slide-wrap{
     border: 1px solid #f4772e;
     opacity: 1;
}

.product-size .variation {
    padding: 10px 20px 0;
}

.product-size .size-title, .product-color .color-title{
    color: #000000;
    font-size: 14px;
    margin: 0 0 2px 0;
}
.product-size .size-elements{
    overflow:  hidden;
    display: table-cell;
    width: 70%;
    border-right: 1px solid #e3e3e3;
    margin-top: 6px;
}
.product-size .size-chart{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}
.product-size .size-element {
    list-style: none;
    float: left;
    margin: 7px 15px 7px 0;
    cursor: pointer;
}
.product-size .size-more{
    list-style: none;
    float: left;
    margin: 16px 0 0 0;
    cursor: pointer;
}
.product-size .size-more-link{
    color: #009db4;
    font-size: 12px;
}
.product-size .size-element .item_size{
    border: 1px solid #6e8694;
    display: block;
    color: #757575;
    background: none;
    min-width: 30px;
    height: 32px;
    line-height: 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    font-size: 12px;
    padding: 0 5px;
}
.product-size .size-cells{
    display: table;
    margin: 0px;
    width: 100%;
}
.product-size, .product-color{
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 15px;
}
.product-size .size-element .chosen{
    color: #fff;
    background: #333;
}


.catWrapper.richRelevance--first {
    margin-top: 15px;
}
.catWrapper.product-details {
    margin-bottom: 15px;
}

.catWrapper.product-reviews {
    margin-bottom: 10px;
}
.catWrapper.product-reviews .ratingWrapper, .product-rating-list .review-wrapper {
    padding: 15px 14px;
    color: #757575;
}
.catWrapper.product-reviews .ratingReviews {
    line-height: 1;
    font-size: .9em;
}
.catWrapper.product-reviews .ratingWrapper a, .product-rating-list .write-review-button a,
.product-info-head .write-review-button a
{
    color: #4ab3c4
}
.catWrapper.product-reviews .review-comment, .product-rating-list .review-comment {
    border-top: 1px solid #c7c7c7;
}
.catWrapper.product-reviews .review-comment .ratingBox, .product-rating-list .review-comment .ratingBox {
    margin: 5px 0;
}
.catWrapper.product-reviews .review-author, .product-rating-list .review-author {
    float: right;
    color: #757575;
    font-size: .9em;
}
.catWrapper.product-reviews .review-content, .product-rating-list .review-content {
    font-size: .9em;
    line-height: 1.5em;
}
.product-rating-list .description-detail {
    padding: 15px 20px 5px;
}
.product-rating-list .description-detail .product-price {
    font-size: 1.3em;
    font-weight: normal;
    display: inline-block;
    margin-right: 5px;
    color: #ff3e01;
    white-space: nowrap;
}
.product-rating-list .description-detail .product-price-normal {
    display: inline-block;
    text-decoration: line-through;
    vertical-align: baseline;
    white-space: nowrap;
}
.product-rating-list-form {
    padding: 0 10px;
}
.product-rating-list-form fieldset {
    border: none;
}
.product-rating-list .prd-ratingOptionTitle {
    font-size: .9em;
    color: #757575;
    vertical-align: top;
    line-height: 12px;
    float: left;
}
.product-rating-form-buttons {
    display: table;
    width: 100%;
}
.product-rating-form-buttons-row {
    display: table-cell;
    vertical-align: top;
}
.product-rating-form-buttons-row:first-child {
    width: 45%;
    padding-right: 10px;
}
.product-rating-list .write-review-button {
    margin: 5px 20px;
}
.catWrapper.product-details-text {
    border-bottom: 1px solid #e3e3e3;
}
.catWrapper.product-details-text .description-detail-more-link {
    border-bottom: none;
    margin-bottom: 0;
}
.product-reviews.write-review-button {
    margin: 0 10px;
}
#buynow {
    text-transform: capitalize;
    cursor: pointer;
}
#buynow[disabled], #buynow:disabled {
    background-color: #acacac;
}
#addtocart.icon-add-to-cart {
    font-size: 0;
}
img {
    max-width: 100%;
}
.coming-soon {
    text-align: center;
    color: #fe0018;
    font-weight: bold;
}

.bg-title{
    background-color: #dce2e9;
    padding: 10px 10px 10px 20px;
}

.no-padding{
    padding: 0;
}

h2.gray-border-bottom{
    border-bottom: 1px solid #e8e8e8;
    margin-top: 20px;
    padding: 0 10px 10px 20px;
    font-size: 16px;
    color: #4f749e;
    font-weight: 500;
}

.form-select.product-quantity-wrapper {
    border: none;
    background: none;
    margin-top: 0!important;
    text-align: right;
}

select.product-trigger-qty{
    padding: 0 12px;
    border: 1px solid #696e6b;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    width: auto;
    height: 25px;
    color: #757575;
    margin-top: 0;
    text-align: center;
    font-family: Roboto, Tahoma, sans-serif;
    font-size: 13px;
}
button.icon {
    background-color: transparent;
}

/*Sellers page*/
.sellerListByCat .sellersList {
    display: none;
    border-bottom: 1px solid #b7b7b7;
}
.sellerListByCat .sellerItem a.icon {
    background-image: url(/images/local_mobile_api/sprites2014.png);
    background-size: 258px 27px;
    content: " ";
    display: block;
    width: 22px;
    height: 20px;
    background-position: -236px 0px;
    position: absolute;
    left: 17px;
    background-color: transparent;
    z-index: 105 !important;
}
.sellerListByCat .sellerItem {
    display: block;
    margin: 20px 0 10px 0;
}
.sellerListByCat .sellerItem a.name {
    position: relative;
    left: 47px;
    top: 2px;
    color: #666;
}
.sellerListByCat .sellerItem span.sellerInfoBox {
    display: none;
    border: 1px solid #1D97CC;
    box-sizing: border-box;
    background-color: #fff;
    font-size: 12px;
    line-height: 17px;
    color: #1D97CC;
    position: absolute;
    height: auto;
    top: auto;
    left: 17px;
    right: 30px;
    padding: 5px;
    z-index: 110 !important;
}
.sellerListByCat .sellerItem span.sellerInfoBox label {
    display: block;
}
.sellerListByCat .cat-list-selected {
    display: block;
    text-align: right;
    width: 16px;
    height: 17px;
    margin-left: 5px;
    vertical-align: middle;
    background: url("/images/local_mobile_api/sprites_02.png") -292px 0px no-repeat !important;
    position: absolute;
    right: 10px;
    top: 15px;
}
.sellerInfoOverlayBg {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    background: #000;
    background: rgba(0, 0, 0, 0);
    z-index: 100;
}
.write-review-button a.gray-button{
    font-size: 0.7em;
}
.back-to-top {
    height: 1px;
    background: #e3e3e3;
    margin: 15px 0 45px;
    text-align: center;
    color: #44b1c3
}
.back-to-top-link {
    cursor: pointer;
    display: inline-block;
    margin-top: -12px;
}
.back-to-top .icon-wrapper {
    background: #fff;
    display: block;
    width: 40px;
    text-align: center;
    margin: auto;
    margin-bottom: 3px;
}
#product-more, #categories-more {
    clear: both;
    width: 100%;
    display: block;
    border: none;
    background: none;
    color: #33a4b3;
    cursor: pointer;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
    margin-bottom: -15px;
    outline: none;
}
.mobile #product-more {
    padding-bottom: 5px;
    padding-bottom: 15px;
}
.empty-cart {
    padding: 10px 20px;
    font-size: 1em;
    color: #333;
}
.icon-alert {
    width: 16px;
    height: 13px;
    background: url("/images/local_mobile_api/icon-alert.png");
    display: inline-block;
}

.cart-items-title {
    padding: 10px 20px;
    border-bottom: 1px solid #e8e8e8;
    color: #4f749e;
}

.cart-items-title span {
    float: right;
    font-size: .87em;
    color: #757575;
    padding-top: 2px;
}

.claimOrder {}
.claimOrder__toggle {}
.claimOrder__popin {
    padding: 12px;
    background: #fff;
    width: 270px;
    top: 50% !important;
    left: 50% !important;
    margin-top: -81px !important;
    margin-left: -147px  !important;
}
.claimOrder__popin__close {
    width: 20px;
    height: 20px;
    background: #000;
    border-radius: 100%;
    text-align: center;
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
}
.claimOrder__popin__close:before {
    content: 'x';
    color: #fff;
    line-height: 20px;
    line-height: 15px;
}
.claimOrder__popin__form {}
.claimOrder__popin__form__error {
    color: #d53034;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 10px;
    display: none;
}
.claimOrder__popin__form .claimOrder__popin__form__codeInput {
    border-radius: 0px;
    line-height: 24px;
    height: 40px;
    margin: 0px;
}
.claimOrder__popin__form__button {
    margin: 15px 0 10px 0;
}
.claimOrder__popin__form__link {
    display: block;
    text-align: center;
    line-height: 16px;
}
#form-account-payment .pay-label label, #form-account-payment .pay-label .active label{
    font-weight: normal;
    font-size: 1em;
    color: #666;
    margin-bottom: 10px;
}
.pay-message {
    float: left;
    color: #666;
}
.swiper-item-banner.homepage {
    margin-bottom: 0px;
    overflow: hidden;
}
.swiper-item-banner {
    overflow: hidden;
}

.ageRestriction {
    width: 318px;
    padding: 20px 12px;
    background-color: #fff;
    color: #454545;
    font-size: 1em;
    box-sizing: border-box;
}
.ageRestriction__text {
    margin-bottom: 15px;
    display: block;
    width: 100%;
    color: #757575;
}
.ageRestriction__buttonGroup {
    font-size: 0px;
    text-align: center;
    position: relative;
}
.ageRestriction__buttonGroup:before {
    position: absolute;
    content: '';
    display: block;
    width: 0px;
    left: 50%;
    border-left: 1px dashed #b7b7b7;
    height: 45px;
    top: -4px;
    margin-left: -1px;
}
.ageRestriction__buttonGroup:after {
    content: '';
    display: block;
    clear: both;
}
.ageRestriction__buttonGroup__button {
    display: inline-block;
    width: 135px;
    color: #fff;
    padding: 10px 0px;
    margin: 0 0px 22px 0;
    font-size: 17px;
    cursor: pointer;
}
.ageRestriction__buttonGroup__button:hover {
    text-decoration: none;
}
.ageRestriction__buttonGroup__button_value_over {
    background-color: #f86a3c;
    float: left;
}
.ageRestriction__buttonGroup__button_value_not_over {
    background-color: #22a4b1;
    float: right;
}
.ageRestriction__checkbox {
    background: none;
}
.ageRestriction__checkbox__control {
    float: left;
}
.ageRestriction__checkbox__label {
    padding: 0 0 0 5px;
    display: block;
    float: left;
    color: #adadad;
    line-height: 14px;
    font-size: 12px;
}
.ageRestrictionNotice {
    width: 310px;
    padding: 15px 10px;
    background-color: #fff;
    color: #454545;
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;
}
.ageRestrictionNotice__text {
    display: block;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
    color: #757575;
}
.ageRestrictionNotice__close {
    cursor: pointer;
    position: absolute;
    background: url('/images/spinbasket/sprites_images/general_sprite/icn_general_1.png') 0 -290px no-repeat;
    width: 29px;
    height: 29px;
    top: -14px;
    right: -13px;
    z-index: 2000;
}

.sizechart{
    display: none;
}

.ss-form-bcard-container .disclaimer .text-grey{
    color: #999;
}
.ss-form-bcard-container .ss-form-error{
    margin-bottom: 10px;
}

.swiper-item-banner.homepage {
    margin-bottom: 18px;
}
.search-empty {
    padding: 10px 15px;
}
.search-empty__content {
    margin-left: 95px;
    color: #757575;
    font-size: 14px;
    padding-right: 10px;
}
.search-empty__icon {
    float: left;
    position: relative;
    top: -14px;
    left: 7px;
    z-index: 100;
}
.search-empty__content .search-empty__title {
    margin: 0;
    color: #000;
    font-size: 14px;
    font-weight: 500;
}
.search-related {
    padding: 15px 5px 5px 15px;
    border-top: 1px solid #c7c7c7;
}
.search-related .search-related__title {
    margin: 0;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
}
.search-related__item {
    display: inline-block;
    vertical-align: top;
    margin-right: 7px;
    margin-bottom: 12px;
}
.search-related__item a {
    display: block;
    font-size: 14px;
    line-height: 24px;
    color: #009db4;
    border: 1px solid;
    padding: 0 10px;
    border-radius: 12px;
}
.search-tell-us {
    padding: 8px 15px 0;
    margin-bottom: 13px;
    border-top: 1px solid #c7c7c7;
}
.search-tell-us__title {
    margin-right: 10px;
    font-weight: 500;
    font-size: 14px;
    display: inline-block;
}
.search-tell-us__button {
    height: 30px;
    background: #f37022;
    color: #fff0f2;
    border: none;
    padding: 0 15px;
    font-size: 13px;
    font-weight: normal;
    margin-top: 5px;
}
.search-tell-us__form {
    margin-top: 15px;
}
.search-tell-us__form:after {
    content: "";
    display: table;
    clear: both;
}
.search-tell-us__fields {
    display: table;
    width: 100%;
    box-sizing: border-box;
}
.search-tell-us__field {
    display: table-row;
    font-size: 13px;
    color: #000;
}
.search-tell-us__field label, .search-tell-us__input {
    display: table-cell;
    vertical-align: top;
    padding-bottom: 12px;
}
.search-tell-us__field label {
    padding-top: 7px;
    padding-right: 10px;
    width: 10%;
    white-space: nowrap;
}
.search-tell-us__form .search-tell-us__input input {
    margin: 0 0 2px;
}
.search-tell-us__form .search-tell-us__button {
    float: right;
    margin-top: 0px;
}
.best-price-guarantee {
    padding: 20px 15px 13px;
}
.best-price-guarantee__image {
    float: left;
}
.best-price-guarantee__content, .product-sticker-description {
    color: #757575;
    font-size: 13px;
    margin-left: 60px;
}
.best-price-guarantee__content:after {
    content: "";
    display: table;
    clear: both;
}
.best-price-guarantee__content p {
    margin: 7px 0;
}
.best-price-guarantee__content-header {
    display: table;
    width: 100%;
}
.best-price-guarantee__content-title, .best-price-guarantee__content-link {
    display: table-cell;
}
.best-price-guarantee__content-title, .product-sticker-description__content-title {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    color: #000;
}
.best-price-guarantee__content-link {
    text-align: right;
    padding-left: 15px;
}
.best-price-guarantee__content-link a {
    color: #44b1c3;
    white-space: nowrap;
}
.product-sticker-description {
    margin-left: 0;
    padding: 15px;
    border-bottom: 1px solid #e3e3e3;
}
.product-sticker-description__content-title {
    margin-bottom: 7px;
}
.product-image-container {
    position: relative;
}
.product-image-container .icon-best-price {
    display: none;
}
.product-image-container .product-sticker {
    position: absolute;
    z-index: 100;
    display: block;
    top: -23px;
    right: 15px;
    max-width: 100px;
}

@media (max-width: 380px) {
    .product-image-container .product-sticker {
        max-width: 80px;
    }
}

.product-image-container .product-sticker img {
    width: 100%;
}

.right-align {
    float: right;
    margin-left: 15px;
    margin-top: 2px;
}
.mobile-step .installment_term {
    margin-bottom: 30px;
}
.mobile-step .installment_term .installment_supportbank {
    margin-bottom: 10px;
}
.mobile-step .installment_term .installment_options {
    color: #000;
    margin-top: 15px;
}
.mobile-step .installment_term .form-select {
    margin-bottom: 30px;
}
.mobile-step .installment_term .form-select select {
    padding-left: 15px;
}
.mobile-step .installment_term:after {
    clear: both;
    content: "";
    display: table;
}
#credit-card-payment .installment_wrap {
    margin-top: 10px;
}
#credit-card-payment .installment_wrap select {
    color: #aaa;
}
#credit-card-payment .installment_wrap .installment-option {
    margin: 5px 0px;
}
#credit-card-payment .disabled {
    color: #aaa;
}
#credit-card-payment .select select {
    padding-left: 15px;
}
#credit-card-payment .installment_general_condition .payment_radio_check {
    display: inline-block;
    vertical-align: top;
}
#credit-card-payment .installment_general_condition .payment_radio_check input[type=checkbox] {
    display: inline-block;
    margin-right: 5px;
}
#credit-card-payment .installment_general_condition .payment_form_title {
    color: #000;
    display: inline-block;
    width: 90%;
    margin-top: -1px;
    font-size: 13px;
}
.ss-form-bcard-container .disclaimer .text-grey {
    color: #999;
}
.ss-form-bcard-container .ss-form-error {
    margin-bottom: 10px;
}

.hint {
    cursor: pointer;
    position: relative;
}
.hint__popup {
    display: none;
    background-color: #FFFFFF;
    position: absolute;
    width: 300px;
    padding: 10px 0px;
    border: 1px solid #cccccc;
    top: 20px;
    left: 0;
    cursor: text;
}
.hint__popup__title {
    padding: 0 15px 10px;
    border-bottom: solid #ebebeb 1px;
    color: #000;
    font-size: 1.17em;
    font-weight: bold;
}
.hint__popup__content {
    margin: 10px 15px;
    color: #999;
    font-size: 0.933em;
    line-height: 1.133em;
    max-height: 150px;
    padding: 0 5px 0 0;
    overflow-y: auto;
}
.hint__popup__button {
    display: block;
    width: 150px;
    background-color: #4ab4c4;
    box-sizing: border-box;
    padding: 10px 0;
    text-align: center;
    color: #fff;
    cursor: pointer;
    float: right;
    margin: 0 10px;
}

.delivery-types {
    padding: 0 0 10px 0;
}
.delivery-types__title {
    font-size: 0.867em;
    color: #274a60;
    padding: 0 15px;
    margin: 10px 0 10px 0;
}
.delivery-types__location {
    padding: 0 15px 18px;
    position: relative;
    border-bottom: 1px solid #d3d3d3;
    margin-bottom: 10px;
}
.delivery-types__location_has_arrow:after {
    position: absolute;
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #aaaaaa;
    bottom: -7px;
    left: 24px;
}

.delivery-types__location__form {

}
.delivery-types__location__form__control-group {
    display: table;
    width: 100%;
}
.delivery-types__location__form__control-group__item {
    display: table-cell;
}
.delivery-types__location__form__control-group__item_has_button {
    width: 65px;
    padding: 0 0 0 10px;
}
.delivery-types__location__form__input[type="text"] {
    height: 28px;
    margin: 0px 10px 14px 0px;
    font-size: 0.867em;
    line-height: 1.733em;
    background: #fff;
}
.delivery-types__location__form__input[type="text"][disabled] {
    background: #f0f0f0;
    border-color: #dadada;
}
.delivery-types__location__form__input[type="text"]:last-of-type {
    margin-bottom: 0px;
}
.delivery-types__location__form__submit {
    display: inline-block;
    outline: none;
    border: none;
    padding: 0 10px 0 10px;
    height: 28px;
    line-height: 1.733em;
    text-decoration: none;
    color: #fff;
    vertical-align: middle;
    background: #486168;
    font-size: 1em;
}
.delivery-types__location__form__select {
    margin-bottom: 10px;
}
.delivery-types__location__form__select:before{}
.delivery-types__location__form__select__control {}
.delivery-types__location__form__error-message {
    color: #b52525;
    font-size: 0.8em;
    line-height: 0.933em;
    margin: 9px 0 0 0;
    display: none;
}

.delivery-types__location__form_state_error .delivery-types__location__form__select,
.delivery-types__location__form_state_error .delivery-types__location__form__input[type="text"] {
    border-color: #b52525;
    color: #b52525;
}
.delivery-types__location__form_state_error .delivery-types__location__form__select:before {
    border-top-color: #b52525;
}
.delivery-types__location__form_state_error .delivery-types__location__form__error-message {
    display: block;
}
.delivery-types__location__form__input_type_zip {

}
.delivery-types__location__form__suggestion {
    position: relative;
}
.delivery-types__location__form__suggestion_state_show-popin {
    border: 1px solid #acacac;
    background: #FCFCFC;
}
.delivery-types__location__form__suggestion_state_show-popin .delivery-types__location__form__suggestion__popin {
    display: block;
}
.delivery-types__location__form__suggestion_state_show-popin .delivery-types__location__form__input {
    border: none;
}
.delivery-types__location__form__suggestion__popin {
    display: none;
    padding: 0 10px;
    max-height: 315px;
    overflow-y: auto;
}
.delivery-types__location__form__suggestion__popin__list {
    border-top: 1px solid #acacac;
}
.delivery-types__location__form__suggestion__popin__list__item {
    padding: 7px 0px;
    font-size: 0.867em;
    line-height: 1em;
}
.delivery-types__location__form__suggestion__popin__list__item:first-child {

}
.delivery-types__location__form__suggestion__popin__list__item_state_active {
    background: #ccccff;
}

.delivery-types__location__message {
    font-size: 0.8em;
    line-height: 1.4em;
    position: relative;
    padding: 0 0 0 25px;
}
.delivery-types__location__message__text {

}
.delivery-types__location__message__icon {
    position: absolute;
    top: 0;
    left: 0px;
}
.delivery-types__location__message__change {
    color: #4ab3c4;
}

.delivery-types__data {
    padding: 0 15px;
}
.delivery-types__data_no_leadtime {
    margin-top: 10px;
}
.delivery-types__data__item {
    position: relative;
    padding: 10px 0px 10px 55px;
}
.delivery-types__data__item.hint {
    display: inline-block;
}
.delivery-types__data__item__icon {
    position: absolute;
    left: 22px;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}
.delivery-types__data__item__text {
    font-size: 0.8em;
    line-height: 0.933em;
    margin: 0 0 0px 0;
}
.delivery-types__data__item__time {
    font-size: 0.733em;
    line-height: 0.867em;
    color: #9ca73b;
    margin: 5px 0px 0 0;
}
.delivery-types__data__item__hint {
    margin: 0 0 0 15px;
    position: relative;
}
.delivery-types__data__item__hint__popup {}

.buyer-protection {
    padding: 0 15px 15px;
}

.buyer-protection__logos {
    text-align: center;
    font-size: 0;
    margin: 0 0 20px;
    width: 290px;
    white-space: nowrap;
}
.buyer-protection__logos__logo {
    display: inline-block;
    vertical-align: middle;
}
.buyer-protection__logos__logo_has_seperator {
    margin: 0 0 0 22px;
    position: relative;
}
.buyer-protection__logos__logo_has_seperator:before {
    width: 1px;
    height: 26px;
    background: #dddddd;
    position: absolute;
    left: -11px;
    top: 50%;
    display: block;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    content: '';
}
.buyer-protection__details {}
.buyer-protection__details__item {
    font-size: 0.8em;
    line-height: 1.067em;
    position: relative;
    padding: 0 0 0 20px;
    margin: 0 0 10px 15px;
}
.buyer-protection__details__item__icon {
    position: absolute;
    top: -1px;
    left: 0;
}
.buyer-protection__learn-more {
    font-size: 0.8em;
    line-height: 1.067em;
    text-align: center;
    display: block;
    color: #4ab3c4;
}


.mobile-app-promo {
    box-sizing: border-box;
    margin: 15px 0;
    padding: 20px;

    border-style: solid;
    border-color: #DFDFDF;
    border-width: 1px 0;
}
    .mobile-app-promo__layout {
        display: table;
        width: 100%;
        max-width: 500px;
        margin: auto !important;
    }
        .mobile-app-promo__text,
        .mobile-app-promo__icon {
            display: table-cell;
            vertical-align: middle;
        }
        .mobile-app-promo__text {
            width: 100%;
            font-size: 15px;
            text-align: left;
        }
        .mobile-app-promo__icon {
            padding-left: 18px;
            font-size: 0;
        }
            .mobile-app-promo__icon-image {
                max-width: none;
                height: 35px;
            }
@media (min-height: 750px) {
    .mobile-app-promo__text {
        font-size: 18px;
    }
    .mobile-app-promo__icon-image {
        height: 75px;
    }
}

.search-empty {
    padding: 12px 15px;
}
.search-empty__content {
    margin-left: 95px;
    color: #757575;
    font-size: 14px;
    padding-right: 10px;
}
.search-empty__icon {
    float: left;
    position: relative;
    top: -12px;
    left: 7px;
    z-index: 100;
}
.search-empty__content .search-empty__title {
    margin: 0;
    color: #000;
    font-size: 14px;
    font-weight: 500;
}
.search-related {
    padding: 15px 5px 5px 15px;
    border-top: 1px solid #e8e8e8;
}
.search-related .search-related__title {
    margin: 0;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
}
.search-related__item {
    display: inline-block;
    vertical-align: top;
    margin-right: 7px;
    margin-bottom: 12px;
}
.search-related__item a {
    display: block;
    font-size: 14px;
    line-height: 24px;
    color: #009db4;
    border: 1px solid;
    padding: 0 10px;
    border-radius: 12px;
}
.search-tell-us {
    padding: 8px 15px 0;
    margin-bottom: 13px;
    border-top: 1px solid #e8e8e8;
}
.search-tell-us__title {
    margin-right: 10px;
    font-weight: 500;
    font-size: 14px;
    display: inline-block;
}
.search-tell-us__button {
    height: 30px;
    background: #f37022;
    color: #fff0f2;
    border: none;
    padding: 0 15px;
    font-size: 13px;
    font-weight: normal;
    margin-top: 5px;
}
.search-tell-us__form {
    margin-top: 15px;
}
.search-tell-us__form:after {
    content: "";
    display: table;
    clear: both;
}
.search-tell-us__fields {
    display: table;
    width: 100%;
    box-sizing: border-box;
}
.search-tell-us__field {
    display: table-row;
    font-size: 13px;
    color: #000;
}
.search-tell-us__field label, .search-tell-us__input {
    display: table-cell;
    vertical-align: top;
    padding-bottom: 12px;
}
.search-tell-us__field label {
    padding-top: 7px;
    padding-right: 10px;
    width: 10%;
    white-space: nowrap;
}
.search-tell-us__form .search-tell-us__input input {
    margin: 0 0 2px;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.search-tell-us__form .search-tell-us__button {
    float: right;
    margin-top: 0px;
}

.catWrapper.narrow-products {
    margin-bottom: -16px;
}

.narrow-products__items {
    font-size: 0;
}
.narrow-products__items--rest {
    display: none;
}
.narrow-products__item {
    width: 50%;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 17px;
    color: #757575;
    text-align: center;
    border: solid #e8e8e8;
    border-width: 0 1px 1px 0;
    vertical-align: top;
}
.narrow-products__item a {
    display: block;
    padding: 15px 25px;
    position: relative;
    color: #757575;
}
.narrow-products__item-title, .narrow-products__item-results {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.narrow-products__item-results {
    color: #f37020;
    margin-top: 7px;
}
.narrow-products__item .icon {
    position: absolute;
    top: 28px;
    right: 15px;
}
.narrow-products__more {
    text-align: center;
    color: #4ab3c4;
    font-weight: 500;
    font-size: 14px;
    padding: 12px 15px;
}

.narrow-products__more-link {
    cursor: pointer;
}
.narrow-products__more-link:after {
    content: '';
    display: inline-block;
    border: solid #4ab3c4;
    border-width: 4px;
    border-bottom-color: rgba(255,255,255,0);
    border-left-color: rgba(255,255,255,0);
    border-right-color: rgba(255,255,255,0);
    margin-left: 4px;
    vertical-align: -2px;
    -moz-transform: scale(.9999);
}
.narrow-products__more-link--opened:after {
    border-bottom-color: #4ab3c4;
    border-top-color: rgba(255,255,255,0);
    vertical-align: 2px;
}

.catalog-pagination {
    position: relative;
    margin: 0 15px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    font-size: 13px;
    color: #44b1c3;
}
.catalog-pagination__content {
    height: 1px;
    display: inline-block;
    vertical-align: middle;
    background: #e8e8e8;
    width: 100%;
}
.catalog-pagination__page {
    background: #fff;
    display: inline-block;
    vertical-align: top;
    margin-top: -27px;
    padding: 0 15px;
}
.catalog-pagination .page-link {
    display: block;
    position: absolute;
    top: 14px;
    background: #fff;
    width: 23px;
    height: 23px;
}
.catalog-pagination .page-link--prev {
    left: 0;
    padding-right: 15px;
}
.catalog-pagination .page-link--next {
    right: 0;
    padding-left: 15px;
}
.catalog-pagination .page-link a {
    position: absolute;
    display: block;
    color: #e8e8e8;
    width: 23px;
    height: 23px;
    border: 2px solid;
    border-radius: 50%;
}
.catalog-pagination .page-link a[href] {
    color: #44b1c3;
}
.catalog-pagination .page-link a:before {
    content: "";
    width: 13px;
    height: 2px;
    border-bottom: 2px solid;
    position: absolute;
    top: 9px;
    left: 5px;
}
.catalog-pagination .page-link a:after {
    content: "";
    width: 5px;
    height: 5px;
    border-top: 2px solid;
    border-left: 2px solid;
    position: absolute;
    top: 0;
}
.catalog-pagination .page-link--prev a:after {
     left: -3px;
     -ms-transform: rotate(-45deg) translateY(12px);
     -webkit-transform: rotate(-45deg) translateY(12px);
     -moz-transform: rotate(-45deg) translateY(12px);
     transform: rotate(-45deg) translateY(12px);
}
.catalog-pagination .page-link--next a:after {
    left: 2px;
    -ms-transform: rotate(135deg) translateY(-12px);
    -webkit-transform: rotate(135deg) translateY(-12px);
    -moz-transform: rotate(135deg) translateY(-12px);
    transform: rotate(135deg) translateY(-12px);
}
.dialog-processing {
    padding: 5px 30px 30px;
    text-align: center;
}
.dialog-processing__text {
    margin: 0 0 10px 0;
}
.product-seller__rating {
    display: inline-block;
    position: relative;
    float: right;

}


.gate-lock-banner {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: #FFF;
    background-repeat: no-repeat;
    background-size: cover;

    color: #FFF;
    z-index: 10000;

    font-family: Helvetica;
    font-weight: 300;
}
.gate-lock-banner__close {
    position: absolute;
    left: 13px;
    top: 13px;
    padding: 5px;
    font-size: 20px;
    line-height: 0.8;
    color: #FFF;
    z-index: 99999;
}
.gate-lock-banner__link-download-fullpage {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 1;
}
.gate-lock-banner__content {
    padding: 0 10%;

    position: absolute;
    top: 35%;
    left: 0;
    right: 0;

    text-align: center;
}
.gate-lock-banner__text {
    margin: 0 0 32px;
    font-size: 24px;
    line-height: 1.4;
    font-weight: 400;
}
.gate-lock-banner__button {
    width: 100%;
    margin: auto;
    max-width: 300px;
}

.gate-lock-banner__link-continue {
    font-size: 16px;
    font-weight: 500;
    color: #173445;
    text-align: center;
    padding: 10px 10px;
}

.smart-banner-1-8{
    left: 0;
    right: 0;
    width: 100%;
    background-color: #ffffff;
    z-index: 9998;

    font-family: Helvetica;
    font-weight: 300;
}
.smart-banner-bottom{
    -webkit-box-shadow: 0px -1px 3px 0px rgba(67, 67, 67, 0.75);
    -moz-box-shadow: 0px -1px 3px 0px rgba(67, 67, 67, 0.75);
    box-shadow: 0px -1px 3px 0px rgba(67, 67, 67, 0.75);
}
.smart-banner-top{
    -webkit-box-shadow: 0px 1px 3px 0px rgba(67, 67, 67, 0.75);
    -moz-box-shadow: 0px 1px 3px 0px rgba(67, 67, 67, 0.75);
    box-shadow: 0px 1px 3px 0px rgba(67, 67, 67, 0.75);
}

.smart-banner-sticky {
    position: fixed;
}

.smart-banner-sticky.smart-banner-top {
    top: 0;
    left: 0;
}

.smart-banner-sticky.smart-banner-bottom {
    bottom: 0;
    left: 0;
}

.smart-banner-1-8__inner{
    height: 80px;
    overflow: hidden;
}

.smart-banner-1-8__column {
    float: left;
    position: relative;
    height: 100%;
}
.smart-banner-1-8__column-center {
    width: 40%;
    display: table;
}
.smart-banner-1-8__text {
    display: table-cell;
    vertical-align: middle;
    padding: 0 2% 0 10%;
    font-size: 15px;
}
.smart-banner-1-8__column-left {
    width: 30%;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform: preserve-3d;
    -o-transform: preserve-3d;
    transform-style: preserve-3d;
}
.smart-banner-1-8__column-left__close {
    width: 25%;
    font-size: 20px;
    line-height: 74px;
    padding-left: 10%;
    float: left;
}
.smart-banner-1-8__column-left__logo {
    width: 58%;
    max-width: 70px;
    float: left;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}
.smart-banner-1-8__column-right {
    width: 30%;
}
.smart-banner-1-8__button > a {
    margin: 25px 10px;
    width: initial;
    height: 30px;
    line-height: 30px;
}

.smart-banner-1-4 {
    left: 0;
    right: 0;
    width: 100%;
    background-color: #ffffff;
    z-index: 9998;

    font-family: Helvetica;
    font-weight: 300;
}

.smart-banner-1-4__inner {
    height: 160px;
    overflow: hidden;
}

.smart-banner-1-4__column {
    float: left;
    position: relative;
    height: 100%;
}

.smart-banner-1-4__column-left {
    width: 40%;
    text-align: center;
}
.smart-banner-1-4__column-left__close {
    font-size: 30px;
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 10;
}
.smart-banner-1-4__column-left__image {
    height: 145px;
    position: relative;
    margin-top: 15px;
}
.smart-banner-1-4__column-right {
    width: 60%;
}
.smart-banner-1-4__column-right__top {
    height: 55%;
    display: table;
    text-align: center;
}
.smart-banner-1-4__column-right__bottom {
    height: 45%;
}
.smart-banner-1-4__text {
    font-size: 15px;
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    padding: 0 10%;
}
.smart-banner-1-4__button > img {
    max-width: 70%;
    max-height: 70%;
    padding: 0 10%;
}
@media screen and (min-width: 500px){
    .smart-banner-1-4__column-left {
        width: 30%;
    }
    .smart-banner-1-4__column-right {
        width: 70%;
    }

    .smart-banner-1-4__column-right__top {
        height: 100%;
        width: 50%;
        float: left;
    }

    .smart-banner-1-4__column-right__bottom {
        height: 100%;
        width: 50%;
        float: left;
    }
    .smart-banner-1-4__button > img {
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}


.gatex-banner-half-size {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.50);
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform: preserve-3d;
    -o-transform: preserve-3d;
    transform-style: preserve-3d;

    font-family: Helvetica;
    font-weight: 300;
}

.gatex-banner-half-size__content {
    position: fixed;
    top: 50%;
    background-color: #ffffff;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media only screen and (max-width: 339px) {
    .gatex-banner-half-size__content {
        left: 10%;
        right: 10%;
    }
}
@media only screen and (min-width: 340px) {
    .gatex-banner-half-size__content {
        width: 320px;
        left: 50%;
        margin-left: -160px;
    }
}

.gatex-banner-half-size__close {
    position: absolute;
    top: 5px;
    right: 0px;
    font-size: 20px;
    z-index: 9999;
    display: block;
    width: 30px;
    height: 30px;
}

.gatex-banner-half-size__link-download-fullpage {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
}

.gatex-banner-half-size__top-image {
    width: 100%;
}
.gatex-banner-half-size__promote-text {
    text-align: center;
    padding: 15px 0 10px 0;
}
.gatex-banner-half-size__promote-text h2 {
    color: #173445;
    padding: 0 10px 5px 10px;
    font-size: 1.75em;
    letter-spacing: 0.01em;
}
.gatex-banner-half-size__promote-text p {
    color: #173445;
    font-size: 11px;
}
.gatex-banner-half-size__sale-code-block {
    height: 50px;
    padding: 0 15px;
    left: 10px;
    right: 10px;

    transform: translate3d(0,0,0);
}

.gatex-banner-half-size__sale-code-block__wrap-sale-code {
    background-color: #27495F;
    height: 43px;
    text-align: center;
    display: table;
    width: 100%;
}
.gatex-banner-half-size__sale-code-block__sale-code-text {
    display: table-cell;
    vertical-align: middle;
    color: #ffffff;
    font-size: 1.1em;
}
.gatex-banner-half-size__sale-code-block__css3 {
    overflow: auto;
    height: 100%;
    padding: 0 40px;
    position: relative;
    transform: translate3d(0,0,0);
}
.gatex-banner-half-size__sale-code-block__css3:after, .gatex-banner-half-size__sale-code-block__css3:before {
    content: "";
    position: absolute;
    display: block;
    top: 5px;
    border: 1.5em solid #27495F;
    z-index: -1;
}
.gatex-banner-half-size__sale-code-block__css3:before {
    left: 0;
    border-right-width: 1.5em;
    border-left-color: transparent;
    border-left-width: 21px;
}
.gatex-banner-half-size__sale-code-block__css3:after {
    right: 0;
    border-left-width: 1.5em;
    border-right-color: transparent;
}

.gatex-banner-half-size__wrap-all-button {
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 60%;
}

.gatex-banner-half-size__button {
    padding: 0 10px;
}
.gatex-banner-half-size__button a {
    font-size: 17px;
    font-weight: 600;
    margin: 10px 0;
}
.gatex-banner-half-size__wrap-link-continue {
    text-align: center;
    padding: 0 0 10px 0;
}

.gatex-banner-half-size__link-continue {
    font-size: 16px;
    font-weight: 500;
    color: #173445;
}
.gatex-banner-half-size__footer-text {
    text-align: center;
    padding: 0px 5px 10px 5px;
}

.gatex-banner-half-size__footer-text p {
    font-size: 10px;
    color: #173445;
    text-transform: uppercase;
}
.restricted-products-list {
    list-style-type: disc;
    margin: 10px 0 15px 15px;
    font-size: 13px;
    color: #7B7B7B;
}

#hellopay-payment {
    margin-bottom: 20px;
}

#hellopay-payment .l-payment-form__field {
    color: #666;
}
.seller__expiration-date {
    padding: 10px 18px 10px 18px;
}
.multisouce-expiration-date {
    border-top: 1px solid #e3e3e3;
    padding: 7px 0px 7px 0px;
    margin: 10px 0 0 0;
}
.expiration-date {}
p.expiration-date__label {
    margin: 0px;
    font-size: 12px;
    color: #646464;
    line-height: 14px;
}
