.l-billing-address-container {
}

.l-billing__toggler {
    margin-bottom: 13px;
}

    .l-billing__toggler label {
        font-size: 13px;
        color: #595959;
    }