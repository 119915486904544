.icon {
    background-image: url(/images/local_mobile_api/sprites_02.png);
}
.icon-star {
    width: 14px;
    height: 14px;
    background-position: -339px -36px;
}

.icon-language-en {
    width: 19px;
    height: 19px;
    background-position: -131px -31px;
}

.icon-language-ms {
    width: 19px;
    height: 19px;
    background-position: -150px -31px;
}

.icon-language-th {
    width: 19px;
    height: 19px;
    background-position: -169px -31px;
}

.icon-language-vi {
    width: 19px;
    height: 19px;
    background-position: -276px -32px;
}

.icon-check-icon {
    width: 13px;
    height: 12px;
    background-position: -354px -38px;
}

.icon-collapse-icon {
    width: 13px;
    height: 10px;
    background-position: -367px -38px;
}

.icon-expand-icon {
    width: 13px;
    height: 10px;
    background-position: -380px -38px;
}
