.productDetailsMain ul.value {
	text-align: left;
    width: 100%;
}

.itm-newFlag, .itm-saleFlag, .itm-saleFlagPercent {
    background-color: #FFFFFF;
    font-size: 1.4rem;
    line-height: 16px;
    font-weight: bold;
    left: 0px;
    padding: 2px 9px 1px 7px;
    position: absolute;
    text-indent:-4px;
    text-transform: uppercase;
    top: 5px;
}


.itm-newFlag {
    background: url("/images/core_mobile/icons/new.gif") no-repeat scroll 0 0 transparent;
    color: #339933;
    font-size: 10px;
    width: 30px;
}
.itm-saleFlagPercent {
    background: url("/images/core_mobile/icons/sale_right.gif") no-repeat scroll 0 0 transparent;
    left: auto;
    text-indent:8px;
    right: 0px;
}
.itm-saleFlag {
    background: url("/images/core_mobile/icons/sale.gif") no-repeat scroll 0 0 transparent;
}
.itm-saleFlag, .itm-saleFlagPercent {
    color: #CC0000;
    font-size: 10px;
    width: 30px;
}

.searchForm .btnHolder{
    width: auto;
    right: 0;
}

#mobileCoupon {
    margin-top:5px;
}