/*
 * Shipping block
 * */
.l-shipping {
}

    .l-shipping__header {
        position: relative;
        display: table;
        width: 100%;
        border: 1px solid #ebebeb;
        border-width: 1px 0;
        background: #fbfaf4;
        line-height: 13px;
    }

    .l-shipping__header:before {
        position: absolute;
        content:'';
        left: 0; top: 0;
        margin: -1px;
        padding: 1px 0;
        width: 3px; height: 100%;
        background: #ccc;
    }

    .l-shipping__col {
        display: table-cell;
        padding: 9px 12px;
        vertical-align: middle;
        white-space: nowrap;
    }

        .l-shipping__products-count {
            color: #b6b6b6;
        }

    .l-shipping__seller {
        padding: 6px 13px;
        border-bottom: 1px solid #e5e5e5;
        font-size: 10px;
        color: #757575;
    }

    .l-shipping__col_r {
        width: 1%;
    }

    .l-shipping__products {
        position: relative;
        padding: 0 12px;
    }

    .l-shipping__products-count {
        font-size: 10px;
    }

    .l-shipping__title {
        font-size: 11px;
        font-weight: bold;
    }

    .l-shipping__type-icon,
    .l-shipping__type {
        vertical-align: middle;
        display: inline-block;
    }
    
    .l-shipping__type {
        font-size: 11px;
    }

    .l-shipping__type-icon {
        margin-right: 9px;
    }
    

    .l-shipping__more {
        position: absolute;
        top: 50%;
        right: 0;
        padding: 10px;
        margin-top: -16px;
        /*margin-left: -16px;*/
    }