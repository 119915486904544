.gatebanner {
    width: 300px;
    height: 320px;
    margin: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -160px;
    margin-left: -150px;
    z-index: 10001;
    text-align: center;
    background-color: #FFF;
    display: none;
    background-position: center;
    background-repeat: no-repeat;
}

.gatebanner.gatebanner-iphone {
    width: 300px;
    height: 300px;
    margin-top: -150px;
    margin-left: -150px;
}

.gatebanner-bg {
    background: #666;
    background: rgba(0,0,0,0.7);
    background-image: none;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 9999;
    display: none;
}

.gatebanner-controls {
    position: absolute;
    width: 100%;
    bottom: 20px;
}

.gatebanner-download-btn {
    text-transform: uppercase;
    font-size: 1.2em;
    font-weight: 700;
    background: #f36f21;
    padding: 10px 20px;
    display: inline-block;
    cursor: pointer;
}

.gatebanner-download-btn a {
    color: #FFF;
    display: inline-block;
    vertical-align: middle;
}

.gatebanner-close-app {
    display: inline-block;
    padding: 10px;
    color: #004b91;
    cursor: pointer;
    font-size: 14px;
}

.gatebanner-get-app {
    background: url("/images/local_mobile_api/gatebanner/arrow-right-orange.png") no-repeat ;
    display: inline-block;
    vertical-align: middle;
    width: 21px;
    height: 21px;
}

.gatebanner-download-btn.tablets {
    zoom: 1.3;
}

.gatebanner-download-btn.tablets .gatebanner-get-app {
    width: 22px;
    height: 23px;
}

.gatebanner-close-btn {
    background: url('/images/local_mobile_api/gatebanner/close_icon.png') 0px 0px no-repeat;
    width: 29px;
    height: 29px;
    top: -10px;
    right: -9px;
    z-index: 2000;
    display: block;
    position: absolute;
}