/*My Account*/
.content h2.content-header{
    /*background: #003466;
    background: -moz-linear-gradient(top,  #003466 0%, #084d90 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#003466), color-stop(100%,#084d90));
    background: -webkit-linear-gradient(top,  #003466 0%,#084d90 100%);
    background: -o-linear-gradient(top,  #003466 0%,#084d90 100%);
    background: -ms-linear-gradient(top,  #003466 0%,#084d90 100%);
    background: linear-gradient(to bottom,  #003466 0%,#084d90 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003466', endColorstr='#084d90',GradientType=0 );
    border-bottom: 1px solid #faa419;*/
    width: 100%;
    margin: 0;
    color: #2d57ad;
    font-size: 1em;
    font-weight: 300;
    padding: 14px 10px;
    border-bottom: 1px solid #c7c7c7;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.my-account-list{
    margin-bottom: 50px;
    padding: 0;
}
.my-account-list .icon-arrow-right-side {
    float: right;
}
.my-account-list li{
    border-bottom: 1px solid #c7c7c7;
}

.my-account-list li a{
    color: #000;
    font-size: 1em;
    width: 100%;
    height: 100%;
    display: inline-block;
    padding: 14px 10px;
    box-sizing: border-box;
}

/*.my-account-list li a:after{
    width: 7px;
    height: 20px;
    content: '';
    display: inline-block;
    background: url(/images/local_mobile_api/arrow.png) no-repeat left 3px;
    float: right;
}
*/
.display-table{
    display: table;
    width: 100%;
    box-sizing: border-box;
}

.display-cell{
    display: table-cell;
}

/*My Account - Address*/
.my-account input[type=radio], .my-account input[type=checkbox]{
    width: 16px;
    height:  16px;
    display: none;
}
.my-account input[type=radio] + label:before, .my-account .newsletter-container input[type=checkbox] + label:after{
    content: '';
    background: url(../images/local_mobile_api_opera_mini/sprites_button.png) no-repeat top left;
    height: 14px;
    padding: 0 0 2px 18px;
}
.my-account input[type=radio] + label:before{
    position: relative;
    display: inline-block;
    top: 3px;
    left: 0;
    width: 16px;
    height: 16px;
    padding: 0;
    background-position: 0 -30px;
}
.my-account input[type=radio]:checked + label:before {
    background-position: 0 -48px;
}

.my-account .address-content{
    padding: 0 1em;
}

.my-account .address-content .address{
    display: table-cell;
    width: 70%;
}

.my-account .address-update{
    padding-left: 5%;
    width: 20%;
}

/*My Account - Order*/
.my-account .border-bottom-grey{
    border-bottom: 1px solid #8f8f8f;
}

.my-account .order-container{
    padding-top: 1em;
}

.my-account .order-container.first {
    padding-top: 0;
}

#orderTimeLimitForm {
    margin-bottom: 1em;
}

.my-account .order-header{
    font-size: 1.2em;
    padding-bottom: 1em;
}

.my-account .order-header p{
    margin: 0;
    line-height: 21px;
}

.my-account .order-detail{
    display: none;
}

.my-account .product-container:last-of-type .product-info {
    border-bottom: 0;
}

.my-account .product-thumbnail{
    display: table-cell;
    width: 35%;
    text-align: center;
    vertical-align: top;
    padding-top: 1.25em;
}

.my-account .product-name{
    font-size: 1.15em;
    padding-bottom: .5em;
}

.my-account .product-info{
    min-height: 78px;
    padding: 1em 0;
}

.my-account .product-info p{
    margin: 0;
}

.accordion-header{
    background: url(/images/local_mobile_api/arrow_down.png) no-repeat right;
}

.accordion-header.icon-collapse{
    background: url(/images/local_mobile_api/arrow_up.png) no-repeat right;
}

/*My Account - Detail*/
/*.my-account input[type="text"], .my-account input[type="email"]{
    border-radius: 3px;
}*/

.my-account select{
    border: 1px solid #c0c0c0;
    background-color: #ffffff;
    color: #333;
    padding: 5px;
    width: 100%;
    margin-top: 5px;
    line-height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.my-account select.year{
    width: 38%;
    margin-right: 0.7%
}
.my-account select.month, .my-account select.date{
    width: 28.95%;
}
.my-account select.date{
    float: right;
}
.my-account p.no-margin-bottom{
    margin-bottom: 0;
}
.my-account div.form-row{
    margin: 0;
}
.form-row label{
    padding-right: 1em;
}

/*My Account - Newsletter*/
.my-account.newsletter{
    border-bottom: none;
}

.my-account .newsletter-container{
    padding: 14px 10px;
    font-size: 1em;
}

.my-account .newsletter-container .newsletter-description{
    font-size: .9em;
    color: #7b7b7b;
}

.my-account .newsletter-container input[type=checkbox]{
    width: 100%;
}

.my-account .newsletter-container input[type=checkbox] + label:after{
    background-position: 0 2px;
    float:right;
    margin-top: .5em;
}

.my-account .newsletter-container input[type=checkbox]:checked + label:after{
    background-position: 0 -14px;
}

#mobile-birthday-wrapper label{
    margin-bottom: 10px;
}

#mobile-birthday-wrapper select{
    border: 1px solid black;
}

/*#form-customer-create {
    padding: 5px;
}*/
#form-customer-create label{
    color:#383838;
    margin-bottom: 5px
}

#form-customer-create input{
    color:#383838;
}

#form-customer-create select{
    width:100%;
    border-radius: 5px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
    border: 1px solid #cccccc;
    background-color: #ffffff;
    color: #333;
    padding: 5px 0;
    margin-top: 5px;
    text-indent: 10px;
    line-height: 100%;
}

#form-customer-create .input-cell{
    width:100%;
}

#form-customer-create table{
    width:100%;
}

#form-customer-create .birthday-label-column{
    width:40px;
}
#form-customer-create table td{
    width:33.3%;
}

#form-customer-create .customer-create-row{
    margin-bottom: 20px;
}
