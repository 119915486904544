input.l-checkbox[type=checkbox] {
    width:  18px;
    height: 18px;
    position: absolute;
    left: -99999px;
}

input.l-checkbox[type=checkbox] + label {
    position: relative;
    padding: 0 0 0 21px;
}


/* 
TODO 
.l-mobile-step input[type=checkbox] + label:after {
the better way to do the custom checkboxbutton

but if we use SPRITES we cant support conviniently it in PSEUDO ELEMENTS
we could use a LESS mixin later to solve this problem
something like
<code>
    input[type=checkbox] + label:after {
        .icon-svg;
        .icon-svg-check-off;
    }

    input[type=checkbox]:checked + label:after {
        .icon-svg-check-on;
    }
</code>
*/

input.l-checkbox[type=checkbox] + label>.check_on,
input.l-checkbox[type=checkbox] + label>.check_off {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -9px;
}

input.l-checkbox[type=checkbox] + label>.check_on {
    display: none;
}

input.l-checkbox[type=checkbox]:checked + label>.check_on {
    display: block;
}

input.l-checkbox[type=checkbox]:checked + label>.check_off {
    display: none;
}