/**
*   SG Postcode - blind copy of previous realisation
block 
.l-postcode-address
**/
.l-postcode-address .address-form-field{
    position: relative;
}
.l-postcode-address .loadding > .loaddingbox {
    width: 18px;
    height: 18px;
    background: url("/images/local_mobile_api/loading18.gif") no-repeat;
}
.l-postcode-address .valid-icon {
    width: 16px;
    height: 12px;
    margin-left: 5px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAMCAYAAABr5z2BAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NUZFQUVEODI0Q0FFMTFFNDg5NDhBMTdBNzcwMkMwQ0MiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OUFFQzUyOUE0Q0M3MTFFNDg5NDhBMTdBNzcwMkMwQ0MiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1RkVBRUQ4MDRDQUUxMUU0ODk0OEExN0E3NzAyQzBDQyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1RkVBRUQ4MTRDQUUxMUU0ODk0OEExN0E3NzAyQzBDQyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PnSK5NMAAAEBSURBVHjaYmQgA8ze7BQMpDKAOISRDM3OQGo7ELMC8RFGEjWbAam9QMwDxP+AOJwJKuENxPwENGsCqa1QzSCQkeq7bw0TUCIayNkExBuAbC4cmmWB1G4gFoEKVQE1zwYxQC5IgNIOUEPY0DSLQjVLQ4X6gJrbYfIgjUFAfArKdwXiNUBNrFDNfEBqGxCrQ+UXAHEJsgWMUIUg/x8EYn2o+DIgToH62REqthGIg4G2/8UwAMmp+4BYByr0AogloGyQ4Z5Azd/Rw4cRzb8yQOoAECsjCZ8HuQKo+SO2AGbEEeLHoYF2B4itgJpf44peJnQBoOLH0Bg5DcQu+DSDAECAAQAoyUxeMpXjpQAAAABJRU5ErkJggg==');
    vertical-align: middle;
    display: none;
}
.l-postcode-address .valid-icon.valid {
    display: inline-block;
}
.l-postcode-address .error-icon {
    width: 14px;
    height: 14px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NUZFQUVEN0U0Q0FFMTFFNDg5NDhBMTdBNzcwMkMwQ0MiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NUZFQUVEN0Y0Q0FFMTFFNDg5NDhBMTdBNzcwMkMwQ0MiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo1RkVBRUQ3QzRDQUUxMUU0ODk0OEExN0E3NzAyQzBDQyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo1RkVBRUQ3RDRDQUUxMUU0ODk0OEExN0E3NzAyQzBDQyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PqbZm70AAACkSURBVHjaYmQAgktKWhVASheI4/TuXfvLgAUA1TADqUVAfBmopoMRqqkdKr8Mm2YkTVFQoUomqE0wAJJYBFWISxMI6DLikADbDGVjlWPEY+pyKB2JrgnkFUYCTmLApgnEYcQSCIvRbIHZHoscaEwMZAImLE6NxKIuEj20yQ8ccqODBZcELCCABschJQ44DfLjZVyaQADKjoPKwcBlRnITOUCAAQCSzFo1jL1I5AAAAABJRU5ErkJggg==');
    display: none;
    vertical-align: middle;
}
.l-postcode-address .error-icon,
.l-postcode-address .valid-icon,
.l-postcode-address .loadding + .loaddingbox{
    position: absolute;
    top: 0;
    right: 10px;
}
.l-postcode-address .valid-icon{
    top: 15px;
}
.l-postcode-address .error-icon{
    top: 13px;
}
.l-postcode-address .loadding + .loaddingbox {
    top: 11px;
}


.l-postcode-address .loadding > .error-icon,
.l-postcode-address .loadding > .valid-icon {
    display: none;
}
.l-postcode-address .error-text {
    color: #d2232a;
    display: none;
    margin: 5px 0 0 145px;
}
.l-postcode-address .error input {
    color: #B94A48 ;
    border-color: #B94A48;
}
.l-postcode-address .error .error-icon {
    display: inline-block;
}
.l-postcode-address .error .error-text {
    display: block;
}
.l-postcode-address .address-form-field {
    margin-bottom: 10px;
}
.l-postcode-address .form-mobile .address-form-field div{
    margin-top: 0;
}
.l-postcode-address .address-form-item {
    display: none;
    margin-top: 0;
}
.l-postcode-address .address-form-item.text {
    font-size: 12px;
    margin-bottom: 8px;
}
.l-postcode-address .address-form-item > .select{
    width: 100%;
    padding: 5px 5px 0 5px;
}
.l-postcode-address .form-label {
    width: 40%;
    padding-right: 5px;
    color: #737373;
    display: inline-block;
}
.l-postcode-address .address-info {
    display: inline-block;
    vertical-align: middle;
    float: right;
    text-align: right;
}
.l-postcode-address .address-text {
    color: #4a4a4a;
}
.l-postcode-address .show-info {
    display: block;
}
.l-postcode-address .address-box div{
    margin: 0;
}