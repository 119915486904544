.fct-bd {
    display: none;
}

.filter-header {
    background-color: #dce2e9;
    padding: 8px 15px;
    color: #4f749e;
    font-size: 16px;
    font-weight: normal;
    min-height: 27px;
    line-height: 27px;
}

.fct-list {
    padding-bottom: 60px;
}

.fct-list .fct {
    padding: 7px 0px;
    line-height: 27px;
    font-size: 13px;
    /*margin-left: 10px;*/
    background: none;
    cursor: default;
    vertical-align: top;
}

/*.fct-list .fct + .fct {
    border-top: 1px solid #DDD;
}
.fct-list .fct + .fct:last-child {
    border-bottom: 1px solid #DDD;
}*/
.fct-list > .fct {
    padding: 0;
}
.fct-list .fct-hd {
    padding: 7px 15px;
    margin: 0;
}
.fct-list .filter-options .fct, .fct-list .fct-hd,
.fct-list .fct.single-choice {
    border-bottom: 1px solid #c7c7c7;
}
.fct-list .fct .icon {
    display: inline-block;
    vertical-align: middle;
}
.fct-list .fct .icon-star {
    margin-top: -2px;
}
.fct-bd {
    /*position: absolute;*/
    /*top: 0;*/
    /*left: 0;*/
    width: 100%;
    background: #FFF;
}
.fct-hd {
    margin-left: 15px;
    line-height: 27px;
}
/*.filter-options {
    padding-bottom: 40px;
}*/
.filter-options .fct {
    padding-left: 15px;
    color: #757575;
    font-size: 1em;
}

.filter-options {
  position: relative;
}

#overlay-filter .filter-options ul.load::before {
    position: absolute;
    content: '';¬
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.75);
    z-index: 1500;
}

.filter-header .filter-left {
    float: left;
    padding-right: 10px;
}

.filter-header .filter-left span + span {
    color: #f37022;
    font-weight: 400;
}

.filter-header .filter-right {
    float: right;
    text-align: right;
}

.filter-header .cta-arrow {
    /*line-height: 24px;*/
    /*vertical-align: bottom;*/
    height: auto;
    position: static;
    background: none;
    display: none;
}

.checkbox:before, .uncheckbox:before{
    content: '';
    border: 1px solid #898989;
    width: 12px;
    height: 12px;
    float: left;
    margin-right: 15px;
    margin-top: 6px;
}
.checkbox {
    position: relative;
}

.checkbox::after {
    position: absolute;
    content: "";
    background: url('/images/local_mobile_api/sprites/icon-3x/arrow-active.png') 0 0 no-repeat;
    background-size: cover;
    width: 12px;
    height: 12px;
    left: 1px;
    top: 7px;
}

.uncheckbox {
    color: #757575;
}

.radio-checked {
    background: url('/images/local_mobile_api/radio_bg.png') no-repeat;
    padding-left: 24px;
}

.radio-unchecked {
    background: url('/images/local_mobile_api/radio_bg_nofill.png') no-repeat;
    padding-left: 24px;
}
li.fct label {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 6px .8em 0 0;
    vertical-align: top;
    border: 1px solid #898989;
}
li.fct input[type="radio"]:checked + label {
    background: url('/images/local_mobile_api/sprites/icon-3x/arrow-active.png') 0 0 no-repeat;
    background-size: cover;
}
li.fct input[type="radio"] {
    display: none;
}
.filter-clear, .filter-more {
    cursor: pointer;
    padding: 0;
    font-size: 16px;
    font-weight: normal;
}
.filter-clear a,
.filter-more a {
    display: inline-block;
    color: #4f749e;
    background: #eee;
    font-size: 14px;
    padding: 0 10px;
    height: 26px;
    line-height: 27px;
    white-space: nowrap;
}

.filter-more {
  margin-right: 10px;
}

.fct-action {
    position: fixed;
    padding-bottom: 5px;
    padding-top: 5px;
    bottom: 0;
    width: 100%;
    z-index: 1000;
    background: #fff;
    border-top: 1px solid #d3d3d3;
    box-shadow: 0 -2px 20px #fff2be;
}

.fct-action .btn {
    background: #EEE;
    text-align: center;
    width: 47%;
    height: 38px;
    float: left;
    cursor: pointer;
    border-radius: 0;
    font-size: 15px;
    margin: 0 auto;
}

.fct-action .btn div {
    padding: 0 10px;
    text-align: center;
    color: #fff;
    line-height: 38px;
}

.fct-action .btn.disabled, #filter-btn-ok.disabled {
    background-color: #f7f8f8;
}

.fct-action .btn.disabled div {
    border-color: #edeeee;
    color: #d1d0d0;
}

#filter-btn-cancel{
    background-color: #acacac;
    margin: 0 2%;
    width: 43%;
}
#filter-btn-ok{
    background-color: #f37022;
    width: 51%;
}

.sort-option {
    display: inline-block;
    background: url('/images/local_mobile_api/catalog_sort_icon.png') no-repeat;
    width: 21px;
    height: 21px;
    vertical-align: middle;
}

.filter-option {
    display: inline-block;
    background: url('/images/local_mobile_api/catalog_filter_icon.png') no-repeat;
    width: 21px;
    height: 21px;
    vertical-align: middle;
}


@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 150dpi) {
    .sort-option {
        background: url('/images/local_mobile_api/catalog_sort_icon@2x.png') no-repeat;
        background-size: 21px 21px;
    }
    .filter-option {
        background: url('/images/local_mobile_api/catalog_filter_icon@2x.png') no-repeat;
        background-size: 21px 21px;
    }
}

.overlay {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 50;
    background: #FFF;
    overflow-x: hidden;
}

.filter-by .sort-filter-option {
    width: 50%;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.filter-by-wrapper--3items .filter-by .sort-filter-option {
    width: 33.333%;
}

.sort-filter-option .filterBtn {
    cursor: pointer;
    padding: 10px 0;
    color: #4f749e;
}
.sort-filter-option .filterBtn a {
    display: block;
    white-space: nowrap;
}
.filter-by .sort-filter-option:first-child {
    border-left: none;
}
.sort-filter-option .filterBtn .icon {
    margin-right: 4px;
    margin-top: -2px;
    vertical-align: middle;
    line-height: 35px;
}
.sort-filter-option .filterBtn .icon-sort {
    margin-top: -2px;
}
.sort-filter-option .filterBtn .icon-grid-switch {
    margin-top: 1px;
}
.sort-filter-option .filterBtn .icon-list-switch {
    margin-top: 1px;
}
.sort-filter-option .filterBtn .filterBtnText {
    line-height: 17px;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    text-align: left;
    font-size: 14px;
    max-width: 80%;
}
.fct__cancell-all-link {
    padding: 5px;
    border: 1px solid #CCC;
}
.fct__cancel {
    padding-bottom: 12px;
    text-align: right;
    padding-right: 10px;
}
.sort-filter-options {
    background-color: #dce2e9;
    color: #595959;
    display: block;
    min-height: 2em;
    line-height: 2em;
    padding: 7px 5px 7px 1em;
}
.sort-filter-options span:first-child {
    color: #4f749e;
}
