/*
 * Product block
 * */
.l-product {
    position: relative;
}
    .l-product__thumb {
        width: 90px; height: 90px;
        text-align: center;
    }

    .l-product__img {
        max-width: 100%;
        max-height: 100%;
    }

    .l-product__thumb:before,
    .l-product__img {
        display: inline-block;
        vertical-align: middle;
    }

    .l-product__thumb:before {
        content: '';
        height: 100%;
    }

    .l-product__share-btn {
        display: inline-block;
        height: 26px;
        background-color: #ebf5f7;
        outline: thin solid #ebf5f7;
        color: #2fa4ab;
        padding: 6px;
        text-transform: uppercase;
        box-sizing: border-box;
        position: absolute;
        right: 1px;
        top: 36px;

    }
    .l-product__share-btn .icon-svg {
        margin-right: 3px;
    }


.l-product_full {
    color: #757575;
    overflow: hidden; /* clearfix */
    padding: 8px 0 5px;
}
    .l-product_full .l-product__title {
        color: #000;
        margin-bottom: 8px;
    }

    .l-product_full .l-product__wrap {
        overflow: hidden;
        padding: 2px 0;
    }

    .l-product_full .l-product__thumb {
        float: left;
        clear: left;
        margin-right: 5px;
    }

.l-product_short {
    width: 90px;
    margin: 0 auto;
}
    .l-product_short .l-product__thumb {
        margin-bottom: 5px;
    }

    .l-product_short .l-product__title {
        overflow: hidden;
        height: 2.66em; /* line-height * 2 */
        padding: 0 3px;
        font: normal 11px/1.33 'Roboto', sans-serif;
    }


.l-product_checkout {
    color: #333;
    overflow: hidden; /* clearfix */
    padding: 0;
}
    .l-product_checkout .l-product__title {
        margin-bottom: 4px;
    }


    .l-product_checkout .l-product__thumb {
        float: left;
        clear: left;
        margin-right: 10px;
        width: 64px;
        height: 74px;
    }

    .l-product_checkout .l-product__wrap {
        overflow: hidden;
        padding: 0 40px 0 0;
    }

        .l-product_checkout .l-product__count {
            color: #666;
        }

    .l-product_checkout .l-product__price {
        float: right;
    }
