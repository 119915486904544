.l-form-select {
    display: block;
    position: relative;
    border: 1px solid #e2e2e2;
    -webkit-border-radius: 3px;
            border-radius: 3px;
    white-space: nowrap;
}

    .l-form-select__input {
        display: block;
        width: 100%;
        font-size: 13px;
        border: 0 none;
        background: transparent;
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
        position: relative;
        z-index: 1;
    }

    .l-form-select:after {
        position: absolute;
        top: 50%;
        content: '';
        border: solid transparent;
        border-width: 6px 4px 0;
        border-top-color: #646464;
        margin: -3px -4px 0;
    }

.l-form-select_error {
    border-color: #d2232a;
    color: #d2232a;
}

/* Assuming that `l` is default size */
.l-form-select__input,
.l-form-select_size_l .l-form-select__input {
    padding: 12px 25px 13px 10px;
}

.l-form-select:after,
.l-form-select_size_l:after {
    right: 10px;
}

.l-form-select_size_m .l-form-select__input {
    padding: 3px 25px 3px 10px;
}

.l-form-select_size_m:after {
    right: 10px;
}
