.mobilestep_addnewaddress .main-container,
.mobilestep_deliveryinformation .main-container{
    background: #f1f1f1;
}

    .l-mobile-step_deliveryinformation {
        margin: 0 18px;
        padding: 13px 13px 10px;
        border: 1px solid #c9cfd2;
        background: #fff;
    }


    .l-mobile-step_deliveryinformation h2 {
        font-size: 14px;
        font-weight: 500;
        padding: 0;
        color: #4f749e;
        margin: 0 0 10px;
    }

    .l-mobile-step_deliveryinformation fieldset {
        margin: 0;
        padding: 0;
        border: 0 none;
    }