.l-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 150;
    -webkit-box-shadow: 0px -5px 10px 0px rgba(211,211,211,1);
    -moz-box-shadow: 0px -5px 10px 0px rgba(211,211,211,1);
    box-shadow: 0px -5px 10px 0px rgba(211,211,211,1);
    
    padding-top: 15px;
    padding-bottom: 15px;
    box-sizing: border-box;

    -webkit-transition: bottom .2s ease-in-out;
            transition: bottom .2s ease-in-out;
}

.l-banner_hidden {
    bottom: -100px;
}

.l-banner__icon {
    float: left;
    margin-left: 10px;
}
p.l-banner__text {
    float: left;
    margin-left: 10px;
    width: 42%;
}
.l-banner__btn {
    background: #f37022;
    border-radius: 0;
    padding: 11px 8px;
    color: #fff;
    text-transform: uppercase;
    display: inline-block;
    box-sizing: border-box;
    margin-right: 17px;
    margin-top: 5px;
    float: right;
}
.l-banner__btn_close {
    float: left;
    font-size: 36px;
    margin-left: 10px;
    color: #afafaf;
    margin-top: 15px;
}