/* expand - collapse styles */
.collapse {
    display: block;
}

.expand {
    display: inline-block;
}

.l-order {
    margin-bottom: 8px;
}
    .l-order__header {
        display: table;
        width: 100%;
        border: 1px solid #ebebeb;
        border-width: 1px 0;
        background: #f5fafd;
        line-height: 16px;
    }

    .l-order__col {
        display: table-cell;
        vertical-align: middle;
        padding: 9px 15px;
    }

    .l-order__col_r {
        width: 1%;
        white-space: nowrap;
    }

    .l-order__toggle,
    .l-order__status,
    .l-order__icon {
        display: inline-block;
        vertical-align: middle;
    }

    .l-order__date-placed {
        font-size: 11px;
    }

    .l-order__status {
        margin-right: 12px;
    }

    .l-order__status_declined {
        color: #f25c22;
    }

    .l-order__status_delivered {
        color: #8aad4e;
    }

    .l-order__receipt {
    }

    .l-order__title {
        font-weight: bold;
    }

    .l-order__link {
        color: #33a3b3;
    }

    .l-order__wrap {
        padding: 8px;
    }

        .l-order__info {
        }   

            .l-order__payment-wrap {
                display: table;
                width: 100%;
                margin-top: -6px;
            }

                .l-order__payment-col {
                    display: table-cell;
                    padding: 15px 0;
                }

                .l-order__payment-type {
                    color: #757575;
                    padding-left: 13px;
                }

                .l-order__payment-type strong {
                    font-weight: normal;
                    color: #393939;
                }

                .l-order__payment-amount {
                    padding-right: 8px;
                    color: #f36f21;
                    text-align: right;
                    font-weight: 700;
                }

            .l-order__person-wrap {
                border: 1px solid #e5e5e5;
                border-bottom: 0 none;
                padding: 11px 14px 7px;
            }

                .l-order__person {
                    display: table;
                    width: 100%;
                }

                    .l-order__person-col {
                        display: table-cell;
                    }

                    .l-order__person-col .icon-svg {
                        margin-right: 4px;
                    }

                    .l-order__person-col_r {
                        text-align: right;
                    }

                .l-order__person-address {
                    margin-top: 12px;
                }


        .l-order__shippings {
            border: solid #ebebeb;
            border-width: 0 1px 1px;
        }


/*
 * Expand - collase for order accordeon
 * */
.l-order__collapse {
    margin-left: 8px;
    margin-top: -2px;
}

.l-order__expand {
    margin-left: 10px;
    margin-right: 3px;
}

.l-order__expand,
.l-order__collapse {
    display: inline-block;
    vertical-align: middle;
}

.l-order_expanded .l-order__expand,
.l-order_collapsed .l-order__collapse,
.l-order_collapsed .l-order__wrap {
    display: none;
}