.ui-autocomplete {
    float: left;
    z-index: 1;
    padding: 2px;
    border-width: 1px;
    border-style: solid;
    border-color: #CCCCCC;
    max-height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
    background: #FFF;
    position: absolute;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.ui-menu-item{
    padding-top: 2px;
    padding-bottom: 2px;
}

.summary.open{
    height: 1.5em;
}

.reviewHeadline, .ratingTypeTitle{
    min-width: 0%;
}