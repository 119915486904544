/* 
    Document   : buttons-ext.css
    Created on : Mar 19, 2013, 10:21:15 AM
    Author     : João Costa <jtcosta@rocket-internet.pt>
    Description:
        Purpose of the stylesheet follows.
*/

.btn.btn-search {
    margin-top: 0px;
    height: 35px;
}