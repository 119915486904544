.l-delivery-blocked-sku {
    font-size: 12px;
}

    p.l-delivery-blocked-sku__notify-desc {
        margin: 0 0 4px;
    }

    .l-delivery-blocked-sku__item-list {
        padding: 0;
        margin: 0 0 12px;
    }

        .l-delivery-blocked-sku__item {
            color: #646464;
            list-style: none;
            display: block;
            margin-bottom: 4px !important;
            padding-left: 12px;
            position: relative;
        }
    
        .l-delivery-blocked-sku__item:before {
            content: '• ';
            position: absolute;
            color: #000;
            left: 0;
            font-size: 1.2em;
        }