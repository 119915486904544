.l-t-input input[type=tel],
.l-t-input input[type=text],
.l-t-input textarea {
    padding: 2px 0;
    background: #fff;
    color: #333;
    -webkit-border-radius: 3px;
            border-radius: 3px;
    margin: 0;
    padding: 20px 10px 6px;
    font-size: 13px;
    height: auto;
    font-family: Roboto, Tahoma, sans-serif;
    border: 1px solid #aaa;

    -webkit-box-shadow: none;
            box-shadow: none;
    -webkit-appearance: none;
            appearance: none;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

/*
    <div class="l-t-input l-t-input_placeholded">
        <?php echo CHtml::activeLabelEx($model, 'first_name', array('label' => $model->getPlaceholder('first_name'))); ?>
        <?php echo CHtml::activeTextField($model, 'first_name', array('class'=>'shipping_input_field', 'size'=>50, 'maxlength' => 50)) ?>
    </div>

    makes input[type=text], textarea with the label inside it
    label acts like placeholder but always shown
**/
.l-t-input_placeholded {
}

.l-t-input_placeholded label {
    display: block;
    position: relative;
    z-index: 1;
    padding: 5px 10px 0;
    color: #646464;
    font-size: 10px;
    line-height: 11px;
    cursor: pointer;
    margin-bottom: -16px;
    height: 11px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.l-t-input_placeholded input[type=tel],
.l-t-input_placeholded input[type=text] {
    padding: 18px 10px 5px;
}

.l-t-input_placeholded textarea {
    padding: 18px 10px 5px;
    display: block;
    font-size: 13px;
    line-height: 1.2;
    /*min-height: 2*1.2*13px + 20px + 6px */
    min-height: 58px;
}

/* apply greater padding for Android below 4.4 */
html.no-webworkers body.android .l-t-input_placeholded input[type=tel],
html.no-webworkers body.android .l-t-input_placeholded input[type=text],
html.no-webworkers body.android .l-t-input_placeholded textarea {
  padding-top: 35px;
}
