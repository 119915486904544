/*
 * Shipping history entry block
 * */
.l-history-entry {
    padding: 9px 8px;
    color: #757575;
    font: normal 12px 'Helvetica Regular', sans-serif;
}
    .l-history-entry__title {
        color: #1e1e1e;
    }

    .l-history-entry__desc {
        margin-top: 2px;
    }

    .l-history-entry__note {
        margin-top: 10px;
        color: #757575;
        font: normal 10px 'Helvetica Regular', sans-serif;
    }