input.l-radio[type=radio] {
    width:  0;
    height: 0;
    position: absolute;
    left: -99999px;
}

input.l-radio[type=radio] + label {
    position: relative;
}


/* 
TODO 
input[type=radio] + label:after,{
the better way to do the custom radiobutton

but if we use SPRITES we cant support conviniently it in PSEUDO ELEMENTS
we could use a LESS mixin later to solve this problem
something like
<code>
    input[type=radio] + label:after {
        .icon-svg;
        .icon-svg-radio-off;
    }

    input[type=radio]:checked + label:after {
        .icon-svg-radio-on;
    }
</code>
*/

input.l-radio[type=radio] + label>.radio_on,
input.l-radio[type=radio] + label>.radio_off {
    vertical-align: -2px;
    display: inline-block;
    margin-right: 3px;
}

input.l-radio[type=radio] + label>.radio_on {
    display: none;
}

input.l-radio[type=radio]:checked + label>.radio_on {
    display: inline-block;
}

input.l-radio[type=radio]:checked + label>.radio_off {
    display: none;
}

/* multiline radio */
input.l-radio_multiline[type=radio] + label>.radio_on,
input.l-radio_multiline[type=radio] + label>.radio_off {
    margin-right: 8px;
}

input.l-radio_multiline[type=radio] + label>.l-radio__text-cont {
    display: inline-block;
    vertical-align: top;
    white-space: normal;
}