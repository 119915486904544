.mobilestep_finish .main-container {
    background: #f1f1f1;
}

    .l-mobile-step_finish {
        font-size: 12px;
        line-height: 15px;
    }

        .l-finish__form {
            margin: 0 18px 10px;
        }


    .l-mobile-step_finish h2 {
        font-size: 14px;
        font-weight: 500;
        padding: 0;
        color: #4f749e;
        margin: 0 0 10px;
    }

    fieldset.l-finish__cont {
        background: #fff;
        margin: 0;
        padding: 13px;
        border: 1px solid #c9cfd2;
    }

    fieldset.l-finish__cont_dark {
        background: #fafafa;
        border: 1px solid #c9cfd2;
        border-bottom: 0 none;
    }


    .l-finish__btn-row .icon-svg-lock {
        margin-right: 12px;
    }

    .l-finish__row {
        margin: 0 0 20px;
    }

    /* WS-11911 hide delivery options there */
    .l-finish__row_delivery-options {
        display: none;
    }

    .l-finish__row .icon-svg-address-phone {
        margin-top: 2px;
    }

        .l-finish__edit-link {
            float: right;
            font-weight: 400;
            color: #33a3b3;
        }

    .l-finish__table-cont {
        padding: 10px 0;
    }
        .l-finish__table-row {
            display: table;
            width: 100%;
        }
            .l-finish__cell {
                display: table-cell;
                text-align: left;
            }

            .l-finish__cell_r {
                text-align: right;
            }

    .l-finish__hr {
        outline: 0;
        border: 0 none;
        border-bottom: 1px solid #aeaeae;
        margin: 8px 0;
    }

    .l-finish__money {
        font-size: 13px;
        line-height: 19px;
    }

    .l-finish__money .l-finish__edit-link{
        float: none;
    }

    .l-finish__money strong {
        font-size: 14px;
        line-height: 1.7;
    }

    .l-finish__table-row_voucher {
        
    }
    .l-finish__table-row_hidden {
        display: none;
    }
