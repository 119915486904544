.l-payment-form {
    font-size: 14px;
}
    .l-payment-form__title {
        font-size: 16px;
        font-weight: 500;
    }

    .l-payment-form__error {
        color: #d2232a;
        font-size: 11px;
    }

    .l-payment-form__field {
        box-sizing: border-box;
        margin-bottom: 11px;
    }

    .l-payment-form__field b, .l-payment-form__field strong {
        font-weight: 500;
    }

    .l-payment-form__fieldset {
        margin: 0;
        padding: 0;
        border: none;
    }

    .l-payment-form__fieldset_hidden {
        display: none;
    }

    .l-payment-form__error {
      color: #D2232A;
      font-style: italic;
    }

.l-payment-form_cc {
}
    .l-payment-form_cc .l-payment-form__field_expiry,
    .l-payment-form_cc .l-payment-form__field_cvv {
        float: left;
        width: 50%;
    }

    .l-payment-form_cc .l-payment-form__field_expiry {
        padding-right: 5px;
    }

    .l-payment-form_cc .l-payment-form__field_cvv {
        padding-left: 5px;
    }

.l-payment-form_note {
    margin: 24px 0 30px;
}
