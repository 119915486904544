/*
    secure icon list
*/
.l-mobile__secure-list {
    display: block;
    font-size: 0;
    text-align: center;
    padding: 20px 0;
}

.l-mobile__secure-list_simple {
    margin: 0;
}


    .l-mobile__secure-item {
        display: inline-block;
        vertical-align: middle;
        padding: 12px 2%;
    }


.l-checkout__row {
    position: relative;
    margin: 0 0 16px;
}
