div.tooltip-open {
    display: block;
}
.tooltip {
    position: absolute;
    background-color: #f8f8f8;
    padding: 17px;
    z-index: 1;
    display: none;
    border: 1px solid #cccccc;
    width: 230px;
    opacity: 0;
    transition: opacity .2s;
    box-sizing: border-box;
}
.tooltip:before,
.tooltip:after {
    width: 0;
    height: 0;
    position: absolute;
    display: block;
    border-style: solid;
    content: '';
}
.tooltip_top:before,
.tooltip_top:after {
    border-bottom-color: #cccccc;
    border-left-color: transparent;
    border-right-color: transparent;
    border-width: 0 8px 9px 8px;
    top: -9px;
    left: 50%;
}
.tooltip_top:after {
    border-bottom-color: #f8f8f8;
    top: -8px;
    left: 50%;
}
.tooltip_top-right:before,
.tooltip_top-right:after {
    border-bottom-color: #cccccc;
    border-left-color: transparent;
    border-right-color: transparent;
    border-width: 0 8px 9px 8px;
    top: -9px;
    left: 75%;
}
.tooltip_top-right:after {
    border-bottom-color: #f8f8f8;
    top: -8px;
    left: 75%;
}
.tooltip__inner h3:empty {
    display: none;
    margin: 0;
    padding: 0;
}
.tooltip__inner h3{
    color: #9ca73a;
    font-variant: small-caps;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    margin-top: -6px;
    margin-bottom: 6px;
}
.tooltip__inner p{
    color: #646464;
    white-space: normal;
    font-weight: normal;
    font-size: 11px;
}
.tooltip__icon-close {
    position: absolute;
    color: #757575;
    font-size: 16px;
    top: 5px;
    right: 5px;
    cursor: pointer;
}
.tooltip-fade {
    opacity: 1;
}