.content .mobile-step h2 {
    margin: 0;
}

.mobile-step fieldset {
    margin: 10px 0 0 0;
    border-radius: 3px;
    padding: 0;
}
.mobile-step .form-mobile div {
    margin: 10px 0 0 0;
}

.mobile-step .form-mobile input[type="number"],
.mobile-step input[type="text"],
.mobile-step .form-mobile input[type="text"],
.mobile-step .form-mobile input[type="tel"],
.mobile-step .form-mobile input[type="email"],
.mobile-step .form-mobile input[type="password"],
.mobile-step .form-mobile input[type="search"],
.mobile-step .form-mobile input[type="email"] {
    box-shadow: none;
    margin-top: 0;
    margin-bottom: 8px;
    /*line-height: 0.7;*/
    font-size: 13px;
    height: 33px;
    font-family: Roboto, Tahoma, sans-serif;
    border-color: #c0c0c0;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
}

.mobile-step .form-mobile div.passwordInput, .mobile-step #passwordContainer {
    margin: 0;
}

.mobile-step #passwordContainer {
    display: none;
}

.mobile-step label span.required {
    display: inline;
}
.mobile-step div label {
    font-size: 0.875em;
}
.mobile-step .radio_check {
    float: left;
    width: 15px;
}
.mobile-step .select-type-login label {
    margin-bottom: 18px;
}

.mobile-step input[type=radio], .mobile-step input[type=checkbox] {
    width: 16px;
    height:  16px;
    display: none;
}

.mobile-step input[type=radio] + label,
.mobile-step .payment-list input[type=radio] + label {
    position: relative;
    padding: 0 0 0 21px;
}

.mobile-step input[type=radio] + label:after,
.mobile-step .payment-list input[type=radio] + label:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    width: 16px;
    height: 16px;
    background: #ff0000;
    background: url(../images/local_mobile_api_opera_mini/sprites_button.png) no-repeat top left;
    background-position: 0 -30px;
    cursor: pointer;
    margin-top: -10px;
}

.mobile-step input[type=radio]:checked + label:after,
.mobile-step .payment-list input[type=radio]:checked + label:after {
    background-position: 0 -48px;
}

.mobile-step .payment-list .inactive {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.mobile-step .payment-list .inactive .disabled {
    color: #808080;
}

.mobile-step .payment-list .inactive .text {
    padding: 5px 21px;
}

.mobile-step .payment-list .inactive .text li.first {
    list-style: square;
}

.mobile-step .payment-list .inactive .text li.second {
    list-style: circle;
    margin-left: 20px;
}

.mobile-step .payment-list .inactive input[type=radio] + label {
    background: none;
    cursor: default;
}

.mobile-step input[type=checkbox] + label {
    position: relative;
    padding: 0 0 0 21px;
}

.mobile-step input[type=checkbox] + label:after {
    position: absolute;
    left: 0;
    top: 50%;
    content: "";
    width: 16px;
    height: 16px;
    background: url(../images/local_mobile_api_opera_mini/sprites_button.png) no-repeat top left;
    background-position: 0 2px;
    margin-top: -8px;
}

.mobile-step input[type=checkbox]:checked + label:after {
    background-position: 0 -13px;
}

.mobile-step .address p {
    margin: 0;
}

.mobile-step .address {
    overflow: hidden;
    width: 90%;
    /*fix for WS-997*/
    /*position: relative;
    left: 10px;
    float: left;
    top: -51px;*/
}

.mobile-step .list_address {
    position: relative;
    top: -52px;
    width: 100%;
}

.mobile-step .shadow {
    width: 20px;
    height:50px;
    position: relative;
    left: -22px;
    background-color: #ffffff;
    top: 17px;
    margin: 0;
}

.address_container .clear-width {
    float: left;
    height: auto !important;
}

.mobile-step .radio-billing + label {
    line-height: 20px;
    cursor: pointer;
}

.mobile-step #billing_address_form input[type="text"],
.mobile-step #billing_address_form .form-mobile input[type="text"],
.mobile-step #shipping_address_form input[type="text"],
.mobile-step #shipping_address_form .form-mobile input[type="text"] {
    margin-bottom: 0;
}

.mobile-step.form-mobile input[type="text"],
.mobile-step .form-mobile input[type="text"] {
    -webkit-appearance: none;
    appearance: none;
}

.mobile-step .billing.location, .mobile-step .shipping_select {
    width: 90%;
    padding: 5px;
    border: none;
    line-height: 21px;
}

.mobile-step .form-mobile-select, .mobile-step .form-select {
    overflow: hidden;
    background: transparent url('../images/local_mobile_api/arrow_down.png') no-repeat;
    background-position: 97% 50%;
    background-position: right 10px top 50%;
    border: 1px solid #ccc;
    /*border-radius: 3px;*/
    width: 100%;
    /*height: 30px;*/
    box-sizing: border-box;
}

.mobile-step .form-select {
    width: 100%;
    float: left;
}

.mobile-step .mobile-step-container {
    padding: 0 10px;
}

.mobile-step h2 {
    border-bottom: 1px solid #c0c0c0;
    padding: 10px;
}

.mobile-step dt {
    color: #333;
    font-size: 0.875em;
}

.mobile-step .all-price dt small {
    font-size: 100%;
    float: none;
    color: #464646;
}

.mobile-step .all-price dd + dt {
    clear: both;
    margin-top: 10px;
}

.mobile-step .all-price dd + dt + dd {
    margin-top: 10px;
}

.mobile-step .all-price dt {
    margin-bottom: 0;
}

.mobile-step .product-list {
    background: #ffffff;
    padding: 0 10px;
    border-bottom: 1px solid #ccc;
    box-shadow: none;
    -webkit-border-shadow: none;
}

.mobile-step div.cf-wrapper {
    margin: 0;
}

.mobile-step .product-list .product-price {
    clear: both;
    color: #c11600;
    font-weight:700;
    font-size: 0.875em;
    float: right;
    width: 100%;
}

.mobile-step .product-list li {
    border: none;
}
.mobile-step div.all-price, .mobile-step div.total-price, .mobile-step .cf-wrapper div.payment-mobileapi, .mobile-step .cf-wrapper div.email {
    padding: 10px;
    margin: 0;
}

.mobile-step .price {
    color: #c11600;
    font-weight: 700;
    font-size: 0.875em;
}

.mobile-step .product-quantity {
    color: #333;
}

.mobile-step .total-price {
    border-top: 1px solid #ccc;
    clear: both;
}

.mobile-step .cf-wrapper h2 {
    margin: 0;
}

.mobile-step .cf-wrapper div.address {
    padding: 10px;
    width: 93%;
}

.mobile-step .cf-wrapper .title {
    border-top: 1px solid #ccc;
}

.mobile-step .cf-wrapper .email span {
    margin: 0;
    color: #999;
}

.mobile-step .coupon-container {
    width: 100%;
    float: left;
}

.mobile-step .coupon-container .inputText {
    width: 76%;
    float: left
}

.mobile-step .coupon-container .coupon-button {
    width: 22%;
    float: right;
    padding: 0;
    margin: 0;
    clear: none;
    height: 30px;
    line-height: 30px;
    font-size: 0.875em;
    color: #fff;
    background: #009caf;
}

.mobile-step .coupon-container .inputText {
    height: 30px !important;
}

.mobile-step #addCoupon {
    display: block;
    padding: 10px;
}

.mobile-step h1 {
    color: #faa41a;
    margin: 45px auto;
    text-align: center;
}

.mobile-step div.description-order {
    font-size: 0.875em;
    text-align: center;
    margin-bottom: 15px;
}

.mobile-step .form-mobile input.success_input_field {
    width: 60%;
}

.mobile-step .password-form label {
    font-weight: bold;
    width: 100px;
}

.mobile-step hr.fancy-line {
    border: 0;
    height: 1px;
    position: relative;
    margin: 30px 0; /* Keep other elements away from pseudo elements*/
}
.mobile-step hr.fancy-line:before {
    top: -0.5em;
    height: 1em;
}
.mobile-step hr.fancy-line:after {
    content:'';
    height: 0.5em;   /* half the height of :before */
    top: 1px;        /* height of hr*/
}

.mobile-step hr.fancy-line:before, hr.fancy-line:after {
    content: '';
    position: absolute;
    width: 100%;
}

.mobile-step hr.fancy-line, hr.fancy-line:before {
    background: -moz-radial-gradient(center, ellipse cover, rgba(0,0,0,0.070) 0%, rgba(0,0,0,0) 75%);
    background: -webkit-gradient(radial, center center, 0px, center center, 75%, color-stop(0%,rgba(0,0,0,0.070)), color-stop(75%,rgba(0,0,0,0)));
    background: -webkit-radial-gradient(center, ellipse cover, rgba(0,0,0,0.070) 0%,rgba(0,0,0,0) 75%);
    background: -o-radial-gradient(center, ellipse cover, rgba(0,0,0,0.070) 0%,rgba(0,0,0,0) 75%);
    background: -ms-radial-gradient(center, ellipse cover, rgba(0,0,0,0.070) 0%,rgba(0,0,0,0) 75%);
    background: radial-gradient(ellipse at 50% 51%, rgba(0,0,0,0.070) 0%,rgba(0,0,0,0) 75%)
}

.mobile-step .desc-free-account {
    margin: 30px 0;
}

.mobile-step h3, .mobile-step .desc-free-account {
}

.mobile-step .password-form span.error {
    position: relative;
    top: -5px;
    margin-left: 102px;
}

.mobile-step .desc-free-account li {
    background: transparent url('../images/local_mobile_api/check.png') no-repeat;
    padding-left: 25px;
}

.mobile-step h3.title-social {
    font-size: 1em;
}

.mobile-step .form-mobile-select.error {
    border: 1px solid #bd1947;
}

.mobile-step .coupon-container {
    position: relative;
}

.mobile-step .errorMessage {
}

.mobile-step .g80.coupon {
    position: relative;
}

.mobile-step #error-sign, .mobile-step .error-symbol {
    font-size: 0.775em;
    border: 1px solid #ccc;
    background: #ccc;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    width: 16px;
    height: 16px;
    line-height: 16px;
    margin-right: 3px;
}

.mobile-step #error-sign {
    float: left;
    margin-left: -22px;
}

.mobile-step #errorCoupon {
    padding-left: 22px;
    line-height: 18px;
}

.mobile-step div.error-area {
    float: left;
    width: 100%;
    margin: 0 0 10px 0;
}

.mobile-step .input-area {
    float: left;
    width: 100%;
}

.mobile-step .promotion-price {
    color: #007323;
    font-weight: 700;
}

.mobile-step .social-icons {
    width: 100%;
    margin: 10px auto;
}

.mobile-step .social-icons li {
    display: inline-block;
}

.mobile-step .social-icons li a {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-right: 10px;
}

.social-icons .facebook {
    background: url('../local/images/local_mobile_api/share_facebook.png') no-repeat;
}

.social-icons .twitter {
    background: url('../local/images/local_mobile_api/share_twitter.png') no-repeat;
}

.social-icons .google {
    background: url('../local/images/local_mobile_api/share_googleplus.png') no-repeat;
}

.cf-wrapper #addCoupon.hidden {
    display: none;
}

.mobile-step .line .form-select-checkout:first-child {
    margin-right: 4% !important;
}

.mobile-step .form-select-checkout {
    width: 48%;
}

.mobile-step .form-select > select {
    width: 92%;
    border: none;
    color: #999999;
    margin-top: 0;
    padding: 5px;
    height: auto;
    text-overflow: ellipsis;
}

.mobile-step .form-select select.error {
    background-color: transparent !important;
    border: none !important;
}

.mobile-step #form-account-payment input {
    margin-bottom: 0;
}

.mobile-step .track-order-form {
}

.mobile-step #cc_save {
    float: left;
    margin: 10px 0 0 2px;
}

.content .mobile-step dd {
    margin: 0;
}

.mobile-step .payment-list {
    padding: 10px;
}

h3.greyText {
    color: #959595;
    font-weight: normal;
    margin-left: 5px;
}
.free_shipping_message{
    margin: 2px 0px;
}

/**
*   Shipping Fee page
**/
div.shippingOptionRow .data_delivery_container {
    padding: 0 20px;
}
div.shippingOptionRow .data_delivery_container ul li{
    margin-bottom: 5px;
    margin-left: 25px;
    line-height: 18px;
    color: #5B5B5B;
    padding-right: 60px;
}
div.shippingOptionRow .data_delivery_container ul li::before{
     content: "\002022";
     color: #AAAAAA;
     padding-right: 6px;
}
div.shippingOptionRow .data_delivery_container ul li.delivery-options_warning_info{
    color: #808080;
    margin-left: 0;
    background: url('/images/2014/notice.png') no-repeat 0 0;
    padding-left: 25px;
    padding-right: 10px;
}
div.shippingOptionRow .data_delivery_container ul li.delivery-options_warning_info::before{
     content: "";
     padding-right: 0;
}

div.shippingOptionRow {
    margin: 15px 0;
}
.shippingOptionLabel {
    cursor: pointer;
    display: block;
}
.mobile-step.mobile-step .shippingOptionLabel:after {
    top: 1px;
}
.shippingOptionLabel strong {
    display: block;
    margin: 0 0 8px;
}
.shippingOptionLabel span {
    color: #757575;
}

.shippingFeeForm {
    padding: 0 0 30px;
}

.shippingFeeForm .sellerName {
    font-size: 15px;
    color: #999;
}

.shippingFeeForm .shippingSpeed {
    font-size: 15px;
    color: #999;
    padding: 15px 0;
    clear: both;
}

.shippingFeeForm ul.product-list + ul.product-list {
    margin-top: 30px;
}

.shippingFeeForm .product-list {
    padding: 0;
}

.shippingFeeForm .product-list > li {
    padding: 15px 10px;
}

.shippingFeeForm li.mobile-step {
    padding: 15px 20px;
    position: relative;
    z-index: 9;
    background: #f3f3f3;
    border: 0;
}

.shippingFeeForm li.shippingError {
    border: 1px solid #f37022;
    padding: 0 9px 10px;
}

.shippingFeeForm .errorMessageArea {
    padding: 5px 90px 10px 55px;
    position: relative;
    background: #ffffb5;
    position: relative;
    margin: 0 0 15px;
}

.shippingFeeForm .errorMessageArea p {
    font-size: 14px;
    line-height: 18px;
    color: #757575;
    position: relative;
}

.shippingFeeForm .errorMessageArea strong {
    color: #ff3d00;
    font-weight: normal;
}

.shippingFeeForm .errorMessageArea .icon-alert {
    position: absolute;
    left: -30px;
    top: 3px;
}

.shippingFeeForm .acceptError {
    position: absolute;
    font-size: 20px;
    text-transform: uppercase;
    color: #fff;
    height: 36px;
    background: #999;
    padding: 0 12px;
    top: 15px;
    right: 15px;
    border: none;
    font-weight: normal;
    letter-spacing: 1px;
}

.shippingFeeForm .errorMessageArea:before,
.shippingFeeForm .errorMessageArea:after {
    content: "";
    bottom: 0;
    top: 0;
    position: absolute;
    width: 9px;
    background: #ffffb5;
}

.shippingFeeForm .errorMessageArea:before {
    left: -9px;
}

.shippingFeeForm .errorMessageArea:after {
    right: -9px;
}

.shippingFeeForm li.mobile-step:before {
    content: "";
    position: absolute;
    border-bottom: 18px solid #f3f3f3;
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    left: 33px;
    bottom: 100%;
}

.delivery-button-wrap {
    padding-bottom: 15px;
}

.mobile-step dl.installment {
    margin: 0;
    padding: 0;
}

.mobile-step dl.installment dt {
    margin-bottom: 2px;
    color: #999999;
    font-size: 1em;
}




















.l-mobile-step h2, #form-account-login h2 {
    font-size: 18px;
    line-height: 1.2;
    color: #333;
    padding: 10px 0 18px;
    text-align: left;
}

.l-mobile-step fieldset {
    margin: 10px 0 0 0;
    padding: 13px;
    border: 1px solid #c9cfd2;
    background: #fff;
}


.l-mobile-step input[type="text"],
.l-mobile-step .form-mobile input[type="number"],
.l-mobile-step .form-mobile input[type="text"],
.l-mobile-step .form-mobile input[type="tel"],
.l-mobile-step .form-mobile input[type="email"],
.l-mobile-step .form-mobile input[type="password"],
.l-mobile-step .form-mobile input[type="search"],
.l-mobile-step input[type="email"] {
    box-shadow: none;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 15px;
    line-height: 0.7;
    height: 43px;
    font-family: Roboto, Tahoma, sans-serif;
    border-color: #aaa;
    -webkit-appearance: none;
            appearance: none;
    -webkit-border-radius: 4px;
            border-radius: 4px;
}

/*.l-mobile-step .form-mobile div.passwordInput, .mobile-step #passwordContainer {
    margin: 0;
}*/

.l-password-cont_expandable {
    overflow: hidden;
    max-height: 0;
    -webkit-transition: max-height .2s;
            transition: max-height .2s;
}

.l-password-cont_expandable.expand {
    overflow: visible;
    max-height: 200px;
    display: block;
}

.l-password-cont {
    position: relative;
}

.show-password {
    position: absolute;
    top: 0;
    right: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}

.show-password > input.l-checkbox[type=checkbox] + label {
    padding: 0;
    vertical-align: top;
}
.show-password > input.l-checkbox[type=checkbox] + label > .check_on,
.show-password > input.l-checkbox[type=checkbox] + label > .check_off {
    position: static;
    margin: 13px 15px;
}


.l-mobile-step label span.required {
    display: inline;
}

.l-mobile-step label {
    font-size: 16px;
    display: block;
}

.l-mobile-step .radio_check {
    float: left;
    width: 15px;
}

.l-mobile-step .select-type-login {
    margin-top: 20px;
}

.l-mobile-step .select-type-login label {
    margin-bottom: 18px;
    font-size: 15px;
}

/*input[type=radio], 
input[type=checkbox] {
    width:  18px;
    height: 18px;
    position: absolute;
    left: -99999px;
}

.l-mobile-step input[type=radio] + label {
    position: relative;
    padding: 0 0 0 32px;
}*/


/* 
TODO 
.l-mobile-step input[type=radio] + label:after,
.l-mobile-step .payment-list input[type=radio] + label:after {
the better way to do the custom radiobutton

but if we use SPRITES we cant support conviniently it in PSEUDO ELEMENTS
we could use a LESS mixin later to solve this problem
something like
<code>
    .l-mobile-step input[type=radio] + label:after {
        .icon-svg;
        .icon-svg-radio-off;
    }

    .l-mobile-step input[type=radio]:checked + label:after {
        .icon-svg-radio-on;
    }
</code>
*/
/*input[type=radio] + label>.radio_on,
input[type=radio] + label>.radio_off {
    position: absolute;
    cursor: pointer;
    top: 10px;
    margin-top: -10px;
    left: 0;
}

input[type=radio] + label>.radio_on {
    display: none;
    margin: 8px 0;
}

input[type=radio]:checked + label>.radio_on {
    display: block;
}

input[type=radio]:checked + label>.radio_off {
    display: none;
}*/



.l-mobile-step .payment-list .inactive {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.l-mobile-step .payment-list .inactive .text {
    padding: 5px 21px;
}




.l-mobile-step .payment-list .inactive input[type=radio] + label {
    background: none;
    cursor: default;
}

/*input[type=checkbox] + label {
    position: relative;
    padding: 0 0 0 21px;
}
*/


/* 
TODO 
.l-mobile-step input[type=checkbox] + label:after,
.l-mobile-step .payment-list input[type=checkbox] + label:after {
the better way to do the custom radiobutton

but if we use SPRITES we cant support conviniently it in PSEUDO ELEMENTS
we could use a LESS mixin later to solve this problem
something like
<code>
    .l-mobile-step input[type=checkbox] + label:after {
        .icon-svg;
        .icon-svg-check-off;
    }

    .l-mobile-step input[type=checkbox]:checked + label:after {
        .icon-svg-check-on;
    }
</code>
*/
/*input[type=checkbox] + label>.check_on,
input[type=checkbox] + label>.check_off {
    position: absolute;
    left: 0;
    top: 50%;
    content: "";
    margin-top: -9px;
}

input[type=checkbox] + label>.check_on {
    display: none;
}

input[type=checkbox] + label>.check_off {
    display: block;
}

input[type=checkbox]:checked + label>.check_on {
    display: block;
}
input[type=checkbox]:checked + label>.check_off {
    display: none;
}*/







.l-mobile-step .orange-button {
    font-size: 17px;
    line-height: 42px;
    text-transform: uppercase;
    padding: 0 4px;
    height: auto;
    margin: 10px 0 0;
}

.l-mobile-step .orange-button[disabled] {
    background: #f9b790;
}


.l-mobile-step .address p {
    margin: 0;
}

.l-mobile-step .address {
    overflow: hidden;
    width: 90%;
    /*fix for WS-997*/
    /*position: relative;
    left: 10px;
    float: left;
    top: -51px;*/
}

.l-mobile-step .list_address {
    position: relative;
    top: -52px;
    width: 100%;
}

.l-mobile-step .shadow {
    width: 20px;
    height:50px;
    position: relative;
    left: -22px;
    background-color: #ffffff;
    top: 17px;
    margin: 0;
}

.address_container .clear-width {
    float: left;
    height: auto !important;
}

.l-mobile-step .radio-billing + label {
    line-height: 20px;
    cursor: pointer;
}

.l-mobile-step #billing_address_form input[type="text"],
.l-mobile-step #billing_address_form .form-mobile input[type="text"],
.l-mobile-step #shipping_address_form input[type="text"],
.l-mobile-step #shipping_address_form .form-mobile input[type="text"] {
    margin-bottom: 0;
}

.l-mobile-step.form-mobile input[type="text"],
.l-mobile-step .form-mobile input[type="text"] {
    -webkit-appearance: none;
    appearance: none;
}

.l-mobile-step .form-mobile-select, 
.l-mobile-step .form-select {
    overflow: hidden;
    background: transparent url('../images/local_mobile_api/arrow_down.png') no-repeat;
    background-position: 97% 50%;
    background-position: right 10px top 50%;
    border: 1px solid #ccc;
    /*border-radius: 3px;*/
    width: 100%;
    /*height: 30px;*/
    box-sizing: border-box;
}

.l-mobile-step .form-select {
    width: 100%;
    float: left;
}

.l-mobile-step .mobile-step-container {
    padding: 0 10px;
}

.l-mobile-step dt {
    color: #333;
    font-size: 0.875em;
}

.l-mobile-step .all-price dt small {
    font-size: 100%;
    float: none;
    color: #464646;
}

.l-mobile-step .all-price dd + dt {
    clear: both;
    margin-top: 10px;
}

.l-mobile-step .all-price dd + dt + dd {
    margin-top: 10px;
}

.l-mobile-step .all-price dt {
    margin-bottom: 0;
}

.l-mobile-step .product-list {
    background: #ffffff;
    padding: 0 10px;
    border-bottom: 1px solid #ccc;
    box-shadow: none;
    -webkit-border-shadow: none;
}

.l-mobile-step div.cf-wrapper {
    margin: 0;
}

.l-mobile-step .product-list .product-price {
    clear: both;
    color: #c11600;
    font-weight:700;
    font-size: 0.875em;
    float: right;
    width: 100%;
}

.l-mobile-step .product-list li {
    border: none;
}
.l-mobile-step div.all-price, 
.l-mobile-step div.total-price, 
.l-mobile-step .cf-wrapper div.payment-mobileapi, 
.l-mobile-step .cf-wrapper div.email {
    padding: 10px;
    margin: 0;
}

.l-mobile-step .price {
    color: #c11600;
    font-weight: 700;
    font-size: 0.875em;
}

.l-mobile-step .product-quantity {
    color: #333;
}

.l-mobile-step .total-price {
    border-top: 1px solid #ccc;
    clear: both;
}

.l-mobile-step .cf-wrapper h2 {
    margin: 0;
}

.l-mobile-step .cf-wrapper div.address {
    padding: 10px;
    width: 93%;
}

.l-mobile-step .cf-wrapper .title {
    border-top: 1px solid #ccc;
}

.l-mobile-step .cf-wrapper .email span {
    margin: 0;
    color: #999;
}

.l-mobile-step .coupon-container {
    width: 100%;
    float: left;
}

.l-mobile-step .coupon-container .inputText {
    width: 76%;
    float: left
}

.l-mobile-step .coupon-container .coupon-button {
    width: 22%;
    float: right;
    padding: 0;
    margin: 0;
    clear: none;
    height: 30px;
    line-height: 30px;
    font-size: 0.875em;
    color: #fff;
    background: #009caf;
}

.l-mobile-step .coupon-container .inputText {
    height: 30px !important;
}

.l-mobile-step #addCoupon {
    display: block;
    padding: 10px;
}

.l-mobile-step h1 {
    color: #faa41a;
    margin: 45px auto;
    text-align: center;
}

.l-mobile-step div.description-order {
    font-size: 0.875em;
    text-align: center;
    margin-bottom: 15px;
}

.l-mobile-step .form-mobile input.success_input_field {
    width: 60%;
}

.l-mobile-step .password-form label {
    font-weight: bold;
    width: 100px;
}

.l-mobile-step hr.fancy-line {
    border: 0;
    height: 1px;
    position: relative;
    margin: 30px 0; /* Keep other elements away from pseudo elements*/
}
.l-mobile-step hr.fancy-line:before {
    top: -0.5em;
    height: 1em;
}
.l-mobile-step hr.fancy-line:after {
    content:'';
    height: 0.5em;   /* half the height of :before */
    top: 1px;        /* height of hr*/
}

.l-mobile-step hr.fancy-line:before, hr.fancy-line:after {
    content: '';
    position: absolute;
    width: 100%;
}

.l-mobile-step hr.fancy-line, hr.fancy-line:before {
    background: -moz-radial-gradient(center, ellipse cover, rgba(0,0,0,0.070) 0%, rgba(0,0,0,0) 75%);
    background: -webkit-gradient(radial, center center, 0px, center center, 75%, color-stop(0%,rgba(0,0,0,0.070)), color-stop(75%,rgba(0,0,0,0)));
    background: -webkit-radial-gradient(center, ellipse cover, rgba(0,0,0,0.070) 0%,rgba(0,0,0,0) 75%);
    background: -o-radial-gradient(center, ellipse cover, rgba(0,0,0,0.070) 0%,rgba(0,0,0,0) 75%);
    background: -ms-radial-gradient(center, ellipse cover, rgba(0,0,0,0.070) 0%,rgba(0,0,0,0) 75%);
    background: radial-gradient(ellipse at 50% 51%, rgba(0,0,0,0.070) 0%,rgba(0,0,0,0) 75%)
}

.l-mobile-step .desc-free-account {
    margin: 30px 0;
}

.l-mobile-step h3, 
.l-mobile-step .desc-free-account {
}

.l-mobile-step .password-form span.error {
    position: relative;
    top: -5px;
    margin-left: 102px;
}

.l-mobile-step .desc-free-account li {
    background: transparent url('../images/local_mobile_api/check.png') no-repeat;
    padding-left: 25px;
}

.l-mobile-step h3.title-social {
    font-size: 1em;
}

.l-mobile-step .form-mobile-select.error {
    border: 1px solid #bd1947;
}

.l-mobile-step .coupon-container {
    position: relative;
}

.l-mobile-step .errorMessage {
}

.l-mobile-step .g80.coupon {
    position: relative;
}

.l-mobile-step #error-sign, 
.l-mobile-step .error-symbol {
    font-size: 0.775em;
    border: 1px solid #ccc;
    background: #ccc;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    width: 16px;
    height: 16px;
    line-height: 16px;
    margin-right: 3px;
}

.l-mobile-step #error-sign {
    float: left;
    margin-left: -22px;
}

.l-mobile-step #errorCoupon {
    padding-left: 22px;
    line-height: 18px;
}

.l-mobile-step div.error-area {
    float: left;
    width: 100%;
    margin: 0 0 10px 0;
}

.l-mobile-step .input-area {
    float: left;
    width: 100%;
}

.l-mobile-step .promotion-price {
    color: #007323;
    font-weight: 700;
}

.l-mobile-step .social-icons {
    width: 100%;
    margin: 10px auto;
}

.l-mobile-step .social-icons li {
    display: inline-block;
}

.l-mobile-step .social-icons li a {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-right: 10px;
}

.social-icons .facebook {
    background: url('../local/images/local_mobile_api/share_facebook.png') no-repeat;
}

.social-icons .twitter {
    background: url('../local/images/local_mobile_api/share_twitter.png') no-repeat;
}

.social-icons .google {
    background: url('../local/images/local_mobile_api/share_googleplus.png') no-repeat;
}

.cf-wrapper #addCoupon.hidden {
    display: none;
}

.l-mobile-step .line .form-select-checkout:first-child {
    margin-right: 4% !important;
}

.l-mobile-step .form-select-checkout {
    width: 48%;
}

.l-mobile-step .form-select > select {
    width: 100%;
    border: none;
    color: #999999;
    margin-top: 0;
    padding: 5px;
    height: auto;
}

.l-mobile-step .form-select select.error {
    background-color: transparent !important;
    border: none !important;
}

.l-mobile-step #form-account-payment input {
    margin-bottom: 0;
}

.l-mobile-step .track-order-form {
}

.l-mobile-step #cc_save {
    float: left;
    margin: 10px 0 0 2px;
}

.content .l-mobile-step dd {
    margin: 0;
}

.l-mobile-step .payment-list {
    padding: 10px;
}

h3.greyText {
    color: #959595;
    font-weight: normal;
    margin-left: 5px;
}
.free_shipping_message{
    margin: 2px 0px;
}


.gst-small{
    display: block;
    color: #999;
    margin-top: 5px;
}

.delivery-button-wrap {
    padding-bottom: 15px;
}

.l-mobile-step dl.installment {
    margin: 0;
    padding: 0;
}

.l-mobile-step dl.installment dt {
    margin-bottom: 2px;
    color: #999999;
    font-size: 1em;
}


.l-mobile-step-header {
    
}

.l-mobile-step-header__back {
    float: left;
    cursor: pointer;
    padding: 12px 10px;
}

.l-mobile-step-header__back .l-mobile-step-header__back-arrow_active {
    display: none;
}

.l-mobile-step-header__back:active .l-mobile-step-header__back-arrow {
    display: none;
}

.l-mobile-step-header__back:active .l-mobile-step-header__back-arrow_active {
    display: inline-block;
}

/*Footer with phone and Lazada Venture Title */
.l-mobile-step-footer {
    font-size: 13px;
    background: transparent;
    color: #646464;
}


.l-mobile-step-footer a {
    color: #646464;
}


.l-forgot-password-cont {
    color: #646464;
    line-height: 1.4;
}
.l-forgot-password-cont .l-forgot-password-cont__title {
    color: #264a5f;
}
.l-forgot-password-cont .l-forgot-password-cont__button {
    margin-top: 25px;
}









